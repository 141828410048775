import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import useWindowSize from './WindowSize';

const DonutChart = props => {
  const { dataList, labelList, colorList, centerText } = props;

  const size = useWindowSize();
  const width = size.width < 550 ? '300' : '500';
  const fontSize = size.width < 550 ? '12px' : '16px';

  const config = {
    series: [
      {
        name: 'abudabe',
        data: dataList
      }
    ],
    labels: labelList,
    colors: colorList,
    options: {
      chart: {
        type: 'donut'
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: false,
              label: centerText,
              fontSize: fontSize,
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function(w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              }
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom'
    }
  };

  return (
    <div className="d-flex justify-content-center m-3">
      <Chart options={config} series={dataList} type="donut" width={width} />
    </div>
  );
};

export default DonutChart;
