import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../_redux/authRedux';
import CreateShop from './createShop';
import EditShop from './editShop';

function Management(props) {
  const [error, setError] = useState(null);
  const [nav, setNav] = useState('createShop');
  const history = useHistory();
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const state = props.location.state;
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;

  useEffect(() => {
    if (!state) {
      history.push('/shop/management/login');
    }
  }, []);

  return (
    <>
      <div className="col">
        <ul className="nav nav-tabs mb-0 mb-md-10">
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold ${nav == 'createShop' ? 'active' : ''}`}
              data-toggle="tab"
              onClick={() => setNav('createShop')}
            >
              Create
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold ${nav == 'editShop' ? 'active' : ''}`}
              data-toggle="tab"
              onClick={() => setNav('editShop')}
            >
              Manage Shop
            </a>
          </li>
        </ul>
        <div id="myTabContent" className="tab-content">
          {nav == 'createShop' ? (
            <div className="tab-pane fade show active">
              <CreateShop />
            </div>
          ) : null}
          {nav == 'editShop' ? (
            <div className="tab-pane fade show active">
              <EditShop />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Management));
