// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import { Switch } from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDropzone } from 'react-dropzone';
import { Input } from '../../../../../../_metronic/_partials/controls';
import Axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(34px, 20px) scale(1);'
    }
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: '3px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      fontFamily: `Poppins, Helvetica, "sans-serif"`,
      fontWeight: 400,
      color: 'rgb(70, 78, 95)',
      display: 'block',
      padding: '0.65rem 1rem',
      border: '1px solid #E5EAEE',
      borderRadius: '0.42rem'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      fontFamily: `Poppins, Helvetica, "sans-serif"`,
      fontWeight: 400,
      color: 'rgb(70, 78, 95)',
      display: 'block',
      border: '1px solid #E5EAEE',
      borderRadius: '0.42rem'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      fontFamily: `Poppins, Helvetica, "sans-serif"`,
      fontWeight: 400,
      color: 'rgb(70, 78, 95)',
      display: 'block',
      border: '1px solid #E5EAEE',
      borderRadius: '0.42rem'
    }
  }
}));

// Validation schema
const ProductEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Promotion name is required'),
  limit: Yup.number()
    .min(0, '0 is minimum')
    .max(1000000, '1000000 is maximum'),
  userLimit: Yup.number()
    .min(0, '0 is minimum')
    .max(1000000, '1000000 is maximum'),
  point: Yup.number()
    .min(0, '0 is minimum')
    .max(1000000, '1000000 is maximum')
    .required('Point used is required'),
  promotionPrice: Yup.number()
    .min(0, '0 is minimum')
    .max(1000000, '1000000 is maximum')
    .required('Promotion price is required'),
  originalPrice: Yup.number()
    .min(0, '0 is minimum')
    .max(1000000, '1000000 is maximum')
    .required('Original price is required'),
  order: Yup.number()
    .min(1, '1 is minimum')
    .max(999998, '999998 is maximum')
    .required('Order is required')
});

const ProductEditForm = ({
  id,
  product,
  btnRef,
  saveProduct,
  description,
  setDescription,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  file,
  setFile,
  shareImage,
  setShareImage,
  loading,
  setLoading,
  imageList,
  setImageList,
  active,
  setActive,
  type,
  setType,
  error,
  setError,
  intl,
  uuid
}) => {
  const classes = useStyles();
  const [currencies, setCurrencies] = useState([]);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 750, aspect: 350 / 250 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [show, setShow] = useState(false);
  const [defaultImg, setDefaultImg] = useState(null);
  const [currentFileSlot, setCurrentFileSlot] = useState(null);
  const hiddenFileInput = useRef(null);
  const isEdit = id ? true : false;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      // console.log(acceptedFiles[0])
      // setFile(
      //   Object.assign(acceptedFiles[0], {
      //     preview: URL.createObjectURL(acceptedFiles[0])
      //   })
      // );
      if (acceptedFiles && acceptedFiles[0]) {
        setShow(true);
        setDefaultImg(acceptedFiles[0]);
        setUpImg(URL.createObjectURL(acceptedFiles[0]));
      }
    }
  });

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  // useEffect(() => {
  //   getCurrency();
  // }, [setCurrency]);

  // useEffect(() => {
  //   if (typeof currency !== 'object' && currency !== null && currencies.length > 0) {
  //     setCurrency(currencies.find(item => item.key === currency));
  //   }
  // }, [currency, currencies]);

  // const getCurrency = async () => {
  //   const response = await Axios.get('https://openexchangerates.org/api/currencies.json');
  //   setCurrencies(Object.keys(response.data).map(key => ({ key: key, name: response.data[key] })));
  // };

  const submitCrop = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);

    const shareOptions = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 400,
      useWebWorker: true,
      initialQuality: 0.6
    };

    canvas.toBlob(
      blob => {
        const image = blob || defaultImg;
        setImageList({
          ...imageList,
          [currentFileSlot]: Object.assign(image, {
            name: renameWithFileType(defaultImg.name, currentFileSlot),
            preview: URL.createObjectURL(image)
          })
        });
        setShow(false);
        setLoading(false);
      },
      'image/png',
      1
    );
  };

  const uploadImage = fileSlot => {
    setCurrentFileSlot(fileSlot);
    hiddenFileInput.current.click();
  };

  const handleInputFile = value => {
    if (value) {
      setShow(true);
      setDefaultImg(value);
      setUpImg(URL.createObjectURL(value));
      setImageList({ ...imageList, [currentFileSlot]: URL.createObjectURL(value) });
    }
  };

  const renameWithFileType = (name, newName) => {
    const fileType = name.split('.').pop();
    if (fileType) {
      const splitNameIndex = newName.split('_');
      const nameIndex = splitNameIndex[1];
      return `${nameIndex}.${fileType}`;
    }
    console.error('Error: file type not found.');
    return name;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          id
            ? {
              ...product,
              limit: product.limit !== null ? product.limit : '',
              userLimit: product.userLimit !== null ? product.userLimit : '',
              name: product.name !== null ? product.name : null,
              originalPrice: product.originalPrice !== null ? product.originalPrice : 0,
              promotionPrice: product.promotionPrice !== null ? product.promotionPrice : 0,
              order: product.order ? product.order : 1
            }
            : {
              limit: '',
              userLimit: '',
              name: undefined,
              point: undefined,
              originalPrice: undefined,
              promotionPrice: undefined,
              order: product.order ? product.order : 1
            }
        }
        validationSchema={ProductEditSchema}
        onSubmit={async values => {
          await saveProduct(values);
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
                <Field
                  name="name"
                  component={Input}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.NAME'
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="SHOP.PROMOTION.FORM.DESCRIPTION" />
                </label>
                {(!id || (id && product._id)) && (
                  <CKEditor
                    key={uuid}
                    editor={ClassicEditor}
                    rows={4}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                      editor.editing.view.change(writer => {
                        writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                      });
                    }}
                    config={{
                      toolbar: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'undo',
                        'redo'
                      ]
                    }}
                    data={description || ''}
                  />
                )}
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  name="limit"
                  component={Input}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.LIMIT'
                  })}
                />
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  name="userLimit"
                  component={Input}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.USER_LIMIT'
                  })}
                />
              </div>
              <div className="form-group">
                {/* comment point only */}
                <Select disabled={id} value={'point'} onChange={e => setType(e.target.value)}>
                  <MenuItem value="point">point</MenuItem>
                  {/* <MenuItem value="credit">credit</MenuItem> */}
                </Select>
                <Field
                  type="number"
                  name="point"
                  component={Input}
                  onChange={e => {
                    e.preventDefault();
                    if (e.target.value < 0) return
                    setFieldValue("point", e.target.value);
                  }}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.POINT'
                  })}
                />
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  name="originalPrice"
                  component={Input}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.ORIGINAL.PRICE'
                  })}
                />
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  name="promotionPrice"
                  component={Input}
                  label={intl.formatMessage({
                    id: 'SHOP.PROMOTION.FORM.PROMOTION.PRICE'
                  })}
                />
              </div>
              {/* <div className="form-group" style={{ marginBottom: '20px' }}>
                <Field type="hidden" name="currency" component={Input} />
                <label>Currency</label>
                <Autocomplete
                  id="combo-box-demo"
                  value={currency}
                  options={currencies}
                  classes={classes}
                  onChange={(event, value) => setCurrency(value)}
                  getOptionLabel={option => `${option.key} - ${option.name}`}
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </div> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label>
                  <FormattedMessage id="SHOP.PROMOTION.FORM.START" />
                </label>
                <div className="form-group">
                  <>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        'aria-readonly': true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={date => setStartDate(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      disablePast
                    />
                  </>
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label>
                  <FormattedMessage id="SHOP.PROMOTION.FORM.END" />
                </label>
                <div className="form-group">
                  <>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        'aria-readonly': true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      format="dd/MM/yyyy"
                      value={endDate}
                      onChange={date => setEndDate(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      disablePast
                    />
                  </>
                </div>
              </MuiPickersUtilsProvider>
              <input
                type="file"
                hidden
                ref={hiddenFileInput}
                accept="image/png, image/jpeg"
                onChange={e => handleInputFile(e.target.files[0])}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <div className="form-group row">
                <div className="col-lg-8">
                  <label>
                    <FormattedMessage id="SHOP.PROMOTION.FORM.IMAGE" />
                  </label>
                  <div style={{ display: 'flex' }}>
                    <ImageContainer
                      className="shadow-sm border"
                      style={{
                        backgroundImage: `url(${(imageList.image_1 && imageList.image_1.preview) || imageList.image_1})`
                      }}
                    >
                      {imageList.image_1 ? (
                        <DeleteImgButton onClick={() => setImageList({ ...imageList, image_1: null })}>
                          Delete
                        </DeleteImgButton>
                      ) : (
                        <>
                          <UploadImgButton onClick={() => uploadImage('image_1')}>Upload</UploadImgButton>
                          <ImgNumber>1</ImgNumber>
                        </>
                      )}
                    </ImageContainer>
                    {imageList.image_1 || imageList.image_2 ? (
                      <ImageContainer
                        className="shadow-sm border"
                        style={{
                          backgroundImage: `url(${(imageList.image_2 && imageList.image_2.preview) ||
                            imageList.image_2})`
                        }}
                      >
                        {imageList.image_2 ? (
                          <DeleteImgButton onClick={() => setImageList({ ...imageList, image_2: null })}>
                            Delete
                          </DeleteImgButton>
                        ) : (
                          <>
                            <UploadImgButton onClick={() => uploadImage('image_2')}>Upload</UploadImgButton>
                            <ImgNumber>2</ImgNumber>
                          </>
                        )}
                      </ImageContainer>
                    ) : null}
                    {imageList.image_2 || imageList.image_3 ? (
                      <ImageContainer
                        className="shadow-sm border"
                        style={{
                          backgroundImage: `url(${(imageList.image_3 && imageList.image_3.preview) ||
                            imageList.image_3})`
                        }}
                      >
                        {imageList.image_3 ? (
                          <DeleteImgButton onClick={() => setImageList({ ...imageList, image_3: null })}>
                            Delete
                          </DeleteImgButton>
                        ) : (
                          <>
                            <UploadImgButton onClick={() => uploadImage('image_3')}>Upload</UploadImgButton>
                            <ImgNumber>3</ImgNumber>
                          </>
                        )}
                      </ImageContainer>
                    ) : null}
                  </div>
                  {/* TODO: add upload image here */}
                  {/* {file ? (
                    <>
                      <div style={{ display: 'flex' }}>
                        <ImageContainer>
                          <img style={{
                            maxWidth: '80px',
                            maxHeight: '80px',
                          }} src={file.preview} />
                          <div >
                            <label style={{ textAlign: 'center', color: '#fff' }}>Delete</label>
                          </div>
                        </ImageContainer>
                      </div>
                      <div
                        className="p-5 d-flex flex-row align-items-center border shadow-sm p-3 mb-5 bg-white rounded"
                        style={{ margin: '5px 0px 10px 0px' }}
                      >

                        <img
                          style={{
                            display: 'block',
                            maxWidth: '200px',
                            maxHeight: '95px',
                            width: 'auto',
                            height: 'auto'
                          }}
                          alt="preview"
                          src={file.preview}
                        />
                        <span className="p-5">{file.name}</span>
                      </div>
                    </>
                  ) : (
                    product.image && (
                      <>
                        <div
                          className="p-5 d-flex flex-row align-items-center border shadow-sm p-3 mb-5 bg-white rounded"
                          style={{ margin: '5px 0px 10px 0px' }}
                        >
                          <img
                            style={{
                              display: 'block',
                              maxWidth: '200px',
                              maxHeight: '95px',
                              width: 'auto',
                              height: 'auto'
                            }}
                            alt="promotion_image"
                            src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${product.image}`}
                          />
                          <span className="p-5">{product.image}</span>
                        </div>
                      </>
                    )
                  )} */}
                  {/* <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} style={{ display: 'block' }} />
                  </div> */}
                  <p style={{ marginTop: 10 }}>
                    {intl.formatMessage({
                      id: 'FORM.SHOP.LOGO_LIMIT'
                    })}
                  </p>
                </div>
              </div>
              {/* index field */}
              <Field type="number" name="order" component={Input} label={'Order'} disabled={!isEdit} />
              <label>Active</label>
              <Switch checked={active} onChange={() => setActive(!active)} />
            </Form>
            {error && <Alert severity="error">{error}</Alert>}
            <button
              type="submit"
              disabled={isSubmitting || loading}
              className="btn btn-primary ml-2"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="SHOP.PROMOTION.FORM.SAVE" />
            </button>
          </>
        )}
      </Formik>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'crop.image' })}</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
          <p>{intl.formatMessage({ id: 'default.image' })}</p>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
          {completedCrop?.width || completedCrop?.height ? (
            <>
              <p style={{ padding: '20px 0 0 0' }}>{intl.formatMessage({ id: 'preview.image' })}</p>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </>
          ) : null}
          {loading && (
            <div className="d-flex justify-content-center" style={{ margin: 10 }}>
              <span style={{ marginRight: 10, color: '#464E5F' }}>{intl.formatMessage({ id: 'compression.image' })}</span>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
              </div>
            </div>
          )}
          <button
            style={{ marginTop: 10 }}
            type="button"
            disabled={loading || !(completedCrop?.width || completedCrop?.height)}
            className="btn btn-primary"
            onClick={() => {
              submitCrop(previewCanvasRef.current, completedCrop);
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
};

export default injectIntl(ProductEditForm);

const ImageContainer = styled.div`
  height: 100px;
  width: 150px;
  margin-right: 10px;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  background-color: #fff;
`;

const DeleteImgButton = styled.div`
  text-align: center;
  color: #fff;
  background-color: #e06666;
  cursor: pointer;

  &:hover {
    background-color: #ea9999;
  }
`;

const UploadImgButton = styled.div`
  text-align: center;
  color: #fff;
  background-color: #29beca;
  cursor: pointer;

  &:hover {
    background-color: #5ed3dc;
  }
`;

const ImgNumber = styled.div`
  text-align: center;
  margin-bottom: 25px;
  color: gray;
  font-size: 20px;
`;
