import * as requestFromServer from "./customersCrud"
import { topupSlice, callTypes } from "./customersSlice"
import { productsSlice } from '../products/productsSlice'

const { actions } = topupSlice

export const fetchCustomers = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .getAllCustomers(id)
    .then(response => {
      const { topup } = response.data
      dispatch(actions.customersFetched({ totalCount: topup.length, entities: topup }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchPackages = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findShops(id)
    .then(response => {
      const { packages } = response.data
      dispatch(actions.customersFetched({ totalCount: packages.length, entities: packages }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}


export const fetchPromotions = token => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findPromotions(token)
    .then(response => {
      const { promotions } = response.data
      console.log(promotions)
      dispatch(productsSlice.actions.productsFetched({ totalCount: promotions.length, entities: promotions }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }))
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .getShopById(id)
    .then(response => {
      dispatch(actions.customerFetched({ customerForEdit: response.data.package }))
    })
    .catch(error => {
      console.log(error)
      error.clientMessage = "Can't find customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.customerDeleted({ id }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const createTopup = (customerForCreation, token) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createPackage(customerForCreation, token)
    .then(response => {
      dispatch(actions.customerCreated({ topup: response.data.topup }))
    })
    .catch(error => {
      error.clientMessage = "Can't create customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}


export const createPackage = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createPackage(customerForCreation)
    .then(response => {
      dispatch(actions.customerCreated({ package: response.data.data.package }))
    })
    .catch(error => {
      error.clientMessage = "Can't create customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const createPackagePromo = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createPackagePromo(customerForCreation)
    .then(response => {
      dispatch(actions.customerCreated({ package: response.data.data.package }))
    })
    .catch(error => {
      error.clientMessage = "Can't create customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateShop = updateShop => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateShop(updateShop)
    .then(response => {
      dispatch(actions.customerUpdated({ package: response.data.data.package }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customers status"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const clearStorage = () => dispatch => {
  dispatch(actions.clear())
}
