import axios from "axios"

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS

export const CUSTOMERS_URL = "api/customers"
export const SHOPS_URL = `${backendURL}/package`

// CREATE =>  POST: add a new customer to the server
export function createPackagePromo(shop) {
  return axios.post(`${SHOPS_URL}/fromPromo`, shop, { headers: { "Authorization": `Bearer ${shop.token}` } })
}

// CREATE =>  POST: add a new customer to the server
export function createPackage(shop) {
  return axios.post(`${SHOPS_URL}`, shop, { headers: { "Authorization": `Bearer ${shop.token}` } })
}

// READ
export function getAllCustomers(id) {
  return axios.get(`${CUSTOMERS_URL}/all/${id}`)
}

export function getShopById(shopId) {
  return axios.get(`${SHOPS_URL}/${shopId}`)
}

export function findPromotions(token) {
  return axios.get(`${SHOPS_URL}/promotions`, { headers: { "Authorization": `Bearer ${token}` } })
}

export function findPackageWithPromo(token) {
  return axios.get(`${SHOPS_URL}/withPromo`, { headers: { "Authorization": `Bearer ${token}` } })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findShops(token) {
  return axios.get(`${SHOPS_URL}`, { headers: { "Authorization": `Bearer ${token}` } })
}

// UPDATE => PUT: update the customer on the server
export function updateShop(shop) {
  return axios.put(`${SHOPS_URL}/`, shop, { headers: { 'Content-Type': 'multipart/form-data' } })
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status
  })
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${SHOPS_URL}/${customerId}`)
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids })
}
