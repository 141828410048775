import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import imageCompression from 'browser-image-compression';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../_redux/authRedux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, Button } from 'react-bootstrap';
import { getShop, getShopById, editShop, searchShopByName } from '../../_redux/authCrud';
import GenerateUrlShop from './Modal/GenerateUrlShop';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FormattedMessage } from 'react-intl';
import { compressionNormalImage, compressionSmallImage } from '../../../../utils/compressionImage';

const shareOptions = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 400,
  useWebWorker: true,
  initialQuality: 0.6
};

function EditShop({ intl }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [shopName, setShopName] = useState('');
  const [_id, set_id] = useState('');
  const [form, setForm] = useState({
    id: '',
    name: '',
    officeHours: '',
    address: '',
    mobile: '',
    picture: '',
    startPoint: 0,
    facebook: '',
    line: '',
    whatsapp: '',
    lineToken: '',
    coverPicture: '',
    createdDate: null,
    expiryDate: null
  });
  const [logo, setLogo] = useState('');
  const [cover, setCover] = useState('');
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showCrop, setShowCrop] = useState(false)
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [currentImgType, setCurrentImageType] = useState('picture')
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [defaultImg, setDefaultImg] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = id => {
    setShow(true);
    setDefault();
    getShopById(id).then(response => {
      let data = response.data.shop;
      if (data.logo && data.logo.data) {
        setLogo(process.env.REACT_APP_BACKEND_IP_ADDRESS + '/' + data.logo.data);
      }

      if (data.cover && data.cover.data) {
        setCover(process.env.REACT_APP_BACKEND_IP_ADDRESS + '/' + data.cover.data);
      }

      setForm({
        ...form,
        id: id,
        name: data.name,
        officeHours: data.officeHour ? data.officeHour : '',
        address: data.address ? data.address : '',
        mobile: data.mobile ? data.mobile : '',
        startPoint: data.startPoint ? data.startPoint : 0,
        facebook: data.facebook,
        whatsapp: data.whatsapp,
        line: data.line,
        createdDate: data.createdAt || null,
        expiryDate: data.expiryDate || null,
        lineToken: data.lineToken ? data.lineToken : ''
      });
    });
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (_id, id) => {
    setShow2(true);
    set_id(_id);
    setForm({
      ...form,
      id: id
    });
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const onSubmit = event => {
    event.preventDefault();
    var formdata = new FormData();

    formdata.append('name', form.name);
    formdata.append('officeHour', form.officeHours);
    formdata.append('address', form.address);
    formdata.append('mobile', form.mobile);
    formdata.append('startPoint', form.startPoint);
    formdata.append('facebook', form.facebook);
    formdata.append('line', form.line);
    formdata.append('whatsapp', form.whatsapp);
    formdata.append('lineToken', form.lineToken);
    formdata.append('expiryDate', form.expiryDate);
    if (form.coverPicture !== '') {
      formdata.append('cover', form.coverPicture, form.coverPicture.name);
    }
    if (form.picture !== '') {
      formdata.append('logo', form.picture, form.picture.name);
      imageCompression(form.picture, shareOptions)
        .then(function (compressedFile) {
          formdata.append('resizeLogo', compressedFile, form.picture.name);
          editShop(form.id, formdata)
            .then(response => {
              if (response.status == 201 || response.status == 200) {
                setSuccess(true);
                getShopList();
                handleClose();
              } else {
                setError(true);
              }
            })
            .catch(error => {
              setError(true);
            });
        })
        .catch(function (error) {
          setError(true);
        });
    } else {
      editShop(form.id, formdata)
        .then(response => {
          if (response.status == 201 || response.status == 200) {
            setSuccess(true);
            getShopList();
            handleClose();
          } else {
            setError(true);
          }
        })
        .catch(error => {
          setError(true);
        });
    }
  };

  const onChangeHandler = event => {
    if ((event.target.name == 'picture' || event.target.name == 'coverPicture') && event.target.files && event.target.files[0]) {
      setCurrentImageType(event.target.name)
      setForm({ ...form, [event.target.name]: event.target.files[0] });
      setShowCrop(true);
      setDefaultImg(event.target.files[0]);
      setUpImg(URL.createObjectURL(event.target.files[0]));
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const columns = [
    {
      dataField: 'seqId',
      text: 'Shop ID'
    },
    {
      dataField: 'name',
      text: 'Shop name'
    },
    {
      dataField: '_id',
      text: 'Action',
      formatter: (rowContent, row) => {
        return (
          <>
            <Button variant="primary" onClick={() => handleShow(row.seqId)}>
              {/* <i className="flaticon2-writing p-0"></i> */}
              {intl.formatMessage({ id: 'button.edit' })}
            </Button>
            &nbsp;
            {row.has_owner ? null : (
              <Button variant="success" onClick={() => handleShow2(row._id, row.seqId)}>
                {/* <i className="flaticon2-avatar p-0"></i> */}
                {intl.formatMessage({ id: 'shop.admin.url' })}
              </Button>
            )}
          </>
        );
      }
    }
  ];

  const setDefault = () => {
    setForm({
      id: '',
      name: '',
      officeHours: '',
      address: '',
      mobile: '',
      picture: '',
      startPoint: 0,
      facebook: '',
      line: '',
      whatsapp: '',
      lineToken: '',
      coverPicture: '',
      createdDate: null,
      expiryDate: null
    });
    setLogo('');
  };

  const submitCrop = async (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);

    canvas.toBlob(
      async blob => {
        const image = blob || defaultImg;
        const compressFile = await compressionNormalImage(image);
        setForm({
          ...form,
          [currentImgType]: Object.assign(compressFile, {
            name: defaultImg.name,
            preview: URL.createObjectURL(compressFile)
          })
        });
        setShowCrop(false);
        setLoading(false);
      },
      'image/png',
      1
    );
  };

  useEffect(() => {
    getShopList();
  }, []);

  const getShopList = () => {
    getShop().then(response => {
      setProducts(response.data.shops);
      setLoading(false);
    });
  };

  const searchShop = () => {
    setLoading(true);
    searchShopByName(shopName).then(response => {
      setProducts(response.data.shops);
      setLoading(false);
    });
  };

  const searchClearShop = () => {
    setLoading(true);
    setShopName('');
    searchShopByName('').then(response => {
      setProducts(response.data.shops);
      setLoading(false);
    });
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  return (
    <>
      <div className="col">
        <h1 className="my-5">{intl.formatMessage({ id: 'shop.all.shop' })}</h1>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
            </div>
          </div>
        ) : (
          <>
            <div className="input-group mb-3">
              <input
                value={shopName}
                onChange={e => setShopName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter shop name"
              />
              <div className="input-group-append">
                <button className="btn btn-secondary" type="button" onClick={() => searchClearShop()}>
                  {intl.formatMessage({ id: 'button.clear' })}
                </button>
                <button className="btn btn-info" type="button" onClick={() => searchShop()}>
                  <i className="fas fa-search"></i> {intl.formatMessage({ id: 'button.search' })}
                </button>
              </div>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              keyField="seqId"
              data={products}
              columns={columns}
              pagination={paginationFactory()}
            />
          </>
        )}

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{intl.formatMessage({ id: 'shop.edit' })}</Modal.Title>
          </Modal.Header>
          <form onSubmit={event => onSubmit(event)}>
            <div style={{ padding: '1.75rem' }}>
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'shop.name' })}</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder={intl.formatMessage({ id: 'shop.name' })}
                  value={form.name}
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.office.hours' })}</label>
                <input
                  type="text"
                  className="form-control"
                  name="officeHours"
                  placeholder={intl.formatMessage({ id: 'user.office.hours' })}
                  value={form.officeHours}
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.address' })}</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="address"
                  placeholder={intl.formatMessage({ id: 'user.address' })}
                  rows="3"
                  value={form.address}
                  onChange={onChangeHandler}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.mobile' })}</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder={intl.formatMessage({ id: 'user.mobile.placeholder' })}
                  value={form.mobile}
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.start.point' })}</label>
                <input
                  type="number"
                  className="form-control"
                  name="startPoint"
                  value={form.startPoint}
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <label>Facebook</label>
                <input
                  type="text"
                  className="form-control"
                  name="facebook"
                  value={form.facebook}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="form-group">
                <label>Whatsapp</label>
                <input
                  type="text"
                  className="form-control"
                  name="whatsapp"
                  value={form.whatsapp}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="form-group">
                <label>Line</label>
                <input type="text" className="form-control" name="line" value={form.line} onChange={onChangeHandler} />
              </div>
              <div className="form-group">
                <label>Line Token</label>
                <input type="text" className="form-control" name="lineToken" value={form.lineToken} onChange={onChangeHandler} />
              </div>
              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label>{intl.formatMessage({ id: 'shop.create.date' })}</label>
                  <div className="form-group">
                    <>
                      <DatePicker
                        disabled={true}
                        onChange={value => setForm({ ...form, createdDate: value })}
                        value={form.createdDate}
                        helperText={null}
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          'aria-readonly': true
                        }}
                        InputProps={{
                          disableUnderline: true
                        }}
                        format="MM/dd/yyyy"
                        className="form-control"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        disablePast
                      />
                    </>
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label>{intl.formatMessage({ id: 'shop.expired.date' })}</label>
                  <div className="form-group">
                    <>
                      <DatePicker
                        onChange={value => setForm({ ...form, expiryDate: value })}
                        value={form.expiryDate}
                        helperText={null}
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          'aria-readonly': true
                        }}
                        InputProps={{
                          disableUnderline: true
                        }}
                        format="MM/dd/yyyy"
                        className="form-control"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        disablePast
                      />
                    </>
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              {logo !== '' ? (
                <div className="d-flex justify-content-center mb-5">
                  <img
                    src={logo}
                    alt=""
                    style={{ height: '200px', cursor: 'pointer' }}
                    onClick={() => window.open(logo, '_blank')}
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.upload.picture' })}</label>
                <div className="custom-file">
                  <input
                    accept="image/png, image/jpeg"
                    type="file"
                    className="custom-file-input"
                    name="picture"
                    onChange={onChangeHandler}
                  />
                  <label className="custom-file-label">{form.picture.name ? form.picture.name : ''}</label>
                </div>
                <label style={{ fontSize: '0.7rem', color: 'red' }}>
                  {intl.formatMessage({ id: 'maximum.upload' }, { fileSize: '3', pixels: '200x200' })}
                </label>
              </div>
              {cover !== '' ? (
                <div className="d-flex justify-content-center mb-5">
                  <img
                    src={cover}
                    alt=""
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => window.open(cover, '_blank')}
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'user.cover.upload.picture' })}</label>
                <div className="custom-file">
                  <input
                    accept="image/png, image/jpeg"
                    type="file"
                    className="custom-file-input"
                    name="coverPicture"
                    onChange={onChangeHandler}
                  />
                  <label className="custom-file-label">{form.coverPicture.name ? form.coverPicture.name : ''}</label>
                </div>
                <label style={{ fontSize: '0.7rem', color: 'red' }}>
                  {intl.formatMessage({ id: 'maximum.upload' }, { fileSize: '3', pixels: '375x150' })}
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-end" style={{ padding: '1.75rem', borderTop: '1px solid #ecf0f3' }}>
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginRight: '1.75rem' }}
                onClick={handleClose}
              >
                <FormattedMessage id="BUTTON.CANCEL" />
              </button>
              <button type="submit" className="btn btn-primary">
                <FormattedMessage id="BUTTON.SUBMIT" />
              </button>
            </div>
          </form>
        </Modal>
        <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{intl.formatMessage({ id: 'shop.admin.url' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GenerateUrlShop id={{ _id: _id, id: form.id }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              {intl.formatMessage({ id: 'button.cancel' })}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Edit shop failed.
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Edit shop success.
        </Alert>
      </Snackbar>
      <Modal show={showCrop} onHide={() => setShowCrop(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'crop.image' })}</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
          <p>{intl.formatMessage({ id: 'default.image' })}</p>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
          {completedCrop?.width || completedCrop?.height ? (
            <>
              <p style={{ padding: '20px 0 0 0' }}>{intl.formatMessage({ id: 'preview.image' })}</p>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </>
          ) : null}
          {loading && (
            <div className="d-flex justify-content-center" style={{ margin: 10 }}>
              <span style={{ marginRight: 10, color: '#464E5F' }}>{intl.formatMessage({ id: 'compression.image' })}</span>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
              </div>
            </div>
          )}
          <button
            style={{ marginTop: 10 }}
            type="button"
            disabled={loading || !(completedCrop?.width || completedCrop?.height)}
            className="btn btn-primary"
            onClick={() => {
              submitCrop(previewCanvasRef.current, completedCrop);
            }}
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(EditShop));
