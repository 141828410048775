import * as requestFromServer from "./productsCrud"
import { productsSlice, callTypes } from "./productsSlice"
import { useSelector } from "react-redux"

const { actions } = productsSlice

export const fetchProducts = authToken => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findProducts(authToken)
    .then(response => {
      const { promotions } = response.data
      dispatch(actions.productsFetched({ totalCount: promotions.length, entities: promotions }))
    })
    .catch(error => {
      error.clientMessage = "Can't find products"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchProduct = id => dispatch => {
  if (!id) {
    return dispatch(actions.productFetched({ productForEdit: undefined }))
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .getProductById(id)
    .then(response => {
      console.log(response)
      const { promotion } = response.data
      dispatch(actions.productFetched({ productForEdit: promotion }))
    })
    .catch(error => {
      error.clientMessage = "Can't find product"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteProduct = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteProduct(id)
    .then(response => {
      dispatch(actions.productDeleted({ id }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete product"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const createProduct = productForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createProduct(productForCreation)
    .then(response => {
      const { promotion } = response.data.data
      dispatch(actions.productCreated({ promotion }))
    })
    .catch(error => {
      error.clientMessage = "Can't create promotion"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateProduct = product => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateProduct(product)
    .then(response => {
      const { promotion } = response.data.data
      dispatch(actions.productUpdated({ promotion }))
    })
    .catch(error => {
      error.clientMessage = "Can't update product"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateProductsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateStatusForProducts(ids, status)
    .then(() => {
      dispatch(actions.productsStatusUpdated({ ids, status }))
    })
    .catch(error => {
      error.clientMessage = "Can't update products status"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteProducts = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteProducts(ids)
    .then(() => {
      dispatch(actions.productsDeleted({ ids }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete products"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}
