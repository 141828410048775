const CountryConfig = {
  MY: {
    country: 'MY',
    currency: 'MYR',
    phonePrefix: '+60'
  },
  TH: {
    country: 'TH',
    currency: 'THB',
    phonePrefix: '+66'
  },
  US: {
    country: 'US',
    currency: 'USD',
    phonePrefix: '+1'
  }
}

export default CountryConfig