import React from "react"
import { Route } from "react-router-dom"
import { useSelector } from "react-redux"
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog"
import CustomerEditDialog from "./customer-edit-dialog/CustomerEditDialog"
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog"
import { CustomersUIProvider } from "./CustomersUIContext"
import { CustomersCard } from "./CustomersCard"

const CustomersPage = (props) => {
  const { history, match } = props
  const matchPage = match
  const { user } = useSelector(state => state.auth)
  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products/new`)
    },
    openEditCustomerDialog: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products/${id}/edit`)
    },
    openDeleteCustomerDialog: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products/${id}/delete`)
    },
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Route path="/shop/:seqId/admin/customers/:customerId/products/new">
        {({ history, match }) => (
          <CustomerEditDialog
            customerId={matchPage.params.id}
            show={match != null}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products`)
            }}
          />
        )}
      </Route>
      <Route path="/shop/:seqId/admin/customers/:customerId/products/:id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            customerId={matchPage.params.id}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products`)
            }}
          />
        )}
      </Route>
      <Route path="/shop/:seqId/admin/customers/:customerId/products/:id/delete">
        {({ history, match }) => (
          <CustomerDeleteDialog
            customerId={matchPage.params.id}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers/${matchPage.params.id}/products`)
            }}
          />
        )}
      </Route>
      <CustomersCard id={matchPage.params.id} />
    </CustomersUIProvider>
  )
}

export default CustomersPage 
