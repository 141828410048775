import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TextField, Button, Switch } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createSales } from '../../../_redux/sales/actions';
import { useSelector } from 'react-redux';
import { NAME_REGEX } from './ActionsItem';
import { FormattedMessage } from 'react-intl';

const CreateSales = (props) => {
  const { modal, handleCloseModal, onSubmitSuccess } = props
  const shop = useSelector(state => state.auth.shop)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true)
    await createSales({
      name: name,
      shop: shop._id
    })
    setLoading(false)
    onSubmitSuccess('Create sales success')
    handleCloseModal()
  }

  const handleOnChange = (e) => {
    if (NAME_REGEX.test(e.target.value)) {
      setName(e.target.value)
    }
  }

  return (
    <>
      <Dialog open={modal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Create new sales</DialogTitle>
        <DialogContent>
          <TextField value={name} autoFocus fullWidth onChange={handleOnChange} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} onClick={handleCloseModal}>
            <FormattedMessage id="BUTTON.CANCEL" />
          </Button>
          <Button color="primary" disabled={loading || !name} onClick={handleSubmit}>
            Confirm
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </>
  )
}

export default CreateSales