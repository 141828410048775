import * as requestFromServer from "./customersCrud"
import { qrCodeSlice, callTypes } from "./customersSlice"

const { actions } = qrCodeSlice

export const fetchCustomers = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findShops(id)
    .then(response => {
      const qrCodes = response.data
      console.log(qrCodes)
      dispatch(actions.customersFetched({ totalCount: qrCodes.length, entities: qrCodes }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchCustomersProducts = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findCustomerProducts(id)
    .then(response => {
      const { products } = response.data
      console.log(products)
      dispatch(actions.customersFetched({ totalCount: products.length, entities: products }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }))
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .getShopById(id)
    .then(response => {
      const { product } = response.data
      console.log(product)
      dispatch(actions.customerFetched({ customerForEdit: product }))
    })
    .catch(error => {
      console.log(error)
      error.clientMessage = "Can't find customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.customerDeleted({ id }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const createShop = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createShop(customerForCreation)
    .then(response => {
      const qrCode = response.data
      console.log(qrCode)
      dispatch(actions.customerCreated({ qrCode }))
    })
    .catch(error => {
      error.clientMessage = "Can't create customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateShop = updateShop => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateShop(updateShop)
    .then(response => {
      console.log(response)
      const { product } = response.data.data
      console.log('respro', product)
      dispatch(actions.customerUpdated({ product }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customers status"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}
