/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import styled from 'styled-components';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import FreePersonIcon from '../../../../assets/free_person.png';
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown'
const IconContainer = styled.div`
  width: 35px;
  height: 35px;
`;

const PersonIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export function QuickUserToggler() {
  const { user, shop } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas'
    };
  }, [uiService]);

  const fullName = user ? `${user.firstName && user.firstName} ${user.lastName && user.lastName}` : '';

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item w-100">

          <div className="col-sm"></div>

          <div className="col-sm d-flex justify-content-end">
            <LanguageSelectorDropdown />
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}>
              <div
                className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                id="kt_quick_user_toggle"
              >
                <>
                  {user && (user.firstName || user.lastName) ? (
                    <>
                      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 text-nowrap">
                        {fullName}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="symbol symbol-35 symbol-light-success">
                    {user && (user.facebookProfile || (user.lineProfile && user.lineProfile.pictureUrl)) ? (
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${user.type === 'admin' ? '' : user.facebookProfile || user.lineProfile.pictureUrl
                            })`
                        }}
                      />
                    ) : (
                      <IconContainer>
                        <PersonIcon src={FreePersonIcon} />
                      </IconContainer>
                    )}
                  </span>
                </>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
