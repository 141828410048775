import React, { useMemo } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls"
import { CustomersFilter } from "./customers-filter/CustomersFilter"
import ShopsTable from "./customers-table/CustomersTable"
import { useCustomersUIContext } from "./CustomersUIContext"

export function CustomersCard({ id }) {
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    }
  }, [customersUIContext])

  return (
    <Card>
      <CardHeader title="Permanent Point QR Code">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Generate QR code
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <ShopsTable id={id} />
      </CardBody>
    </Card>
  )
}
