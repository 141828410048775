/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from "@date-io/date-fns"
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_helpers';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import FreePersonIcon from '../../../../../assets/free_person.png';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { logout } from '../../../../../app/modules/Auth/_redux/authCrud';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;

const Screenmode = {
  UserProfile: 'profile',
  Edit: 'edit',
  ChangePassword: 'password'
}

const QuickUser = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const shop = useSelector(state => state.auth.shop);
  const [changePasswordForm, setChangePasswordForm] = useState({ old_password: '', password: '', re_password: '' })
  const [passwordError, setPasswordError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [mode, setMode] = useState(Screenmode.UserProfile);
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userProfile, setUserProfile] = useState({ firstName: '', lastName: '', email: '', birthdate: '', shippingAddress: '', remark: '' })

  const logoutClick = async () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    const loginPath = user.type === 'admin' ? '/admin/login' : `/shop/${user.seqId}/login`;
    await logout()
    props.logout();
    history.push(loginPath);
  };

  useEffect(() => {
    mappingUserProfile()
  }, [user]);

  const mappingUserProfile = () => {
    if (user) {
      setUserProfile({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        birthdate: user.birthdate,
        shippingAddress: user.shippingAddress,
        remark: user.remark
      })
    }
  }

  const onSubmit = async event => {
    event.preventDefault();
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (userProfile.email && !emailRegex.test(userProfile.email)) {
      setError(true)
      return;
    }

    const { data } = await axios.patch(`${backendURL}/customer/${user.id}`, userProfile)
    if (data.success) {
      setSuccess(true)
    }
    dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: { ...data.data, id: data.data._id, seqId: user.seqId, type: user.type } } });
    setMode(Screenmode.UserProfile);
  }

  const onSubmitChangePassword = async event => {
    event.preventDefault();

    if (changePasswordForm.re_password !== changePasswordForm.password) {
      setPasswordError('Password do not match. Please enter password again.')
      setChangePasswordForm({ old_password: '', password: '', re_password: '' })
      return;
    }

    if (changePasswordForm.re_password.length < 6 || changePasswordForm.password.length < 6) {
      setPasswordError('Password must have at least 6 characters.')
      setChangePasswordForm({ old_password: '', password: '', re_password: '' })
      return;
    }

    try {
      const { data } = await axios.patch(`${backendURL}/customer/${user.id}`, changePasswordForm)
      if (data.success) {
        setPasswordSuccess('Change password success.')
        dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: { ...data.data, id: data.data._id, seqId: user.seqId, type: user.type } } });
        setChangePasswordForm({ old_password: '', password: '', re_password: '' })
        setMode(Screenmode.UserProfile);
      } else {
        setPasswordError(data.message)
        setChangePasswordForm({ old_password: '', password: '', re_password: '' })
      }
    } catch (error) {
      setPasswordError(error.response.data.msg || error.response.data.message)
      setChangePasswordForm({ old_password: '', password: '', re_password: '' })
    }

  }

  const onCloseButton = () => {
    setMode(Screenmode.UserProfile);
    mappingUserProfile()
  }

  const onChange = e => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
  }

  const onDateChange = date => {
    setUserProfile({ ...userProfile, birthdate: new Date(date) })
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          {mode === Screenmode.UserProfile && 'User Profile'}
          {mode === Screenmode.Edit && 'Edit your profile'}
          {mode === Screenmode.ChangePassword && 'Change password'}
        </h3>
        <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close" onClick={() => onCloseButton()}>
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={mode}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div>
            {(user && mode === Screenmode.UserProfile) &&
              <div className="offcanvas-content pr-5 mr-n5 fade-user">
                <div className="d-flex align-items-center mt-5 justify-content-between">
                  <div className="symbol symbol-100 mr-5 symbol-light-success">
                    {user && (user.facebookProfile || (user.lineProfile && user.lineProfile.pictureUrl)) ? (
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${user.type === 'admin' ? '' : user.facebookProfile || user.lineProfile.pictureUrl
                            })`
                        }}
                      />
                    ) : (
                      <IconContainer>
                        <PersonIcon src={FreePersonIcon} />
                      </IconContainer>
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div className="navi mt-2 mb-5">
                      <a href="#" className="navi-item">
                        <span className="navi-link p-0 pb-2">
                          <span className="navi-icon mr-1">
                            <span className="svg-icon-lg svg-icon-primary">
                              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}></SVG>
                            </span>
                          </span>
                          <span className="navi-text text-muted text-hover-primary">{user.email}</span>
                        </span>
                      </a>
                    </div>
                    <button className="btn purple-button btn-bold" onClick={logoutClick}>
                      Sign out
                    </button>
                  </div>
                </div>
                <div className="separator separator-solid mt-8 mb-5" style={{ borderColor: '#C4C4C4' }} />
                <div className="navi navi-spacer-x-0 pl-3">
                  {user.firstName && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_FIRSTNAME" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.firstName}</div>
                    </div>
                  </div>}
                  {user.lastName && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_LASTNAME" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.lastName}</div>
                    </div>
                  </div>}
                  {user.phone && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_PHONE" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.phone}</div>
                    </div>
                  </div>}
                  {user.email && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_EMAIL" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.email}</div>
                    </div>
                  </div>}
                  {user.birthdate && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_BIRTHDAY" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.birthdate ? moment(user.birthdate).format('DD/MM/YYYY') : '-'}</div>
                    </div>
                  </div>}
                  <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_POINT" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.point}</div>
                    </div>
                  </div>
                  <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_CREDIT" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.credit}</div>
                    </div>
                  </div>
                  {user.shippingAddress && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_SHIPPING" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.shippingAddress}</div>
                    </div>
                  </div>}
                  {user.remark && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        <FormattedMessage id="USER_REMARK" />
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.remark}</div>
                    </div>
                  </div>}
                </div>
                <div className="separator separator-solid my-7" style={{ borderWidth: '2px', borderColor: '#C4C4C4' }} />
                <div className="d-flex justify-content-center">
                  <button className="btn purple-button btn-bold mr-3" onClick={() => setMode(Screenmode.Edit)}>
                    Edit your profile
                  </button>
                  {(user && user.type !== 'customer') &&
                    <button className="btn purple-button btn-bold" onClick={() => setMode(Screenmode.ChangePassword)}>
                      Change password
                    </button>}
                </div>
                {shop && user.type !== 'customer' ? <ExpiredDateContainer>
                  <DateLabelStyled>Create shop date: {shop.createdAt ? moment(shop.createdAt).format('DD/MM/YYYY') : '-'}</DateLabelStyled>
                  <DateLabelStyled>Expired shop date: {shop.expiryDate ? moment(shop.expiryDate).format('DD/MM/YYYY') : '-'}</DateLabelStyled>
                </ExpiredDateContainer> : null}
              </div>}
            {(user && mode === Screenmode.Edit) &&
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_FIRSTNAME" />
                    </div>
                    <input className="form-control" name="firstName" value={userProfile.firstName} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_LASTNAME" />
                    </div>
                    <input className="form-control" name="lastName" value={userProfile.lastName} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_EMAIL" />
                    </div>
                    <input className="form-control" name="email" value={userProfile.email} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_BIRTHDAY" />
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker className="form-control"
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          "aria-readonly": true,
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        format="dd/MM/yyyy"
                        value={userProfile.birthdate} onChange={date => onDateChange(date)} />
                    </MuiPickersUtilsProvider>
                    {/* <input className="form-control"/> */}
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_SHIPPING" />
                    </div>
                    <textarea className="form-control" rows="3" name="shippingAddress" value={userProfile.shippingAddress} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_REMARK" />
                    </div>
                    <textarea className="form-control" rows="3" name="remark" value={userProfile.remark} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button type="button" className="btn btn-light btn-bold" onClick={() => {
                      setMode(Screenmode.UserProfile);
                      mappingUserProfile();
                    }}>
                      <FormattedMessage id="BUTTON.CANCEL" />
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      <FormattedMessage id="BUTTON.SUBMIT" />
                    </button>
                  </div>
                </form>
              </div>}
            {(user && mode === Screenmode.ChangePassword) &&
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={(e) => onSubmitChangePassword(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_OLD_PASSWORD" />
                    </div>
                    <input type="password" className="form-control" name="old_password" value={changePasswordForm.old_password} onChange={(e) => setChangePasswordForm({ ...changePasswordForm, old_password: e.target.value })} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_PASSWORD" />
                    </div>
                    <input type="password" className="form-control" name="password" value={changePasswordForm.password} onChange={(e) => setChangePasswordForm({ ...changePasswordForm, password: e.target.value })} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_RE_PASSWORD" />
                    </div>
                    <input type="password" className="form-control" name="re_password" value={changePasswordForm.re_password} onChange={(e) => setChangePasswordForm({ ...changePasswordForm, re_password: e.target.value })} />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button type="button" className="btn btn-light btn-bold" onClick={() => {
                      setMode(Screenmode.UserProfile);
                      mappingUserProfile();
                    }}>
                      <FormattedMessage id="BUTTON.CANCEL" />
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      <FormattedMessage id="BUTTON.SUBMIT" />
                    </button>
                  </div>
                </form>
              </div>}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Invalid email format.
        </Alert>
      </Snackbar>
      <Snackbar open={passwordError !== false} autoHideDuration={4000} onClose={() => setPasswordError(false)}>
        <Alert onClose={() => setPasswordError(false)} severity="error">
          {passwordError}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Edit profile success.
        </Alert>
      </Snackbar>
      <Snackbar open={passwordSuccess !== false} autoHideDuration={4000} onClose={() => setPasswordSuccess(false)}>
        <Alert onClose={() => setPasswordSuccess(false)} severity="success">
          {passwordSuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default connect(null, auth.actions)(QuickUser)

const IconContainer = styled.div`
width: 100px;
height: 100px;
`;

const PersonIcon = styled.img`
width: 100px;
height: 100px;
`;

const ExpiredDateContainer = styled.div`
  background-color: #17b987;
  color: #000;
  margin: 20px 15px 20px 15px;
  padding: 10px 0 5px 0;
  text-align: center;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
`

const DateLabelStyled = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`