import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/customersManage/customersActions"
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader"
import CustomerEditForm from "./CustomerEditForm"
import { useCustomersUIContext } from "../CustomersUIContext"

export function CustomerEditDialog({
  id, show, onHide }) {
  // Customers UI Context
  const [files, setFiles] = useState([])
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    }
  }, [customersUIContext])

  // Customers Redux state
  const dispatch = useDispatch()
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customersManage.actionsLoading,
      customerForEdit: state.customersManage.customerForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id))
  }, [id, dispatch])

  // server request for saving customer
  const saveShop = (customer) => {
    const pointData = new FormData()
    pointData.append('shopUserId', customer._id)
    // pointData.append('oldPoint', customer.point)
    pointData.append('point', customer.newPoint)
    if (files.length > 0) {
      files.forEach(file => pointData.append('images', file))

    }
    console.log(...pointData)
    // server request for creating customer
    dispatch(actions.updateShop(pointData)).then(() => onHide())
    setFiles([])
    // server request for updating customer
    // shopData.append('seqId', id)
    // dispatch(actions.updateShop(shopData)).then(() => onHide())
    // setFile(null)
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        id={id}
        saveShop={saveShop}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        onHide={onHide}
        files={files}
        setFiles={setFiles}
      />
    </Modal>
  )
}
