import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/customerProduct/customersActions"
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader"
import CustomerEditForm from "./CustomerEditForm"
import { useCustomersUIContext } from "../CustomersUIContext"

const CustomerEditDialog = ({
  id, show, onHide, customerId }) => {
  // Customers UI Context
  const [files, setFiles] = useState([])
  const [removedFiles, setRemovedFiles] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(false)
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    }
  }, [customersUIContext])

  // Customers Redux state
  const dispatch = useDispatch()
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customerProducts.actionsLoading,
      customerForEdit: state.customerProducts.customerForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id))
  }, [id, dispatch])

  useEffect(() => {
    if (customerForEdit) {
      setStartDate(customerForEdit.start)
      setEndDate(customerForEdit.end)
      if (customerForEdit.images) {
        setFiles(customerForEdit.images.map(file => ({
          uid: uuid(),
          name: file,
          status: 'done',
          url: `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/customers/products/${file}`,
          thumbUrl: `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/customers/products/${file}`,
        })))
      }
    } else {
      setFiles([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerForEdit])

  // server request for saving customer
  const saveShop = (product) => {
    if (!startDate || !endDate) {
      setError('Please enter start date and end date')
      return
    } else if (moment(startDate).isAfter(moment(endDate))) {
      setError('End date must after start date')
      return
    }
    const productData = new FormData()
    productData.append('customer', customerId)
    productData.append('name', product.name)
    productData.append('description', product.description)
    productData.append('start', startDate)
    productData.append('end', endDate)
    if (removedFiles.length > 0) {
      removedFiles.forEach(file => productData.append('deleteImages[]', file.name))
    }
    if (files.length > 0) {
      files.filter(file => file.new).forEach(file => productData.append('images', file))
    }
    if (!id) {
      dispatch(actions.createShop(productData)).then(() => onHide())
    } else {
      productData.append('id', id)
      dispatch(actions.updateShop(productData, id)).then(() => onHide())
    }
    setFiles([])
    setRemovedFiles([])
    setStartDate(null)
    setEndDate(null)
    setError(false)
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setFiles(_ => ([]))
        setRemovedFiles([])
        setStartDate(null)
        setEndDate(null)
        setError(false)
        onHide()
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        id={id}
        saveShop={saveShop}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        onHide={onHide}
        files={files}
        setFiles={setFiles}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        removedFiles={removedFiles}
        setRemovedFiles={setRemovedFiles}
        error={error}
      />
    </Modal>
  )
}

export default CustomerEditDialog
