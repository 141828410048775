const shopToken = `_st-`

export const setTokenBySeqId = (seqId, token) => {
    window.localStorage.setItem(`${shopToken}${seqId}`, token);
}

export const getTokenBySeqId = (seqId) => {
    return window.localStorage.getItem(`${shopToken}${seqId}`);
}
export const removeTokenBySeqId = (seqId) => {
    window.localStorage.removeItem(`${shopToken}${seqId}`);
}

