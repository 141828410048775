import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import { Select, MenuItem, Button } from "@material-ui/core";
import { injectIntl } from 'react-intl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers"
import { Container } from './styles'
import moment from 'moment-timezone'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import font from './fontbase64'
import CountryConfig from '../../../CountryConfig';

// const TYPE = ['ความเคลื่อนไหวและการใช้งาน', 'ข้อมูลลูกค้า', 'แพคเกจพิเศษ', 'Topup money']

// intl.formatMessage({ id: 'loading' })
const Report = ({ intl }) => {
    const { authToken, user } = useSelector(state => state.auth)
    const [type, setType] = useState(0)
    const [range, setRange] = useState('date')
    const [month, setMonth] = useState(new Date().getMonth())
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(null);
    const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
    const TYPE = [intl.formatMessage({ id: 'report.transaction.detail' }), intl.formatMessage({ id: 'report.customer.detail' }), 'Special Package', 'Topup money']

    const formatDate = (value, format) => {
        const date = moment(value)
            .tz(moment.tz.guess())
            .format(format == 'bd' ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')
            .split(',');
        return `${date[0]} ${format == 'bd' ? '' : date[1]}`
    };

    const getWhatsappHyperlink = (mobile, mode) => {
        if (!mobile) return ''
        const prefix = CountryConfig[process.env.REACT_APP_COUNTRY].phonePrefix
        let addPrefixMobile = prefix + mobile.substring(1)

        if (mode === 'pdf') {
            return `https://wa.me/${addPrefixMobile}`
        } else {
            return `=HYPERLINK("https://wa.me/${addPrefixMobile}")`
        }

    }

    const fetch = async (mail) => {
        if (mail && !email) {
            alert('Email is required.')
            return
        }

        const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS
        const result = await axios.post(`${backendURL}/report`, { type, range, month, from, to }, { headers: { "Authorization": `Bearer ${authToken}` } })

        const { data } = result
        let head
        let table
        let tablePdf
        if (type === 0) {
            head = ['Date', 'Phone number', 'Name', 'Birthdate', 'Email', 'Point', 'New Point', 'Description']
            table = data.map((row, index) => [
                `${index + 1}`,
                formatDate(row?.createdAt),
                `${row?.shopUser?.user?.phone || ''}`,
                `${row?.shopUser?.user?.firstName || ''} ${row?.shopUser?.user?.lastName || ''}`,
                `${row?.shopUser?.user?.birthdate ? formatDate(row?.shopUser?.user?.birthdate, 'bd') : ''}`,
                `${row?.shopUser?.user?.email || ''}`,
                `${row?.point > 0 ? `+${row?.point}` : row?.point}`,
                `${row?.newPoint || ''}`,
                `${row?.description || ''}`
            ])
            tablePdf = data.map((row, index) => [
                `${index + 1}`,
                formatDate(row?.createdAt),
                `${row?.shopUser?.user?.phone || ''}`,
                `${row?.shopUser?.user?.firstName || ''} ${row?.shopUser?.user?.lastName || ''}`,
                `${row?.shopUser?.user?.birthdate ? formatDate(row?.shopUser?.user?.birthdate, 'bd') : ''}`,
                `${row?.shopUser?.user?.email || ''}`,
                `${row?.point > 0 ? `+${row?.point}` : row?.point}`,
                `${row?.newPoint || ''}`,
                `${row?.description || ''}`
            ])
        } else if (type === 1) {
            head = ['Name', 'Group Member Name', 'Age', 'Birthday', 'Phone number', 'LINE Email', 'Whatsapp', 'Custom Email', 'Subscription Date', 'Last Usage', 'point', 'credit', 'note', 'Shipping address', 'Sales']
            table = data.map((row, index) => [
                `${index + 1}`,
                row?.user?.firstName || '',
                row?.name || '',
                row?.user?.birthdate ? moment().diff(row?.user?.birthdate, 'years') : '',
                row?.user?.birthdate ? moment(row.user?.birthdate).format('DD/MM/YYYY') : '',
                row.user?.phone || '',
                row.user?.lineProfile?.email || '',
                getWhatsappHyperlink(row.user?.phone, 'excel'),
                // row.user?.phone ? `=HYPERLINK("https://wa.me/${row.user?.phone.replace('+', '')}")` : '',
                row.user?.email || '',
                moment(row?.createdAt).format('DD/MM/YYYY'),
                moment(row?.user?.recentUse).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.point || '',
                row?.credit || '',
                row?.note || '',
                row?.user?.shippingAddress || '',
                row?.salesName || ''
            ])
            tablePdf = data.map((row, index) => [
                `${index + 1}`,
                row?.user?.firstName || '',
                row?.name || '',
                row?.user?.birthdate ? moment().diff(row?.user?.birthdate, 'years') : '',
                row?.user?.birthdate ? moment(row.user?.birthdate).format('DD/MM/YYYY') : '',
                row.user?.phone || '',
                row.user?.lineProfile?.email || '',
                getWhatsappHyperlink(row.user?.phone, 'pdf'),
                // row.user?.phone ? `=HYPERLINK("https://wa.me/${row.user?.phone.replace('+', '')}")` : '',
                row.user?.email || '',
                moment(row?.createdAt).format('DD/MM/YYYY'),
                moment(row?.user?.recentUse).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.point || '',
                row?.credit || '',
                row?.note || '',
                row?.user?.shippingAddress || '',
                row?.salesName || ''
            ])
        } else if (type === 3) {
            head = ['Date', 'Name', 'Quantity', 'Expire', 'Remaining']
            table = data.map((row, index) => [
                `${index + 1}`,
                moment(row?.createdAt).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.target?.user?.firstName || '',
                row?.credit || '',
                moment(row?.expire).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.newCredit || '',
            ])
            tablePdf = data.map((row, index) => [
                `${index + 1}`,
                moment(row?.createdAt).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.target?.user?.firstName || '',
                row?.credit || '',
                moment(row?.expire).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
                row?.newCredit || '',
            ])
        }

        const doc = new jsPDF({ orientation: 'landscape', filters: ["ASCIIHexEncode"] })
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        doc.autoTable({
            head: [['#', ...head]],
            body: tablePdf,
            pageBreak: 'auto',
            styles: {
                font: 'THSarabunNew',
                fontStyle: 'normal',
            },
            headStyles: { fontStyle: 'bold', fillColor: '#fff', textColor: '#000', font: 'THSarabunNew' }
        })

        if (!mail) {
            doc.save(`${TYPE[type]}.pdf`)
            const xlsx = await axios.post(`${backendURL}/report/excel`, { dataSet: [['', ...head], ...table] }, {
                responseType: 'blob'
            })
            const url = window.URL.createObjectURL(new Blob([xlsx.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${TYPE[type]}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } else {
            const pdf = doc.output('datauristring')
            const formData = new FormData()
            formData.append('name', TYPE[type])
            formData.append('email', email)
            formData.append('pdf', pdf)
            const dataSet = [['', ...head], ...table]
            dataSet.forEach((data, index) => data.forEach((item) => formData.append(`dataSet[${index}]`, item)))
            setSuccess(true)
            const response = await axios.post(`${backendURL}/report/email`, formData, { headers: { "Authorization": `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' } })
        }

    }

    return (
        <Container>
            <h3>{intl.formatMessage({ id: 'menu.report' })}</h3>
            <Select
                value={type}
                onChange={e => setType(e.target.value)}
                variant="outlined"
            >
                <MenuItem value={0}>{TYPE[0]}</MenuItem>
                <MenuItem value={1}>{TYPE[1]}</MenuItem>
                {/* <MenuItem value={2}>{TYPE[2]}</MenuItem>
                <MenuItem value={3}>{TYPE[3]}</MenuItem> */}
            </Select>
            <h4 className="select-datetime">{intl.formatMessage({ id: 'report.period' })}</h4>
            {/* <div className="month">
                <Radio value="month" onChange={e => setRange(e.target.value)} checked={range === 'month'} />
                Month &nbsp;
            <div>
                    <Select
                        value={month}
                        onChange={e => setMonth(e.target.value)}
                    >
                        {
                            [
                                "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
                            ].filter((_, i) => i <= new Date().getMonth()).map((month, index) => <MenuItem value={index}>{index === (new Date().getMonth()) ? 'Current': month}</MenuItem>)
                        }
                    </Select>
                </div>
            </div> */}
            <div className="month mb-5">
                {/* <Radio value="date" disabled onChange={e => setRange(e.target.value)} checked={range === 'date'} /> */}
                {/* Date Range */}
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="form-group">
                    <label>{intl.formatMessage({ id: 'report.from.date' })}</label>
                    <DatePicker
                        error={false}
                        helperText={null}
                        inputProps={{
                            style: {
                                fontFamily: `Poppins, Helvetica, "sans-serif"`,
                                fontWeight: 400,
                                color: 'rgb(70, 78, 95)',
                                display: 'block',
                                padding: '0.65rem 1rem',
                                border: '1px solid #E5EAEE',
                                borderRadius: '0.42rem'
                            },
                            "aria-readonly": true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        format="dd/MM/yyyy"
                        value={from}
                        onChange={(date) => setFrom(date)}
                        className="form-control"
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label>{intl.formatMessage({ id: 'report.to.date' })}</label>
                <div className="form-group">
                    <>
                        <DatePicker
                            error={false}
                            helperText={null}
                            inputProps={{
                                style: {
                                    fontFamily: `Poppins, Helvetica, "sans-serif"`,
                                    fontWeight: 400,
                                    color: 'rgb(70, 78, 95)',
                                    display: 'block',
                                    padding: '0.65rem 1rem',
                                    border: '1px solid #E5EAEE',
                                    borderRadius: '0.42rem'
                                },
                                "aria-readonly": true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            format="dd/MM/yyyy"
                            value={to}
                            onChange={(date) => setTo(date)}
                            className="form-control"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </>
                </div>
            </MuiPickersUtilsProvider>
            <div>
                <label>{intl.formatMessage({ id: 'user.email' })}</label>
                <input
                    className="form-control"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>
            <div className="btn-report">
                <Button variant="contained" color="primary" onClick={() => fetch()}>{intl.formatMessage({ id: 'button.download' })}</Button>
                <div className="email-btn">
                    <Button variant="contained" onClick={() => fetch('mail')}>{intl.formatMessage({ id: 'report.send.to.email' })}</Button>
                </div>
            </div>
            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success">
                    {intl.formatMessage({ id: 'report.has.been.sent' })}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default injectIntl(Report)
