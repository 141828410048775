import { all } from "redux-saga/effects"
import { combineReducers } from "redux"

import * as auth from "../app/modules/Auth/_redux/authRedux"
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice"
import { customersManageSlice } from '../app/modules/ECommerce/_redux/customersManage/customersSlice'
import { customerProductsSlice } from '../app/modules/ECommerce/_redux/customerProduct/customersSlice'
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice"
import { packagesSlice } from "../app/modules/ECommerce/_redux/packages/customersSlice"
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice"
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice"
import { topupSlice } from "../app/modules/ECommerce/_redux/topup/customersSlice"
import { qrCodeSlice } from "../app/modules/ECommerce/_redux/qrCode/customersSlice"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  customersManage: customersManageSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  customerProducts: customerProductsSlice.reducer,
  packages: packagesSlice.reducer,
  topup: topupSlice.reducer,
  qrCode: qrCodeSlice.reducer,

})

export function* rootSaga() {
  yield all([auth.saga()])
}
