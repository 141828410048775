import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../_redux/authRedux';
import { createShop } from '../../_redux/authCrud';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReactCrop from 'react-image-crop';
import { Modal } from 'react-bootstrap';
import { compressionNormalImage, compressionSmallImage } from '../../../../utils/compressionImage';

function CreateShop({ intl }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [form, setForm] = useState({
    name: '',
    officeHours: '',
    address: '',
    mobile: '',
    picture: '',
    startPoint: 0,
    facebook: '',
    line: '',
    whatsapp: '',
    expiryDate: null,
    lineToken: '',
    coverPicture: ''
  });
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [show, setShow] = useState(false);
  const [currentImgType, setCurrentImageType] = useState('picture')
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [defaultImg, setDefaultImg] = useState(null);

  const onSubmit = async event => {
    event.preventDefault();
    if (!form.expiryDate) return setError('Please fill expired date.');

    setLoading(true);
    var formdata = new FormData();

    formdata.append('name', form.name);
    formdata.append('officeHour', form.officeHours);
    formdata.append('address', form.address);
    formdata.append('mobile', form.mobile);
    formdata.append('startPoint', form.startPoint);
    formdata.append('facebook', form.facebook);
    formdata.append('line', form.line);
    formdata.append('lineToken', form.lineToken);
    formdata.append('whatsapp', form.whatsapp);
    formdata.append('expiryDate', form.expiryDate);
    formdata.append('logo', form.picture, form.picture.name);
    formdata.append('cover', form.coverPicture, form.coverPicture.name);
    const compressedResizeLogo = await compressionSmallImage(form.picture);
    formdata.append('resizeLogo', compressedResizeLogo, form.picture.name);
    createShop(formdata)
      .then(response => {
        if (response.status == 201) {
          setSuccess(true);
          setForm({
            name: '',
            officeHours: '',
            address: '',
            mobile: '',
            picture: '',
            startPoint: 0,
            facebook: '',
            line: '',
            whatsapp: '',
            expiryDate: null,
            lineToken: '',
            coverPicture: ''
          });
        } else {
          setError('Create shop failed.');
        }
        setLoading(false);
      })
      .catch(error => {
        setError('Create shop failed.');
        setLoading(false);
      });
  };

  const onChangeHandler = event => {
    if ((event.target.name == 'picture' || event.target.name == 'coverPicture') && event.target.files && event.target.files[0]) {
      setCurrentImageType(event.target.name)
      setForm({ ...form, [event.target.name]: event.target.files[0] });
      setShow(true);
      setDefaultImg(event.target.files[0]);
      setUpImg(URL.createObjectURL(event.target.files[0]));
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const submitCrop = async (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);

    canvas.toBlob(
      async blob => {
        const image = blob || defaultImg;
        const compressFile = await compressionNormalImage(image);
        setForm({
          ...form,
          [currentImgType]: Object.assign(compressFile, {
            name: defaultImg.name,
            preview: URL.createObjectURL(compressFile)
          })
        });
        setShow(false);
        setLoading(false);
      },
      'image/png',
      1
    );
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  return (
    <>
      <div className="col overflow-auto">
        <h1 className="my-5">{intl.formatMessage({ id: 'shop.create.new.shop' })}</h1>
        <form onSubmit={event => onSubmit(event)}>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'shop.name' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder={intl.formatMessage({ id: 'shop.name' })}
              value={form.name}
              onChange={onChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.office.hours' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <input
              type="text"
              className="form-control"
              name="officeHours"
              placeholder="Example: (7.00 AM - 6.00 PM) Closed on Sunday"
              value={form.officeHours}
              onChange={onChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.address' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <textarea
              type="text"
              className="form-control"
              name="address"
              placeholder={intl.formatMessage({ id: 'user.address' })}
              rows="3"
              value={form.address}
              onChange={onChangeHandler}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.mobile' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              placeholder={intl.formatMessage({ id: 'user.mobile.placeholder' })}
              value={form.mobile}
              onChange={onChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.start.point' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <input
              type="number"
              className="form-control"
              name="startPoint"
              value={form.startPoint}
              onChange={onChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label>Facebook</label>
            <input
              type="text"
              className="form-control"
              name="facebook"
              value={form.facebook}
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group">
            <label>Whatsapp</label>
            <input
              type="text"
              className="form-control"
              name="whatsapp"
              value={form.whatsapp}
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group">
            <label>Line</label>
            <input type="text" className="form-control" name="line" value={form.line} onChange={onChangeHandler} />
          </div>
          <div className="form-group">
            <label>Line Token</label>
            <input type="text" className="form-control" name="lineToken" value={form.lineToken} onChange={onChangeHandler} />
          </div>
          <div className="form-group">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <label>
                {intl.formatMessage({ id: 'shop.expired.date' })}<label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
              </label>
              <div className="form-group">
                <>
                  <DatePicker
                    onChange={value => setForm({ ...form, expiryDate: value })}
                    value={form.expiryDate}
                    helperText={null}
                    inputProps={{
                      style: {
                        fontFamily: `Poppins, Helvetica, "sans-serif"`,
                        fontWeight: 400,
                        color: 'rgb(70, 78, 95)',
                        display: 'block',
                        padding: '0.65rem 1rem',
                        border: '1px solid #E5EAEE',
                        borderRadius: '0.42rem'
                      },
                      'aria-readonly': true
                    }}
                    InputProps={{
                      disableUnderline: true
                    }}
                    format="MM/dd/yyyy"
                    className="form-control"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    disablePast
                  />
                </>
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.upload.picture' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <div className="custom-file">
              <input
                accept="image/png, image/jpeg"
                type="file"
                className="custom-file-input"
                name="picture"
                onChange={onChangeHandler}
                required
              />
              <label className="custom-file-label">{form.picture.name ? form.picture.name : 'Choose file...'}</label>
            </div>
            <label style={{ fontSize: '0.7rem', color: 'red' }}>
              {intl.formatMessage({ id: 'maximum.upload' }, { fileSize: '3', pixels: '200x200' })}
            </label>
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'user.cover.upload.picture' })}</label>
            <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
            <div className="custom-file">
              <input
                accept="image/png, image/jpeg"
                type="file"
                className="custom-file-input"
                name="coverPicture"
                onChange={onChangeHandler}
                required
              />
              <label className="custom-file-label">{form.coverPicture.name ? form.coverPicture.name : 'Choose file...'}</label>
            </div>
            <label style={{ fontSize: '0.7rem', color: 'red' }}>
              {intl.formatMessage({ id: 'maximum.upload' }, { fileSize: '3', pixels: '375x150' })}
            </label>
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading} style={{ width: '100px' }}>
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
              </div>
            ) : (
              <FormattedMessage id="BUTTON.SUBMIT" />
            )}
          </button>
        </form>
      </div>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {intl.formatMessage({ id: 'alert.create.shop.success' })}
        </Alert>
      </Snackbar>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'crop.image' })}</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
          <p>{intl.formatMessage({ id: 'default.image' })}</p>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
          {completedCrop?.width || completedCrop?.height ? (
            <>
              <p style={{ padding: '20px 0 0 0' }}>{intl.formatMessage({ id: 'preview.image' })}</p>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </>
          ) : null}
          {loading && (
            <div className="d-flex justify-content-center" style={{ margin: 10 }}>
              <span style={{ marginRight: 10, color: '#464E5F' }}>{intl.formatMessage({ id: 'compression.image' })}</span>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
              </div>
            </div>
          )}
          <button
            style={{ marginTop: 10 }}
            type="button"
            disabled={loading || !(completedCrop?.width || completedCrop?.height)}
            className="btn btn-primary"
            onClick={() => {
              submitCrop(previewCanvasRef.current, completedCrop);
            }}
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(CreateShop));
