import React from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Route } from "react-router-dom"
import { RedeemHistoryLoadingDialog } from './components/Loading'
import RedeemHistoryCard from "./RedeemCard"
import { RedeemHistoryUIProvider } from "./RedeemUIContext"

const RedeemHistoryPage = ({ history }) => {
  const { user } = useSelector(state => state.auth)
  const redeemHistoryUIEvents = {}

  return (
    <RedeemHistoryUIProvider redeemHistoryUIEvents={redeemHistoryUIEvents}>
      <RedeemHistoryLoadingDialog />
      <RedeemHistoryCard/>
    </RedeemHistoryUIProvider>
  )
}

export default RedeemHistoryPage
