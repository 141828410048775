import styled from 'styled-components'

export const Container = styled.div`
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    .filter-row {
        display: flex;
        justify-content: space-between;  
        margin-bottom: 16px;
    }
    .filter-row-row {
        display: flex;
        align-items: baseline;
    }
    .shop-filter {
        margin-right: 16px;
    }
    .stat-row {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 8px;
    }
    .stat-head {
        background-color: turquoise;
        color: white;
        padding: 8px 0;
    }
    .stat-card {
        flex: 1;
    }
    .stat-main {  
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;
    }
    .stat-sub {
        margin: 4px 0;
    font-weight: 100;
    opacity: 0.8;
    }
    .MuiSvgIcon-fontSizeLarge {
        color: green;
    }
    .chart-row {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
    }
    .chart-item {
        flex: 1;
        text-align: center;
    }
    .chart-title {
        background-color: turquoise;
        color: white;
        padding: 8px 0 2px 0;
        margin: 10px 0 10px 0;
    }
`
