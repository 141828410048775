import styled from 'styled-components'
import { Button as MButton } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const LineImg = styled.img`
  width: 25px;
  height: 25px;
`

export const WeChatImg = styled.img`
  width: 25px;
  height: 25px;
`

export const LineButton = styled(MButton)`
 && {
  margin-top: 10px;
  font-size: 15px;
    padding: 10px 30px;
    background-color: #00C300;
    color: white;
    text-transform: none;
 }
`

export const WeChatButton = styled(MButton)`
 && {
   margin-top: 10px;
  font-size: 15px;
    padding: 10px 30px;
    background-color: #00bc0d;
    color: white;
    text-transform: none;
 }
`

export const FacebookButton = styled(MButton)`
 && {
  font-size: 15px;
    padding: 10px 30px;
    background-color: #1877f2;
    color: white;
    text-transform: none;
 }
`

export const MobileButton = styled(MButton)`
 && {
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 30px;
    background-color: #32a881;
    color: white;
    text-transform: none;
 }
`