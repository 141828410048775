import React from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Route } from "react-router-dom"
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog"
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog"
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog"
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog"
import { CustomersUIProvider } from "./CustomersUIContext"
import { CustomersCard } from "./CustomersCard"

export default function CustomersPage({ history }) {
  const { user } = useSelector(state => state.auth)
  const customersUIEvents = {
    openEditCustomerDialog: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${id}/edit`)
    },
    openProductsPage: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${id}/products`)
    },
    openDeleteCustomersDialog: () => {
      history.push(`/shop/${user.seqId}/admin/customers/deleteCustomers`)
    },
    openFetchCustomersDialog: () => {
      history.push(`/shop/${user.seqId}/admin/customers/fetch`)
    },
    openUpdateCustomersStatusDialog: () => {
      history.push(`/shop/${user.seqId}/admin/customers/updateStatus`)
    },
    openPackagesPage: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${id}/packages`)
    },
    openTopupPage: (id) => {
      history.push(`/shop/${user.seqId}/admin/customers/${id}/topup`)
    }
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Route path="/shop/:seqId/admin/customers/:id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers`)
            }}
          />
        )}
      </Route>

      <Route path="/shop/:seqId/admin/customers/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers`)
            }}
          />
        )}
      </Route>
      <Route path="/shop/:seqId/admin/customers/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/customers`)
            }}
          />
        )}
      </Route>
      <CustomersCard />
    </CustomersUIProvider>
  )
}
