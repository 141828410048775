import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 0.1px 1px 5px lightgrey;

  .invite-admin {
    margin-right: 20px;
  }
  .full-width {
    width: 100%;
    margin: 16px 0;
  }
  .inner {
    margin: 16px;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .row-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pointer {
    cursor: pointer;
  }
  .item {
    flex: 1;
  }
`