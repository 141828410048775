// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useCallback, useState } from "react"
import { Modal } from "react-bootstrap"
import { Formik, Form, Field } from "formik"
import Alert from '@material-ui/lab/Alert'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { FormattedMessage, injectIntl } from "react-intl"
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button } from 'antd'
import * as Yup from "yup"
import {
  Input,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls"

// // Validation schema
// const ShopEditSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(2, "Minimum 2 symbols")
//     .required("name is required"),
//   description: Yup.string(),
// })

const CustomerEditForm = ({
  saveShop,
  customer,
  actionsLoading,
  onHide,
  intl,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  id,
  error,
  promotion,
  setPromotion,
  promotions,
}) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        onSubmit={(values, { resetForm }) => {
          saveShop(values)
          resetForm(customer)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group">
                  <InputLabel id="demo-simple-select-label">promotion</InputLabel>
                  <Select
                    disabled={id}
                    value={promotion}
                    onChange={e => setPromotion(e.target.value)}
                  >
                    {promotions && promotions.map(promotion => (
                      <MenuItem value={promotion._id}>{promotion.name}</MenuItem>
                    ))}
                  </Select>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="form-group">
                    <label><FormattedMessage id="SHOP.PRODUCT.FORM.START" /></label>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        "aria-readonly": true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="MM/dd/yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disablePast
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label><FormattedMessage id="SHOP.PRODUCT.FORM.END" /></label>
                  <div className="form-group">
                    <>
                      <DatePicker
                        error={false}
                        helperText={null}
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          "aria-readonly": true,
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disablePast
                      />
                    </>
                  </div>
                </MuiPickersUtilsProvider>
              </Form>
              {error && <Alert severity="error">{error}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  onHide()
                  resetForm(customer)
                }}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="BUTTON.CANCEL" />
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({
                  id: "FORM.SHOP.SUBMIT"
                })}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(CustomerEditForm)
