import imageCompression from 'browser-image-compression';

export const compressionNormalImage = async image => {
  try {
    const compressFile = await imageCompression(image, {
      maxSizeMB: 3.0,
      useWebWorker: true,
      initialQuality: 0.6
    });
    return compressFile;
  } catch (error) {
    console.error(error);
  }
};

export const compressionSmallImage = async image => {
  try {
    const compressFile = await imageCompression(image, {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 400,
      useWebWorker: true,
      initialQuality: 0.6
    });
    return compressFile;
  } catch (error) {
    console.error(error);
  }
};
