import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as S from './styles'

const AdminPlaylist = [
  { url: 'https://youtu.be/ji78__lzK0c', name: 'How to download report', time: '0:26' },
  { url: 'https://youtu.be/ky8G3EgezBw', name: 'How to Invite customer', time: '0:27' },
  { url: 'https://youtu.be/o0L9kj-R0y4', name: 'How to create Promotion', time: '0:50' },
  { url: 'https://youtu.be/ZfZx79k6WXU', name: 'How to create Permanent point QR Code', time: '0:24' },
  { url: 'https://youtu.be/pTtT8BlEO2w', name: 'How to Add point', time: '0:29' },
  { url: 'https://youtu.be/4XXbQue9uFw', name: 'How to look Point History', time: '0:22' },
  { url: 'https://youtu.be/6QqpyLmPt-0', name: 'How to look Dashboard', time: '0:23' },
  { url: 'https://youtu.be/b81FukZScmw', name: 'Contact us', time: '0:14' },
]

const CustomerPlaylist = [
  { url: 'https://youtu.be/61G9hn6R1FU', name: 'How to Redeem', time: '0:23' },
  { url: 'https://youtu.be/4XXbQue9uFw', name: 'How to look Point History', time: '0:22' },
  { url: 'https://youtu.be/b81FukZScmw', name: 'Contact us', time: '0:14' },
]

const Tutorial = ({ intl }) => {
  const { user } = useSelector(state => state.auth);
  const [isCustomer, setIsCustomer] = useState(user.type === 'customer')
  const [playlist, setPlaylist] = useState(isCustomer ? CustomerPlaylist : AdminPlaylist)
  const [player, setPlayer] = useState(playlist[0].url)
  const [loop, setLoop] = useState(false)


  const handleOnClick = (url) => {
    setPlayer(url)
  }

  const playNext = () => {
    if (loop) {
      const nextIndex = playlist.findIndex(video => video.url === player) + 1
      if (nextIndex > playlist.length - 1) setLoop(false)
      else setPlayer(playlist[nextIndex].url)
    }
  }

  return (
    <S.Container>
      <S.PlayerWrapper>
        <S.Player
          url={player}
          playing={loop}
          onEnded={() => playNext()}
          controls
        />
      </S.PlayerWrapper>
      <S.PlaylistContainer>
        <S.PlaylistRow style={{ alignItems: 'center' }}>
          <S.Header>{intl.formatMessage({ id: 'tutorial.training' })}</S.Header>
          <span class="material-icons" onClick={() => setLoop(!loop)} style={{ marginLeft: '10px', backgroundColor: loop ? '#7ab8b6' : '', padding: '5px', borderRadius: '10px', color: loop ? '#fff' : '', cursor: 'pointer', transition: 'all 0.3s ease 0s' }}>
            {intl.formatMessage({ id: 'tutorial.repeat' })}
          </span>
          <S.InfoText style={{ paddingLeft: 5 }}>{playlist.length} videos</S.InfoText>
        </S.PlaylistRow>

        {playlist.map(video =>
          <S.PlaylistRow>
            <S.RowGuard onClick={() => handleOnClick(video.url)} />
            <S.PlayerThumbnail
              url={video.url}
              light={true}
            ></S.PlayerThumbnail>
            <S.PlaylistInfo>
              <S.InfoTextBold playing={player === video.url}>{video.name}</S.InfoTextBold>
              <S.InfoText>Time: {video.time} min</S.InfoText>
            </S.PlaylistInfo>
          </S.PlaylistRow>
        )}

      </S.PlaylistContainer>
    </S.Container>
  )
}

export default injectIntl(Tutorial)