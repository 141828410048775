import React, { useMemo, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls"
import { ProductsFilter } from "./products-filter/ProductsFilter"
import ProductsTable from "./products-table/ProductsTable"
import { useProductsUIContext } from "./ProductsUIContext"
import { FormattedMessage, injectIntl } from "react-intl"

const ProductsCard = ({ intl }) => {
  const productsUIContext = useProductsUIContext()
  const [totalSize, setTotalSize] = useState(0);
  const limitPromition = 100;
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      newProductButtonClick: productsUIContext.newProductButtonClick,
      openDeleteProductsDialog: productsUIContext.openDeleteProductsDialog,
      openEditProductPage: productsUIContext.openEditProductPage,
      openUpdateProductsStatusDialog:
        productsUIContext.openUpdateProductsStatusDialog,
      openFetchProductsDialog: productsUIContext.openFetchProductsDialog,
    }
  }, [productsUIContext])

  return (
    <Card>
      <CardHeader title={intl.formatMessage({
        id: "SHOP.PROMOTION.LIST"
      })}>
        <CardHeaderToolbar>
          {totalSize < limitPromition ? <button
            type="button"
            className="btn btn-primary"
            onClick={productsUIProps.newProductButtonClick}
          >
            <FormattedMessage id="SHOP.PROMOTION.CREATE" />

          </button> : null}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProductsFilter />
        {/* {productsUIProps.ids.length > 0 && (
          <>
            <ProductsGrouping />
          </>
        )} */}
        <ProductsTable totalSize={(value) => setTotalSize(value)}/>
      </CardBody>
    </Card>
  )
}

export default injectIntl(ProductsCard)
