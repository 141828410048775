import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import styled from 'styled-components';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import FreePersonIcon from '../../../../assets/free_person.png';
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { useSelector } from 'react-redux';

const PersonIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 6px;
`;

// TODO: Icon header
export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const user = useSelector(state => state.auth.user);

  const showProfilePic = user && (user.facebookProfile || (user.lineProfile && user.lineProfile.pictureUrl))
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile')
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >

        <LanguageSelectorDropdown />
        {/*begin::Logo*/}
        <Link to="/">
          <img alt="logo" src={layoutProps.headerLogo} style={{ width: '150px' }} />
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}

        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {/* {layoutProps.headerMenuSelfDisplay && (
                <>
                  
                  <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button>
                  
                </>
            )} */}

          {/*begin::Topbar Mobile Toggle*/}
          <button
            className="btn btn-hover-text-primary p-0 ml-2"
            onClick={() => {
              const toggle = document.getElementById('kt_quick_user_toggle');
              if (toggle) {
                toggle.click();
              }
            }}
          >
            <span className="svg-icon svg-icon-xl">
              <PersonIcon src={showProfilePic || FreePersonIcon} />
              {/* <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} /> */}
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
