import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import liff from '@line/liff';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../_redux/authRedux';
import { getUserByToken, facebookLogin, lineLogin } from '../../_redux/authCrud';
import { Modal } from 'react-bootstrap';
import LineIcon from '../../../../../assets/LINE_APP.png';
// import PhoneInput from 'react-phone-number-input';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import { LineImg, LineButton, WeChatButton, WeChatImg, Container, FacebookButton, MobileButton } from './styles';

const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;

var locale = process.env.REACT_APP_COUNTRY

function Login(props) {
  const { shop, errorMessage } = useSelector(state => state.auth);
  const expiredDate = shop ? new Date(shop.expiryDate) : null;
  const today = new Date();
  const isExpired = today >= expiredDate;
  const [error, setError] = useState(null);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [show, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [_mState, set_mState] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [isValid, setValid] = useState(false);
  const [forgetPassword, setForgetPassword] = useState({ password: '', re_password: '' });
  const [email, setEmail] = useState('');
  const [isValid2, setValid2] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [timeoutMessage, setTimeoutMessage] = useState(undefined);
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inviteCode = params.get('invitecode');
  const fptoken = params.get('fptoken');
  const redeem = params.get('redeem');
  const isLiff = params?.get('liff')

  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;

  useEffect(() => {
    setTimeoutMessage(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (fptoken) {
      setShow(true);
      set_mState(3);
    }

    try {
      axios.get('https://ipapi.co/json/').then(data => {
        if (data.data.country) {
          locale = data.data.country;
        }
      });
    } catch (error) {
      console.error(error);
    }

    // if (isLiff) {
    //   getLiffProfile()
    // }
  }, []);

  const getLiffProfile = async () => {
    await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
    if (liff.isLoggedIn()) {
      const lineProfile = await liff.getProfile();
      try {
        const { data } = await lineLogin(lineProfile.userId, props.match.params.seqId, lineProfile);
        const { token, user } = data
        dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: user } })
        dispatch({ type: auth.actionTypes.Login, payload: { authToken: token } })
      } catch (e) {
        const { message } = e.response.data
        history.push({
          pathname: `/shop/${props.match.params.seqId}/registration`,
          search: inviteCode && `?invitecode=${inviteCode}`,
          profile: lineProfile,
          error: message
        });
      }
    } else {
      liff.logout()
    }
  }

  const handleLineLogin = async () => {
    // liff.logout()
    // liff.login({ redirectUri: `${process.env.REACT_APP_BASE_URL}/shop/${props.match.params.seqId}/login?liff=true` })

    // if (!liff.isLoggedIn()) {
    // } else if (liff.isLoggedIn() && isLiff) {
    //   try {
    //     const liffProfile = await liff.getProfile();
    //     const result = await lineLogin(liffProfile.userId, props.match.params.seqId);
    //     console.log(liffProfile, result)
    //   } catch (e) {
    //     liff.logout()
    //     console.error('liff logout')
    //   }
    // } else {
    //   liff.logout()
    // }

    const queryParams = [
      'response_type=code',
      `client_id=${process.env.REACT_APP_LINE_CHANNEL_ID}`,
      `redirect_uri=${process.env.REACT_APP_BACKEND_IP_ADDRESS}/line/login-callback?shop=${props.match.params.seqId}`,
      `state=${Math.random()
        .toString(36)
        .substr(2, 10)}`,
      'scope=profile'
    ];

    window.location.assign(`https://access.line.me/oauth2/v2.1/authorize?${queryParams.join('&')}`);
    if (inviteCode) {
      localStorage.setItem('_ivc', inviteCode);
    }

    // if (!window.liff.isLoggedIn()) {
    //   window.liff.login({ redirectUri: `${process.env.REACT_APP_BASE_URL}?shop=${props.match.params.seqId}&liff=true` });
    // }

    // const lineIdOwner = "20102902"
    // const lineIdCustomer = '2020-06-07T08:28:04.965Z'
    // const lineIdReport = "new shop user 3"
    // const lineId = lineIdOwner
    // try {
    //   const result = await lineLogin(lineId, props.match.params.seqId)
    //   const user = await getUserByToken(result.data.token)
    //   dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: user.data.data.user } })
    //   dispatch({ type: auth.actionTypes.Login, payload: { authToken: result.data.token } })
    //   history.push({ pathname: "/" })
    // } catch (e) {
    //   if (e.message.includes('401')) {
    //     setError(true)
    //     return
    //   }
    //   history.push({ pathname: `/${props.match.params.seqId}/registration`, search: inviteCode && `?inviteCode=${inviteCode}`, lineId, error: e.message })
    // }
  };

  const handleFacebookLogin = async () => {
    const scope = 'id,first_name,last_name,email,birthday,picture.type(large)';
    window.FB.login(
      async function (fbRes) {
        if (fbRes.authResponse) {
          const { userID, accessToken } = fbRes.authResponse;
          try {
            const result = await facebookLogin(userID, accessToken, props.match.params.seqId, inviteCode);
            if (result.data.success == false) {
              // not found user, go register flow
              window.FB.api('/me', { fields: scope }, function (meRes) {
                let picture = meRes.picture.data.url;
                let searchParams = '';
                for (var x in meRes) {
                  if (x == 'picture') {
                    searchParams = searchParams + `&${x}=${picture}`;
                  } else {
                    searchParams = searchParams + `&${x}=${meRes[x]}`;
                  }
                }
                // searchParams = inviteCode ? searchParams.slice(1) + inviteCode : searchParams.slice(1)
                searchParams = inviteCode ? searchParams + `&invitecode=${inviteCode}` : searchParams;
                history.push({
                  pathname: `/shop/${props.match.params.seqId}/registration`,
                  search: searchParams,
                  error: result.data.msg
                });
              });
            } else {
              const user = await getUserByToken(result.data.token);
              dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: user.data.data.user } });
              dispatch({ type: auth.actionTypes.Login, payload: { authToken: result.data.token } });
              history.push({ pathname: '/' });
            }
          } catch (e) {
            if (e.message.includes('401')) {
              setError('This user has been suspend');
              return;
            } else {
              history.push({
                pathname: `/shop/${props.match.params.seqId}/registration`,
                search: inviteCode && `?invitecode=${inviteCode}`,
                error: e.response.data.message
              });
            }
          }
        } else {
          history.push({
            pathname: `/shop/${props.match.params.seqId}/registration`,
            search: inviteCode && `?invitecode=${inviteCode}`,
            error: 'Facebook authentication got an error'
          });
        }
      },
      { scope: 'email,public_profile,user_birthday' }
    );
  };

  const handleClose = () => {
    if (_mState === 3) {
      clearfptoken();
    }

    setShow(false);
    setMobile('');
    setOTP('');
    setForgetPassword({ password: '', re_password: '' });
    set_mState(0);
    setLoading(false);
  };

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    let param_mobile = mobile;
    // if (mobile && isValidPhoneNumber(mobile)) {
    try {
      const { data } = await axios.post(`${backendURL}/auth/login-phone-first`, {
        phone: param_mobile,
        seqId: props.match.params.seqId
      });
      if (data.success) {
        if (data.msg === 'User not found, Please register.') {
          history.push({
            pathname: `/shop/${props.match.params.seqId}/registration`,
            search: inviteCode ? `?phone=${param_mobile}&invitecode=${inviteCode}${redeem ? `&redeem=${redeem}` : ''}` : `?phone=${param_mobile}${redeem ? `&redeem=${redeem}` : ''}`,
            error: data.message
          });
        } else if (data.token) {
          dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: data.user } });
          dispatch({ type: auth.actionTypes.Login, payload: { authToken: data.token } });
          if (redeem) {
            history.push({ pathname: `/shop/${props.match.params.seqId}/redeem/${redeem}` });
          } else {
            history.push({ pathname: '/' });
          }
        } else {
          set_mState(1);
          setEmail(data.user.email);
        }
      } else {
        setError(data.message);
        setMobile('');
      }
    } catch (error) {
      setError(error.response.data.msg || error.response.data.message);
      setMobile('');
    }
    // } else {
    //   setError('Invalid mobile phone.');
    //   setMobile('');
    // }
    setLoading(false);
  };

  useEffect(() => {
    if (!timeLeft) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const openModal = () => {
    setShow(true);
    set_mState(0);
  };

  const onSubmitOTP = async event => {
    event.preventDefault();
    // if (!otp) {
    //   setError('Plese enter number 6 digits OTP.')
    //   return;
    // }

    // const birthdate = birthdateToPassword(birthday);
    // if (!birthdate) {
    //   setError('Wrong format birthdate');
    //   setBirthday('');
    //   return;
    // }

    try {
      const result = await axios.post(`${backendURL}/auth/login-phone-second`, {
        phone: mobile,
        password: password,
        seqId: props.match.params.seqId
      });
      if (result.data.success) {
        dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: result.data.user } });
        dispatch({ type: auth.actionTypes.Login, payload: { authToken: result.data.token } });
        history.push({ pathname: '/' });
      } else if (
        result.data.msg === 'Phone or OTP are invalid' ||
        result.data.msg === 'Phone or Birthdate are invalid' ||
        result.data.msg === 'Password is invalid'
      ) {
        setError(result.data.msg);
        setOTP('');
        setBirthday('');
        setPassword('');
      } else {
        history.push({
          pathname: `/shop/${props.match.params.seqId}/registration`,
          search: inviteCode ? `?phone=${mobile}&invitecode=${inviteCode}` : `?phone=${mobile}`,
          error: result.data.msg
        });
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status !== 401) setError(e.response.data.message || e.response.data.message);
        setOTP('');
        setBirthday('');
      } else {
        console.log(e);
      }
    }
  };

  const birthdateToPassword = bd => {
    let result = null;
    if (bd.includes('-')) {
      let date = bd.split('-');
      result = date[2] + date[1] + date[0];
    } else if (bd.includes('/')) {
      result = bd.replaceAll('/', '');
    }

    if (result && result.length !== 8) {
      result = null;
    }

    return result;
  };

  const onChangeOTP = event => {
    if (event.target.value.length > 6) {
      return;
    }

    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(event.target.value)) {
      setOTP(event.target.value);
    }
  };

  const sendEmailFP = async () => {
    try {
      const { data } = await axios.post(`${backendURL}/auth/forgot-password`, {
        phone: mobile,
        seqId: props.match.params.seqId
      });
      if (data.success) {
        set_mState(4);
      } else {
        setError(data.message);
        setMobile('');
      }
    } catch (error) {
      setError(error.response.data.msg || error.response.data.message);
      setMobile('');
    }
  };

  const handleChangePassword = async event => {
    event.preventDefault();

    if (forgetPassword.re_password !== forgetPassword.password) {
      setError('Password do not match. Please enter password again.');
      setForgetPassword({ password: '', re_password: '' });
      return;
    }

    if (forgetPassword.re_password.length < 6 || forgetPassword.password.length < 6) {
      setError('Password must have at least 6 characters.');
      setForgetPassword({ password: '', re_password: '' });
      return;
    }

    try {
      const { data } = await axios.post(`${backendURL}/auth/change-password`, {
        forgotPasswordToken: fptoken,
        password: forgetPassword.password
      });
      if (data.success) {
        setSuccess('Change password success.');
        setForgetPassword({ password: '', re_password: '' });
        clearfptoken();
      } else {
        setError(data.message);
        setForgetPassword({ password: '', re_password: '' });
      }
    } catch (error) {
      setError(error.response.data.msg || error.response.data.message);
      setForgetPassword({ password: '', re_password: '' });
    }
  };

  const clearfptoken = () => {
    window.location = `${process.env.REACT_APP_BASE_URL}/shop/${props.match.params.seqId}/login`;
  };

  // const validateMobile = (text) => {
  //   var result = true
  //   setMobile(text);
  //   if (text) {
  //     if (!isValidPhoneNumber(text)) result = false
  //     if (text.charAt(3) === '0') result = false
  //   }
  //   setValid(result)
  // }

  const onChangeMobile = (event) => {
    const phoneRegex = /^[0-9]*$/
    if (phoneRegex.test(event.target.value)) {
      setMobile(event.target.value)
    }

    if (event.target.value.length >= 10) {
      setValid(true)
    } else {
      setValid(false)
    }
  }

  return (
    <>
      <div className="login-form login-signin">
        {/* begin::Head */}
        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
        <Link to={`/${props.match.params.seqId}/registration`} className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
      </div> */}

        <Container>
          {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
          {/* <FacebookButton onClick={handleFacebookLogin} size="large" variant="contained">
            Sign in with Facebook
          </FacebookButton> */}
          {process.env.REACT_APP_COUNTRY === 'TH' && <LineButton
            onClick={() => handleLineLogin()}
            size="large"
            disabled={isExpired}
            variant="contained"
            startIcon={<LineImg alt="line icon" src={LineIcon} />}
            disableElevation
          >
            {isExpired ? 'Shop has expired' : <b>Sign in with Line</b>}
          </LineButton>}
          {/* <WeChatButton
            size="large"
            variant="contained"
            startIcon={<WeChatImg alt="weChat icon" src={WeChatIcon} />}
            disableElevation
          >
            <b>Sign in with WeChat</b>
          </WeChatButton> */}
          {process.env.REACT_APP_COUNTRY !== 'TH' && <MobileButton
            size="large"
            variant="contained"
            disabled={isExpired}
            onClick={() => openModal()}
            // startIcon={<WeChatImg alt="weChat icon" src={WeChatIcon} />}
            disableElevation
          >
            {isExpired ? 'Shop has expired' : 'Sign in / Sign up with Mobile'}
          </MobileButton>}
        </Container>
      </div>
      <Snackbar open={error !== null ? true : false} autoHideDuration={4000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={success !== false} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {(_mState === 0 || _mState === 1) && 'Sign in with mobile'}
            {(_mState === 2 || _mState === 4) && 'Forget password'}
            {_mState === 3 && 'Re-enter password'}
          </Modal.Title>
        </Modal.Header>
        {_mState == 0 && (
          <form className="d-flex flex-column" style={{ padding: '1.75rem' }} onSubmit={event => onSubmit(event)}>
            <div className="form-group">
              <label>Mobile</label>
              {/* <PhoneInput
                defaultCountry={locale}
                international
                value={mobile}
                onChange={text => validateMobile(text)}
              /> */}
              <input
                value={mobile}
                type="text"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="Enter mobile"
                onChange={onChangeMobile}
              />
              {/* {mobile && mobile.length > 3 && !isValid ? <div style={{ fontSize: '0.9rem', fontWeight: '400', marginTop: '0.25rem', color: '#F64E60' }}>
                Invalid mobile
              </div> : null} */}
            </div>
            {/* <div className="form-group">
              <label>Password</label>
              <input
                value={birthday}
                type="password"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="password"
                onChange={e => setBirthday(e.target.value)}
              />
            </div> */}
            <button
              type="submit"
              disabled={isLoading || !isValid ? 'disabled' : ''}
              className="btn btn-primary"
            >
              {/* Request OTP   */}
              Login
              {isLoading ? (
                <div className="spinner-border text-white" style={{ marginLeft: '10px' }} role="status"></div>
              ) : null}
            </button>
          </form>
        )}

        {_mState == 1 && (
          <form className="d-flex flex-column" style={{ padding: '1.75rem' }} onSubmit={event => onSubmitOTP(event)}>
            <div className="form-group">
              <label>Password</label>
              <input
                value={password}
                type="password"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="password"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              disabled={!password}
              className="btn btn-primary"
            >
              {/* Request OTP   */}
              Login
              {isLoading ? (
                <div className="spinner-border text-white" style={{ marginLeft: '10px' }} role="status"></div>
              ) : null}
            </button>
            <ForgetPasswordButton onClick={() => set_mState(2)} style={{ textAlign: 'end' }}>
              Forget password?
            </ForgetPasswordButton>
          </form>
        )}

        {_mState == 2 && (
          <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
            <div className="form-group">
              <label>Email</label>
              <input
                value={email}
                type="text"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="Enter email"
              />
            </div>
            <button type="button" className="btn btn-primary" onClick={() => sendEmailFP()}>
              Send Email
              {isLoading ? (
                <div className="spinner-border text-white" style={{ marginLeft: '10px' }} role="status"></div>
              ) : null}
            </button>
          </div>
        )}

        {_mState == 4 && (
          <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
            <p style={{ fontWeight: 'bold', padding: 10 }}>Email has been sent. Please check your inbox.</p>
          </div>
        )}

        {_mState == 3 && (
          <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
            <div className="form-group">
              <label>Password</label>
              <input
                value={forgetPassword.password}
                type="password"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="Enter email"
                onChange={e => setForgetPassword({ ...forgetPassword, password: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Re-password</label>
              <input
                value={forgetPassword.re_password}
                type="password"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="Enter email"
                onChange={e => setForgetPassword({ ...forgetPassword, re_password: e.target.value })}
              />
            </div>
            <button
              type="button"
              disabled={!forgetPassword.password || !forgetPassword.re_password}
              className="btn btn-primary"
              onClick={event => handleChangePassword(event)}
            >
              <FormattedMessage id="BUTTON.SUBMIT" />
              {isLoading ? (
                <div className="spinner-border text-white" style={{ marginLeft: '10px' }} role="status"></div>
              ) : null}
            </button>
          </div>
        )}

        <div className="d-flex justify-content-end" style={{ padding: '1.75rem', borderTop: '1px solid #ecf0f3' }}>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            <FormattedMessage id="BUTTON.CANCEL" />
          </button>
        </div>
      </Modal>
      <Snackbar open={timeoutMessage} autoHideDuration={4000} onClose={() => setTimeoutMessage(undefined)}>
        <Alert onClose={() => setTimeoutMessage(undefined)} severity="error">
          {timeoutMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));

const ForgetPasswordButton = styled.label`
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    color: #32a881;
  }
`;

// mobile otp
{
  /* <div className="d-flex flex-column" style={{ padding: '1.75rem' }}> */
}
{
  /* <p className="text-center">Please enter number 6 digits OTP send to {mobile}</p>
            <div className="form-group">
              <input
                max="999999999"
                type="number"
                className="form-control"
                name="name"
                placeholder="OTP"
                value={otp}
                onChange={event => onChangeOTP(event)}
              />
            </div>
            <button type="button" disabled={timeLeft === 0 ? '' : 'disabled'} className="btn btn-secondary" style={{ marginBottom: '10px' }} onClick={e => onSubmit(e)}>
              Resend OTP (Time left: {timeLeft} seconds)
            </button> */
}
{
  /* <div className="form-group">
              <label>Birthday</label>
              <input
                value={birthday}
                type="date"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder="dd/mm/yyyy"
                onChange={e => setBirthday(e.target.value)}
              />
            </div> */
}
{
  /* <div className="form-group">
              <PhoneInput
                defaultCountry={locale}
                international
                value={password}
                onChange={text => {
                  setPassword(text);
                  if (text) {
                    setValid2(isValidPhoneNumber(text));
                  }
                }}
              />
            </div> */
}
{
  /* <button type="button" disabled={timeLeft === 0 ? 'disabled' : ''} className="btn btn-primary" onClick={e => onSubmitOTP(e)}>
              Submit
            </button> */
}

{
  /* <button type="button" disabled={!birthday} className="btn btn-primary" onClick={e => onSubmitOTP(e)}>
              Submit
            </button>
          </div> */
}
