import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { CustomersFilter } from './customers-filter/CustomersFilter';
import ShopsTable from './customers-table/CustomersTable';
import { useCustomersUIContext } from './CustomersUIContext';

export function CustomersCard({ id }) {
  const history = useHistory();
  const customersUIContext = useCustomersUIContext();
  const { actionsLoading, productForEdit, user } = useSelector(
    state => ({
      actionsLoading: state.packages.actionsLoading,
      productForEdit: state.packages.customerForEdit,
      user: state.auth.user
    }),
    shallowEqual
  );
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Products list">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={() => history.push(`/shop/${user.seqId}/admin/customers`)}
            className="btn btn-light"
            style={{marginRight: '5px'}}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          <button type="button" className="btn btn-primary" onClick={customersUIProps.newCustomerButtonClick}>
            New Product
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <ShopsTable id={id} />
      </CardBody>
    </Card>
  );
}
