import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextField, Button, Switch } from '@material-ui/core/';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import { Container } from './styles';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
const maximum_admin = 3;

const AdminManage = ({ intl }) => {
  const [inviteCode, setInviteCode] = useState('');
  const [admins, setAdmins] = useState(null);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [modal, setModal] = useState(false);
  const [username, setUsername] = useState('');
  const [currentCountAdmin, setCurrentCountAdmin] = useState(0);
  const history = useHistory();
  const isMaximumAdmin = currentCountAdmin >= maximum_admin;
  const { authToken, user } = useSelector(state => state.auth);

  const refetch = async () => {
    const resultAdmins = await axios.get(`${backendURL}/user/admins`, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    countShopAdmin(resultAdmins.data.data.admins);
    setAdmins(resultAdmins.data.data.admins);
  };

  const countShopAdmin = list => {
    const shopAdmin = 'shop-admin';
    let countAdmin = 0;
    list.forEach(admin => {
      if (admin.role === shopAdmin && admin.status === 'active') {
        countAdmin++;
      }
    });
    setCurrentCountAdmin(countAdmin);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // const resultInvite = await axios.get(`${backendURL}/auth/invite`, { headers: { "Authorization": `Bearer ${authToken}` } })
      // setInviteCode(resultInvite.data.data.invitationCode)
      await refetch();
      setLoading(false);
    };
    fetchData();
  }, []);

  const generateNewUrl = async () => {
    try {
      const result = await axios.post(`${backendURL}/auth/invite`);
      setInviteCode(result.data.invitationCode);
      setSnack('This link is valid for 10 minutes');
    } catch (err) {
      if (err.message.includes('403')) return setSnack('You do not have permission to generate invitation code');
      setSnack('Internal server error');
    }
  };

  const userStatus = admin => {
    const index = checked.findIndex(check => check.id === admin.user._id);
    if (index !== -1) {
      return checked[index].value;
    }
    return admin.status === 'active';
  };

  const onSubmitUsername = async () => {
    try {
      const admin = admins.find(admin => admin._id === modal);
      setLoading(true);
      await axios.put(
        `${backendURL}/user/name`,
        {
          name: username,
          id: admin._id
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      await refetch();
      setLoading(false);
      setModal(false);
      setSnack('Change Name Success');
    } catch {
      setSnack('Fail');
    }
  };
  if (user.type !== 'shop-owner') history.push('/');
  return (
    <>
      <Container>
        <div className="inner">
          <div className="row">
            <span className="invite-admin">
              <FormattedMessage id="SHOP.MANAGE.INVITE_ADMIN" />
            </span>
            <span className="invite-admin full-width">
              <TextField
                variant="outlined"
                value={
                  inviteCode &&
                  `${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/applyInvite?invitecode=${inviteCode}`
                }
                InputProps={{
                  endAdornment: (
                    <FileCopyIcon
                      className="pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/applyInvite?invitecode=${inviteCode}`
                        );
                        setSnack('Copied!!');
                      }}
                    />
                  )
                }}
                fullWidth
              />
            </span>
            <Button
              disabled={isMaximumAdmin}
              onClick={() => generateNewUrl()}
              variant="contained"
              size="large"
              color="primary"
            >
              {isMaximumAdmin ? (
                `maximum limit 3 active shop admins`
              ) : (
                <FormattedMessage id="SHOP.MANAGE.CREATE_NEW_INVITE" />
              )}
            </Button>
          </div>
        </div>
      </Container>
      <Container>
        <div className="inner">
          {admins &&
            admins.map(admin => (
              <div className="row-2" key={admin._id}>
                <span className="item">
                  <span
                    className="pointer"
                    onClick={() => {
                      setModal(admin._id);
                      setUsername(admin.name || admin.user.firstName);
                    }}
                  >
                    {admin.name || admin.user.firstName} &nbsp;
                    {admin.user._id === user.id && <span>(you)</span>}
                    <EditIcon fontSize="small" />
                  </span>
                </span>
                <span className="item">
                  {admin.user._id !== user.id && (
                    <Select
                      disabled={loading}
                      value={admin.role}
                      onChange={async e => {
                        setLoading(true);
                        try {
                          await axios.put(
                            `${backendURL}/user/role`,
                            {
                              id: admin._id,
                              role: e.target.value
                            },
                            { headers: { Authorization: `Bearer ${authToken}` } }
                          );
                          await refetch();
                          setSnack('Change Role Success');
                        } catch (error) {
                          if (error && error.response) {
                            setSnack(error.response.data.message);
                          } else {
                            setSnack('Fail');
                          }
                        }
                        setLoading(false);
                      }}
                    >
                      <MenuItem value={'shop-owner'}>{intl.formatMessage({ id: 'admin.manage.shop.owner' })}</MenuItem>
                      <MenuItem value={'shop-admin'}>{intl.formatMessage({ id: 'admin.manage.shop.admin' })}</MenuItem>
                      <MenuItem value={'shop-report'}>shop-report</MenuItem>
                    </Select>
                  )}
                </span>
                {admin.user._id !== user.id && (
                  <span className="item">
                    <span>Active</span>
                    <span>
                      <Switch
                        checked={userStatus(admin)}
                        disabled={loading}
                        onChange={async e => {
                          setLoading(true);
                          setChecked([
                            ...checked.filter(check => check.id !== admin.user._id),
                            { id: admin.user._id, value: !userStatus(admin) }
                          ]);
                          try {
                            await axios.put(
                              `${backendURL}/user/status`,
                              {
                                user: admin.user,
                                status: e.target.checked ? 'active' : 'suspend',
                                id: admin._id
                              },
                              { headers: { Authorization: `Bearer ${authToken}` } }
                            );
                            await refetch();
                            setSnack('Change Status Success');
                          } catch (error) {
                            if (error && error.response) {
                              setSnack(error.response.data.message);
                            } else {
                              setSnack('Fail');
                            }
                            setChecked([
                              ...checked.filter(check => check.id !== admin.user._id),
                              { id: admin.user._id, value: userStatus(admin) }
                            ]);
                          }
                          setLoading(false);
                        }}
                      />
                    </span>
                  </span>
                )}
              </div>
            ))}
        </div>
        {loading && <LinearProgress />}
      </Container>
      <Snackbar autoHideDuration={2000} message={snack} open={snack} onClose={() => setSnack(false)} />
      <Modal open={modal} onClose={() => setModal(false)}>
        <div>
          <TextField value={username} onChange={e => setUsername(e.target.value)} />
          <Button><FormattedMessage id="BUTTON.SUBMIT" /></Button>
        </div>
      </Modal>
      <Dialog open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Admin Name</DialogTitle>
        <DialogContent>
          <TextField value={username} onChange={e => setUsername(e.target.value)} autoFocus fullWidth />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setModal(false)} color="primary">
            <FormattedMessage id="BUTTON.CANCEL" />
          </Button>
          <Button disabled={loading} onClick={onSubmitUsername} color="primary">
            {intl.formatMessage({ id: 'button.confirm' })}
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </>
  );
};

export default injectIntl(AdminManage);
