import ic_dashboard from './ic_dashboard.svg'
import ic_reward from './ic_reward.svg'
import ic_shop from './ic_shop.svg'
import ic_booking from './ic_booking.svg'
import ic_invite_customer from './ic_invite_customer.svg'
import ic_admin_manage from './ic_admin_manage.svg'
import ic_report from './ic_report.svg'
import ic_tutorial from './ic_tutorial.svg'
import ic_contact_us from './ic_contact_us.svg'
import ic_deposit from './ic_deposit.svg'
import ic_history from './ic_history.svg'

export const icon = {
  ic_dashboard,
  ic_reward,
  ic_shop,
  ic_booking,
  ic_invite_customer,
  ic_admin_manage,
  ic_report,
  ic_tutorial,
  ic_contact_us,
  ic_deposit,
  ic_history
}