import React, { Suspense, lazy, useEffect, useState } from "react";
import axios from "axios";
import { Redirect, Switch, Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Promotions from "./modules/ECommerce/pages/promotions/ProductsPage";
import RedeemHistory from './modules/ECommerce/pages/redeemHistory/RedeemPage';
import PackagesPage from "./modules/ECommerce/pages/packages/ProductsPage";
import PromotionEdit from "./modules/ECommerce/pages/promotions/product-edit/ProductEdit";
import PackageEdit from "./modules/ECommerce/pages/packages/product-edit/ProductEdit";
import Home from "./pages/home";
import MainPage from "./pages/home/MainPage"
import Products from "./pages/products";
import { getUserByToken } from "./modules/Auth/_redux/authCrud";
import * as auth from "./modules/Auth/_redux/authRedux";
import CustomersManagePage from "./modules/ECommerce/pages/customersManage/CustomersPage";
import CustomerProductPage from "./modules/ECommerce/pages/customerProducts/CustomersPage";
import TopupPage from "./modules/ECommerce/pages/topup/CustomersPage";
import CustomerPackagePage from "./modules/ECommerce/pages/customerPackages/CustomersPage";
import AdminManage from "./modules/ECommerce/pages/adminManage";
import InviteFriend from "./pages/inviteFriend";
import Promotion from "./pages/promotion/Promotion";
import Product from "./pages/product";
import Report from "./pages/report/Report";
import Packages from "./pages/packages/Packages";
import Package from "./pages/package/Package";
import Dashboard from "./pages/dashboard/Dashboard";
import AddPoint from "./pages/addPoint/addPoint";
import AddPointByCustomer from "./pages/addPoint/addPointByCustomer";
import QrCodePage from "./modules/ECommerce/pages/qrCode/CustomersPage";
import Redeem from "./pages/redeem";
import Contact from "./pages/contact/Contact";
import Tutorial from "./pages/tutorial";
import { getTokenBySeqId } from "./utils/localstorage";
import SalesManage from "./modules/ECommerce/pages/salespersonManage";
import ConversionRate from "./pages/addPoint/conversionRate";

const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage(props) {
  const [loading, setLoading] = useState(true);

  const { authToken, user, shop } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const search = decodeURIComponent(location.search);
  const params = new URLSearchParams(search);

  const shopId = params.get('shop');
  const isLiff = params.get('liff');
  const inviteCode = params.get('invitecode');

  const matchAdmin = useRouteMatch('/admin');
  const matchShop = useRouteMatch('/shop/:id');

  const routeParamShopId = matchShop && matchShop.params.id;

  const resetAuth = redirectUrl => {
    window.localStorage.removeItem(`persist:demo1-auth`);
    dispatch({ type: auth.actionTypes.Logout, payload: { trigger: true } });
    if (redirectUrl) history.push({
      pathname: redirectUrl,
      search: inviteCode && `invitecode=${inviteCode}`
    });
  }

  const getShopInfo = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/shop/${routeParamShopId || shopId}`)
    dispatch({ type: auth.actionTypes.GetShopInfo, payload: data })
  }

  useEffect(() => {
    const validateToken = async () => {
      try {
        const token = getTokenBySeqId(routeParamShopId) || authToken
        const result = await getUserByToken(token);
        const { seqId, type } = result.data.data.user;

        if (matchAdmin && type !== 'admin') return resetAuth('/admin/login');
        if (routeParamShopId && +routeParamShopId !== seqId) return resetAuth(`/shop/${routeParamShopId}/login`);
        if (isLiff && shopId && +shopId !== seqId) return resetAuth(`/shop/${shopId}/login`);

        dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: result.data.data.user } });
        setLoading(false);
      } catch (err) {
        resetAuth();
      }
    };
    if (!shop && (matchShop || shopId)) {
      getShopInfo();
    }

    validateToken();
  }, [authToken, dispatch]);

  if (loading) {
    return <LayoutSplashScreen />;
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/admin" component={ECommercePage} />
        {user.type === 'admin' && <Redirect to="/admin/shops" />}
        <Redirect exact from="/" to={`/shop/${user.seqId}/home`} />
        <ContentRoute path="/shop/:id/contact" component={Contact} />
        <ContentRoute path="/shop/:id/home" component={MainPage} />
        {/* reward page is old home page */}
        <ContentRoute path="/shop/:id/reward" component={Home} />
        <ContentRoute path="/shop/:id/products" component={Products} />
        <ContentRoute path="/shop/:id/packages" component={Packages} />
        <ContentRoute path="/shop/:id/redeem/:code" component={Redeem} />
        <ContentRoute path="/shop/:seqId/package/:id" component={Package} />
        <ContentRoute path="/shop/:seqId/promotion/:id" component={Promotion} />
        <ContentRoute path="/shop/:seqId/product/:id" component={Product} />
        <ContentRoute path="/shop/:id/invite" component={InviteFriend} />
        <ContentRoute path="/shop/:id/admin/home" component={MainPage} />
        <ContentRoute path="/shop/:id/admin/dashboard" component={Dashboard} />
        <ContentRoute path="/shop/:id/admin/promotions/new" component={PromotionEdit} />
        <ContentRoute
          path="/shop/:id/admin/promotions/:promotionId/edit"
          component={PromotionEdit}
        />
        <ContentRoute path="/shop/:id/admin/promotions" component={Promotions} />
        <ContentRoute path="/shop/:id/admin/packages/new" component={PackageEdit} />
        <ContentRoute
          path="/shop/:id/admin/packages/:packageId/edit"
          component={PackageEdit}
        />
        <ContentRoute path="/shop/:id/admin/packages" component={PackagesPage} />
        <ContentRoute path="/shop/:seqId/admin/customers/:id/products" component={CustomerProductPage} />
        <ContentRoute path="/shop/:seqId/admin/customers/:id/topup" component={TopupPage} />
        <ContentRoute path="/shop/:seqId/admin/customers/:id/packages" component={CustomerPackagePage} />
        <ContentRoute path="/shop/:id/admin/customers" component={CustomersManagePage} />
        <ContentRoute path="/shop/:id/admin/qrcode" component={QrCodePage} />
        <ContentRoute path="/shop/:id/admin/adminManage" component={AdminManage} />
        <ContentRoute path="/shop/:id/admin/salesperson" component={SalesManage} />
        <ContentRoute path="/shop/:id/admin/report" component={Report} />
        <ContentRoute path="/shop/:id/admin/addPoint" component={AddPoint} />
        <ContentRoute path="/shop/:id/admin/conversion-rate" component={ConversionRate} />
        <ContentRoute path="/shop/:id/admin/customer-addpoint" component={AddPointByCustomer} />
        <ContentRoute path="/shop/:id/redeem-history" component={RedeemHistory} />
        <ContentRoute path="/shop/:id/tutorial" component={Tutorial} />
        <Redirect from="/shop" to="/" />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
