/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { v4 as uid } from 'uuid';
import moment from 'moment';
import * as actions from '../../../_redux/products/productsActions';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../_metronic/_partials/controls';
import ProductEditForm from './ProductEditForm';
import { useSubheader } from '../../../../../../_metronic/layout';
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls';
import imageCompression from 'browser-image-compression';
import CountryConfig from '../../../../../../CountryConfig';

const CURRENCY = CountryConfig[process.env.REACT_APP_COUNTRY].currency

const initProduct = {
  id: undefined,
  name: '',
  limit: '',
  userLimit: '',
  point: 0,
  promotionPrice: 0,
  originalPrice: 0
};

const PromotionEdit = ({
  history,
  match: {
    params: { promotionId }
  }
}) => {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState('basic');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [file, setFile] = useState(null);
  const [shareImage, setShareImage] = useState(null);
  const [active, setActive] = useState(true);
  const [error, setError] = useState(false);
  const [uuid, setUuid] = useState(uid());
  const [type, setType] = useState('point');
  const [defaultImg, setDefaultImg] = useState(null);
  const [imageList, setImageList] = useState({ image_1: null, image_2: null, image_3: null });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, productForEdit, user } = useSelector(
    state => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
      user: state.auth.user
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProduct(promotionId));
    setUuid(uid());
  }, [promotionId, dispatch]);

  useEffect(() => {
    let _title = promotionId ? '' : 'New Promotion';
    if (productForEdit && promotionId) {
      _title = `Edit Promotion`;
      setActive(productForEdit.status === 'active');
      setDescription(productForEdit.description);
      setStartDate(productForEdit.start);
      setEndDate(productForEdit.end);
      setImageList({
        image_1: productForEdit.image[0]
          ? `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${productForEdit.image[0]}`
          : null,
        image_2: productForEdit.image[1]
          ? `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${productForEdit.image[1]}`
          : null,
        image_3: productForEdit.image[2]
          ? `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${productForEdit.image[2]}`
          : null
      });
      setDefaultImg(productForEdit.image);
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit, promotionId]);

  const saveProduct = async values => {
    setLoading(true);
    if (!startDate || !endDate) {
      setError('Please enter start date, end date');
      setLoading(false);
      return;
    } else if (moment(startDate).isAfter(moment(endDate))) {
      setError('End date must after start date');
      setLoading(false);
      return;
    } else if (!imageList.image_1) {
      setError('Please upload first promotion image');
      setLoading(false);
      return;
    }

    const data = new FormData();
    data.append('seqId', user.seqId);
    data.append('name', values.name);
    data.append('description', description);
    if (promotionId) {
      data.append('point', values.point ? values.point : 0);
      data.append('credit', 0);
    } else {
      data.append('point', type === 'point' ? values.point : 0);
      data.append('credit', 0);
    }
    data.append('promotionPrice', values.promotionPrice);
    data.append('originalPrice', values.originalPrice);
    data.append('limit', Number(values.limit) || '');
    data.append('userLimit', Number(values.userLimit) || '');
    data.append('start', startDate);
    data.append('end', endDate);
    data.append('currency', CURRENCY);
    data.append('order', values.order);

    if (imageList) {
      const { newImageList, deleteImageList } = convertImageBlobToList(imageList);
      if (newImageList.length > 0) {
        newImageList.forEach(blob => {
          data.append('image', blob, blob.name);
        });
      }

      if (imageList.image_1 !== null && typeof imageList.image_1 !== 'string') {
        const newShareImage = await handleCompressionImage(imageList.image_1);
        data.append('resizeImage', newShareImage, newShareImage.name);
      }

      if (deleteImageList.length > 0) {
        deleteImageList.forEach((num, index) => {
          data.append(`removeIndex[${index}]`, num);
          // data.append('removeIndex', deleteImageList)
        });
      }
    }
    data.append('active', active ? 'active' : 'inactive');
    if (!promotionId) {
      dispatch(actions.createProduct(data)).then(() => backToProductsList());
    } else {
      data.append('id', promotionId);
      dispatch(actions.updateProduct(data)).then(() => backToProductsList());
    }
    setError(false);
  };

  const backToProductsList = () => {
    history.push(`/shop/${user.seqId}/admin/promotions/`);
  };

  const handleCompressionImage = image => {
    const shareOptions = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 400,
      useWebWorker: true,
      initialQuality: 0.6
    };

    return imageCompression(image, shareOptions)
      .then(function (compressedFile) {
        return Object.assign(compressedFile, {
          name: image.name,
          preview: URL.createObjectURL(compressedFile)
        });
      })
      .catch(function (error) {
        console.error(error.message);
      });
  };

  const convertImageBlobToList = imageObject => {
    const newImageList = [];
    const deleteImageList = [];
    for (const key in imageObject) {
      const blob = imageObject[key];
      if (blob === null) {
        // delete case send index
        const index = key.split('_').pop();
        if (defaultImg !== null && defaultImg[index - 1]) {
          deleteImageList.push(index);
        }
      } else if (typeof blob === 'string') {
        // no update case skip to push this blob
      } else {
        // update case
        newImageList.push(blob);
      }
    }
    return { newImageList, deleteImageList };
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button type="button" onClick={backToProductsList} className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              Basic info
            </a>
          </li>
          {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("remarks")}>
                <a
                  className={`nav-link ${tab === "remarks" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "remarks").toString()}
                >
                  Product remarks
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("specs")}>
                <a
                  className={`nav-link ${tab === "specs" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "specs").toString()}
                >
                  Product specifications
                </a>
              </li>
            </>
          )}
        </ul> */}

        <div className="mt-5">
          {tab === 'basic' && (
            <ProductEditForm
              id={promotionId}
              description={description}
              loading={loading}
              setLoading={setLoading}
              setDescription={setDescription}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              file={file}
              setFile={setFile}
              shareImage={shareImage}
              setShareImage={setShareImage}
              imageList={imageList}
              setImageList={setImageList}
              active={active}
              setActive={setActive}
              type={promotionId ? (productForEdit && productForEdit.point ? 'point' : 'credit') : type}
              setType={setType}
              error={error}
              setError={setError}
              actionsLoading={actionsLoading}
              product={
                { ...productForEdit, point: productForEdit && (productForEdit.point || productForEdit.credit) } ||
                initProduct
              }
              saveProduct={saveProduct}
              uuid={uuid}
            />
          )}
        </div>
        {/* <button
          type="submit"
          className="btn btn-primary ml-2"
          onClick={saveProductClick}
        >
          Save
          </button> */}
      </CardBody>
    </Card>
  );
};

export default PromotionEdit;
