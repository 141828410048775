import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;

export const LOGIN_URL = `${backendURL}/auth/login`;
export const LOGOUT = `${backendURL}/auth/logout`;
export const LINE_LOGIN_URL = `${backendURL}/auth/login-line`;
export const FACEBOOK_LOGIN_URL = `${backendURL}/auth/login-facebook`;
export const REGISTER_URL = `${backendURL}/auth/register`;
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const ME_URL = `${backendURL}/auth/me`;
export const SHOP_URL = `${backendURL}/shop`;
export const SHOP_INVITE = `${backendURL}/shop/invite`;
export const SHOP_ADMIN_URL = `${backendURL}/shop/admin`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout() {
  return axios.post(LOGOUT)
}

export function lineLogin(lineId, shopId, lineProfile) {
  return axios.post(LINE_LOGIN_URL, { lineId, shopId, lineProfile });
}

export function facebookLogin(facebookId, accessToken, shopId, inviteCode) {
  return axios.post(FACEBOOK_LOGIN_URL, { facebookId, accessToken, shopId, inviteCode });
}

export function register({
  firstName,
  lastName,
  phone,
  email,
  birthdate,
  lineId,
  linePictureUrl,
  seqId,
  lineDisplayName,
  inviteCode,
  facebookId,
  facebookProfile,
  shippingAddress,
  remark,
  password,
  re_password,
  salesName
}) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    phone,
    email,
    birthdate,
    lineId,
    linePictureUrl,
    seqId,
    lineDisplayName,
    inviteCode,
    facebookId,
    facebookProfile,
    shippingAddress,
    remark,
    password,
    re_password,
    salesName
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL, { headers: { Authorization: `Bearer ${token}` } });
}

export function createShop(formdata) {
  return axios.post(SHOP_URL, formdata, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function editShop(id, formdata) {
  return axios.patch(`${SHOP_URL}/${id}`, formdata, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function getShop() {
  return axios.get(SHOP_URL, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function getShopById(id) {
  return axios.get(`${SHOP_URL}/${id}`, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function shopinvite(id) {
  return axios.get(`${SHOP_INVITE}/${id}`, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function searchShopByName(name) {
  return axios.get(`${SHOP_URL}?name=${name}`, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function editShopByAdmin(id, formdata) {
  return axios.patch(`${SHOP_ADMIN_URL}/${id}`, formdata);
}
