// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/products/productsActions';
import * as uiHelpers from '../ProductsUIHelpers';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from '../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useProductsUIContext } from '../ProductsUIContext';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

const ProductsTable = ({ intl, totalSize }) => {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const [promotions, setPromotions] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog
    };
  }, [productsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(state => ({ currentState: state.products }), shallowEqual);
  const filter = productsUIContext.queryParams.filter;
  const { totalCount, entities, listLoading } = currentState;
  const { authToken } = useSelector(state => state.auth);
  const [s_name, set_name] = useState('');
  const [s_start, set_start] = useState('');
  const [s_end, set_end] = useState('');
  const [s_order, set_order] = useState('');
  const [show, setShow] = useState(false);
  const [imageList, setImageList] = useState(false);

  useEffect(() => {
    if (!filter.searchText && !(filter.status === 0 || filter.status === 1)) {
      setPromotions(entities);
      return;
    }
    let filterPromotion = entities;
    if (filter.searchText) {
      filterPromotion = filterPromotion.filter(promotion => promotion.name.includes(filter.searchText));
    }

    const status = ['active', 'inactive'];
    if (filter.status === 0 || filter.status === 1) {
      filterPromotion = filterPromotion.filter(promotion => promotion.status === status[filter.status]);
    }

    setPromotions(filterPromotion);
  }, [entities, filter]);

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchProducts(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);

  const fetchHistory = async id => {
    setModal(true);
    setLoading(true);
    const redeems = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/promotion/${id}/history`);
    setLoading(false);
    setModal(redeems.data.redeem);
  };

  const sort = async (field, sort) => {
    if (field == 'name') {
      set_name(sort);
      set_start('');
      set_end('');
      set_order('');
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/promotion?name=${sort}`);
      setPromotions(result.data.promotions);
    } else if (field == 'start') {
      set_start(sort);
      set_name('');
      set_end('');
      set_order('');
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/promotion?start=${sort}`);
      setPromotions(result.data.promotions);
    } else if (field == 'end') {
      set_end(sort);
      set_start('');
      set_name('');
      set_order('');
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/promotion?end=${sort}`);
      setPromotions(result.data.promotions);
    } else if (field == 'order') {
      set_end('');
      set_start('');
      set_name('');
      set_order(sort);
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/promotion?order=${sort}`);
      setPromotions(result.data.promotions);
    }
  };

  // Table columns
  const columns = [
    {
      dataField: '__id',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: productsUIProps.openEditProductPage,
        openDeleteProductDialog: productsUIProps.openDeleteProductDialog
      },
      style: {
        minWidth: '100px'
      }
    },
    {
      dataField: 'order',
      text: 'Order',
      formatter: order => (order >= 999999 ? '-' : order)
      // text: (
      //   <span className="d-flex flex-row align-items-center">
      //     Order
      //     <span className="d-flex flex-column p-0 pl-3" style={{ cursor: 'pointer' }}>
      //       <i
      //         className="flaticon2-up p-0"
      //         style={{ color: `${s_name == 'asc' ? '#6993FF' : ''}` }}
      //         onClick={() => sort('order', 'asc')}
      //       ></i>
      //       <i
      //         className="flaticon2-down p-0"
      //         style={{ color: `${s_name == 'desc' ? '#6993FF' : ''}` }}
      //         onClick={() => sort('order', 'desc')}
      //       ></i>
      //     </span>
      //   </span>
      // )
    },
    {
      dataField: 'name',
      text: (
        <span className="d-flex flex-row align-items-center">
          {intl.formatMessage({
            id: 'SHOP.PROMOTION.NAME'
          })}
          <span className="d-flex flex-column p-0 pl-3" style={{ cursor: 'pointer' }}>
            <i
              className="flaticon2-up p-0"
              style={{ color: `${s_name == 'asc' ? '#6993FF' : ''}` }}
              onClick={() => sort('name', 'asc')}
            ></i>
            <i
              className="flaticon2-down p-0"
              style={{ color: `${s_name == 'desc' ? '#6993FF' : ''}` }}
              onClick={() => sort('name', 'desc')}
            ></i>
          </span>
        </span>
      )
    },
    {
      dataField: 'limit',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.LIMIT'
      }),
      formatter: limit => limit || 'unlimit'
    },
    {
      dataField: 'userLimit',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.USER_LIMIT'
      }),
      formatter: limit => limit || 'unlimit'
    },
    {
      dataField: 'point',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.POINT'
      }),
      formatter: point => point || 'N/A'
    },
    {
      dataField: 'credit',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.CREDIT'
      }),
      formatter: credit => credit || 'N/A'
    },
    {
      dataField: 'start',
      text: (
        <span className="d-flex flex-row align-items-center">
          {intl.formatMessage({
            id: 'SHOP.PROMOTION.START'
          })}
          <span className="d-flex flex-column p-0 pl-3" style={{ cursor: 'pointer' }}>
            <i
              className="flaticon2-up p-0"
              style={{ color: `${s_start == 'asc' ? '#6993FF' : ''}` }}
              onClick={() => sort('start', 'asc')}
            ></i>
            <i
              className="flaticon2-down p-0"
              style={{ color: `${s_start == 'desc' ? '#6993FF' : ''}` }}
              onClick={() => sort('start', 'desc')}
            ></i>
          </span>
        </span>
      ),
      formatter: start => moment(start).format('DD MMM YYYY')
    },
    {
      dataField: 'end',
      text: (
        <span className="d-flex flex-row align-items-center">
          {intl.formatMessage({
            id: 'SHOP.PROMOTION.END'
          })}
          <span className="d-flex flex-column p-0 pl-3" style={{ cursor: 'pointer' }}>
            <i
              className="flaticon2-up p-0"
              style={{ color: `${s_end == 'asc' ? '#6993FF' : ''}` }}
              onClick={() => sort('end', 'asc')}
            ></i>
            <i
              className="flaticon2-down p-0"
              style={{ color: `${s_end == 'desc' ? '#6993FF' : ''}` }}
              onClick={() => sort('end', 'desc')}
            ></i>
          </span>
        </span>
      ),
      formatter: end => moment(end).format('DD MMM YYYY')
    },
    {
      dataField: 'image',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.IMAGE'
      }),
      formatter: image =>
        image && (
          <i
            className="far fa-image p-2"
            style={{ color: 'rgb(255, 155, 74)', cursor: 'pointer' }}
            onClick={() =>
              setShowPromotionImage(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/`, image)
            }
          ></i>
        )
    },
    {
      dataField: 'status',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.STATUS'
      })
    },
    {
      dataField: '_id',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.CUSTOMERS'
      }),
      formatter: id =>
        loading ? (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        ) : (
          <a href="#" onClick={() => fetchHistory(id)}>
            Click
          </a>
        )
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: promotions && promotions.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber
  };

  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps;
    const indexOfLast = page * sizePerPage;
    const indexOfFirst = indexOfLast - sizePerPage;
    const currentEntities = entities.slice(indexOfFirst, indexOfLast);
    totalSize(promotions.length);
    return currentEntities;
  };

  const setShowPromotionImage = (path, image) => {
    setShow(true);
    setImageList(image.map(item => item && `${path}${item}`));
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={promotions === null ? [] : pagination(promotions, paginationOptions)}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(productsUIProps.setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <Dialog maxWidth="sm" fullWidth open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Promotion Users</DialogTitle>

        <DialogContent>
          {loading && <div>{intl.formatMessage({ id: 'loading' })}</div>}
          <List>
            {!loading &&
              modal.length &&
              modal?.map((redeem, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${redeem.shopUser?.user?.firstName} ${redeem.shopUser?.user?.lastName}`}
                    secondary={moment(redeem.createdAt).format('DD/MM/YYYY')}
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} style={{ padding: 0 }}>
        <Modal.Header closeButton>
          <Modal.Title>Promotion images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {imageList &&
              imageList.map(
                img =>
                  img && (
                    <Carousel.Item key={img} style={{ justifyContent: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={img} style={{ width: '320px' }} />
                      </div>
                    </Carousel.Item>
                  )
              )}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default injectIntl(ProductsTable);
