import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TextField, Button, Switch } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { deleteSales, updateSales } from '../../../_redux/sales/actions';
import { FormattedMessage } from 'react-intl';

export const NAME_REGEX = /^[a-zA-Z0-9\u0E01-\u0E3A\u0E40-\u0E4E\s]*$/

const ActionsItem = (props) => {
  const { item, onSubmitSuccess } = props
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    setName(item.name)
  }, [])

  const handleClickChoice = (mode) => {
    setModal(mode)
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (modal === 'edit') {
      await updateSales(item._id, { name: name })
      onSubmitSuccess('Update sales success.')
    } else if (modal === 'delete') {
      await deleteSales(item._id)
      onSubmitSuccess('Delete sales success.')
    }
    setLoading(false)
    setModal(false)

  }

  const handleOnChange = (e) => {
    if (NAME_REGEX.test(e.target.value)) {
      setName(e.target.value)
    }
  }

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleClickChoice('edit')}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleClickChoice('delete')}>
          Delete
        </MenuItem>
      </Menu>
      <Dialog open={modal} onClose={() => setModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">{modal === 'edit' || modal === false ? "Edit sales name" : modal === 'create' ? "Create new sales" : "Confirm delete sales"}</DialogTitle>
        <DialogContent>
          {modal === 'edit' || modal === false ?
            <TextField value={name} autoFocus fullWidth onChange={handleOnChange} /> :
            <label>Are you sure to delete <BoldText>{item.name}</BoldText>?</label>}
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} onClick={() => setModal(false)}>
            <FormattedMessage id="BUTTON.CANCEL" />
          </Button>
          <Button color="primary" disabled={loading || (modal === 'edit' && !name)} onClick={handleSubmit}>
            Confirm
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </>
  )
}

export default ActionsItem

const BoldText = styled.label`
  font-weight: bold;
  color: #3d9699;
`
