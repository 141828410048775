// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react"
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next"
import Modal from '@material-ui/core/Modal'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/packages/customersActions"
import * as uiHelpers from "../ProductsUIHelpers"
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers"
import * as columnFormatters from "./column-formatters"
import { Pagination } from "../../../../../../_metronic/_partials/controls"
import { useProductsUIContext } from "../ProductsUIContext"
import { FormattedMessage, injectIntl } from "react-intl"
import axios from 'axios';

const ProductsTable = ({ intl }) => {
  // Products UI Context
  const productsUIContext = useProductsUIContext()
  const [promotions, setPromotions] = useState([])
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    }
  }, [productsUIContext])

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.packages }),
    shallowEqual
  )
  const filter = productsUIContext.queryParams.filter
  const { totalCount, entities, listLoading } = currentState
  const { authToken } = useSelector(state => state.auth)

  useEffect(() => {
    if (!filter.searchText && !(filter.status === 0 || filter.status === 1)) {
      setPromotions(entities)
      return
    }
    let filterPromotion = entities
    if (filter.searchText) {
      filterPromotion = filterPromotion.filter(promotion =>
        promotion.name.includes(filter.searchText))
    }

    const status = ['active', 'inactive']
    if (filter.status === 0 || filter.status === 1) {
      filterPromotion = filterPromotion.filter(promotion => promotion.status === status[filter.status])
    }

    setPromotions(filterPromotion)
  }, [entities, filter])

  // Products Redux state
  const dispatch = useDispatch()
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([])
    // server call by queryParams
    dispatch(actions.fetchPackages(authToken))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch])

  const fetchHistory = async (id) => {
    setModal(true)
    setLoading(true)
    console.log(id)
    const redeems = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/package/${id}/history`)
    console.log(redeems)
    setLoading(false)
    setModal(redeems.data.redeem)
  }

  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: productsUIProps.openEditProductPage,
        openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.NAME"
      }),
    },
    {
      dataField: "point",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.POINT"
      }),
      formatter: point => point || 'N/A'
    },
    {
      dataField: "credit",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.CREDIT"
      }),
      formatter: credit => credit || 'N/A'
    },
    {
      dataField: "count",
      text: intl.formatMessage({
        id: "SHOP.PACKAGE.COUNT"
      }),
      formatter: count => count || 'N/A'
    },
    {
      dataField: "start",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.START"
      }),
      formatter: start => moment(start).format('MM/DD/YY')
    },
    {
      dataField: "end",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.END"
      }),
      formatter: end => moment(end).format('MM/DD/YY')
    },
    {
      dataField: "image",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.IMAGE"
      }),
      formatter: image => image && <a target="_blank" href={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/packages/${image}`}>Click</a>
    },
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.STATUS"
      }),
    },
    {
      dataField: "_id",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.CUSTOMERS"
      }),
      formatter: (id) => loading ? <div className="overlay-layer">
        <div className="spinner spinner-lg spinner-primary" />
      </div> : <a href="#" onClick={() => fetchHistory(id)}>Click</a>
    },
  ]
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: promotions && promotions.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber,
  }

  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps
    const indexOfLast = page * sizePerPage
    const indexOfFirst = indexOfLast - sizePerPage
    const currentEntities = entities.slice(indexOfFirst, indexOfLast)
    return currentEntities
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={promotions === null ? [] : pagination(promotions, paginationOptions)}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          )
        }}
      </PaginationProvider>
      <Dialog maxWidth="sm" fullWidth open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Package Users</DialogTitle>

        <DialogContent>
          {loading && <div>{intl.formatMessage({ id: 'loading' })}</div>}
          <List>
            {!loading && modal.length && (modal?.map(redeem => (
              <ListItem>
                <ListItemText primary={`${redeem.shopUser?.user?.firstName} ${redeem.shopUser?.user?.lastName}`} secondary={moment(redeem.createdAt).format('DD/MM/YYYY')} />
              </ListItem>
            )))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(ProductsTable)
