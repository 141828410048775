import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls"
import { CustomersFilter } from "./customers-filter/CustomersFilter"
import ShopsTable from "./customers-table/CustomersTable"
import { useCustomersUIContext } from "./CustomersUIContext"
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CountryConfig from "../../../../../CountryConfig"

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
const CURRENCY = CountryConfig[process.env.REACT_APP_COUNTRY].currency

export function CustomersCard() {
  // const customersUIContext = useCustomersUIContext()
  // const [modal, setModal] = useState(false)
  // const [form, setForm] = useState({
  //   rate: '',
  //   earnPoint: '',
  //   currency: CURRENCY
  // })
  // const [success, setSuccess] = useState(null);
  // const [error, setError] = useState(null);
  // const [conversionRate, setConversionRate] = useState()
  // const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;

  // const customersUIProps = useMemo(() => {
  //   return {
  //     ids: customersUIContext.ids,
  //     newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
  //   }
  // }, [customersUIContext])

  // const getConversionRate = async () => {
  //   if (!form.currency) return
  //   try {
  //     const { data } = await axios.get(`${backendURL}/rate?currency=${form.currency}`)
  //     if (data.conversionRate && data.conversionRate.length !== 0) {
  //       setConversionRate(data.conversionRate[0])
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  // const openModalConversionRate = () => {
  //   setModal(true)
  //   getConversionRate()
  // }

  // const handleOnChange = (event) => {
  //   setForm({ ...form, [event.target.name]: event.target.value });
  // }

  // const handleOnSubmit = async (event) => {
  //   event.preventDefault()
  //   try {
  //     const { data } = await axios.post(`${backendURL}/rate`, form);

  //     if (data.success) {
  //       setSuccess("Add conversion rate success.")
  //       setForm({
  //         rate: '',
  //         earnPoint: ''
  //       })
  //       setModal(false)
  //     }
  //   } catch (e) {
  //     const { message } = e.response.data
  //     setError(message)
  //   }
  // }


  return (
    <>
      <Card>
        <CardHeader title="Customers list">
          <CardHeaderToolbar>
            {/* <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            New Shop
          </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <CustomersFilter />
          {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
          <ShopsTable />
        </CardBody>
      </Card>
      {/* <Modal show={modal} onHide={() => setModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add conversion rate</Modal.Title>
        </Modal.Header>
        <form className="d-flex flex-column align-items-center" style={{ padding: '1.75rem' }} onSubmit={(event) => handleOnSubmit(event)}>
          <div className="form-group d-flex flex-column">
            <TextField name="rate" label="Rate" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} />
          </div>
          <div className="form-group d-flex flex-column">
            <TextField name="earnPoint" label="Earn point" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} />
          </div>
          <div className="d-flex flex-column align-items-center">
            {conversionRate && <h6>Conversion rate: Every {conversionRate.rate} {form.currency}, get {conversionRate.earnPoint} points</h6>}
          </div>
          <div className="w-100 d-flex justify-content-between">
            <button type="button" className="m-5 btn btn-secondary" onClick={() => handleCloseModal()}>Cancel</button>
            <button type="submit" className="m-5 btn btn-primary" >Submit</button>
          </div>
        </form>
      </Modal>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar> */}
    </>
  )
}
