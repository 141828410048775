import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
export const GET_REDEEM_HISTORY = `${backendURL}/promotion/history`;
export const GET_POINT_HITORY = `${backendURL}/shop/point/history`;

export function getRedeemHistory(token) {
  return axios.get(`${GET_REDEEM_HISTORY}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function getPointHistory(token) {
  return axios.get(`${GET_POINT_HITORY}`, { headers: { Authorization: `Bearer ${token}` } });
}
