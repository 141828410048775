import React from "react";
import { useSelector } from 'react-redux';
import { Shopname, PointText, Point, HeaderCardContainer } from './styles'

const MainPageHeader = () => {
  const shop = useSelector(state => state.auth.shop);
  const user = useSelector(state => state.auth.user);

  return (
    <HeaderCardContainer>
      <div style={{ height: '148px', backgroundColor: 'lightcyan', backgroundImage: `url(${`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shop.cover && shop.cover.data}`})`, backgroundSize: 'cover' }} />
      <div className="d-flex flex-row w-100 position-relative" style={{ height: '105px', backgroundColor: 'white', boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.25)', borderRadius: '20px', padding: '18px' }}>
        <div className="position-absolute" style={{ height: '117px', width: '142px', border: '4px solid #fff', borderRadius: '20px', top: '-38px', backgroundImage: `url(${`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shop.logo && shop.logo.data}`})`, backgroundSize: 'contain', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', backgroundColor: '#fff', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
        <div style={{ marginLeft: '159px' }}>
          <Shopname>{shop.name}</Shopname>
          <div className="w-100" style={{ border: '0.5px solid rgba(70, 78, 95, 0.4)' }}></div>
          <Point>{user.point}</Point><PointText>&nbsp;Point</PointText>
        </div>
      </div>
    </HeaderCardContainer>
  )
}

export default MainPageHeader