const mockup_shop = 57

export const isMockup = (shopId) => mockup_shop === shopId

export const mockup_data = {
    merchant_customer: {
        new_customer: [15, 17, 11, 19, 21, 15],
        total_customer: [10, 25, 42, 53, 72, 93]
    },
    total_manager: {
        total_owner: 3,
        total_admin: 6
    },
    promotion_redeem: {
        by_amount: {
            data: [23, 12, 8, 15, 3],
            label: ['Pizza', 'Sweet cake', 'Salad', 'Steak', 'Fried rice']
        },
        by_point: {
            data: [19, 35, 15, 10, 15],
            label: ['Steak', 'Pizza', 'Sweet cake', 'Salad', 'Fried rice']
        }
    },
    customer_redeem: {
        point_by_amount: {
            data: [35, 28, 34, 12, 20],
            label: ['0883777366', '0883776645', '0922736666', '0377764152', '0932667761'],
        },
        point_by_point: {
            data: [34, 40, 23, 13, 20],
            label: ['0883777366', '0883776645', '0922736666', '0377764152', '0932667761'],
        },
        customer_by_amount: {
            data: [34, 24, 25, 48, 13],
            label: ['0883777366', '0883776645', '0922736666', '0377764152', '0932667761'],
        },
        customer_by_point: {
            data: [34, 12, 30, 20, 28],
            label: ['0883777366', '0883776645', '0922736666', '0377764152', '0932667761'],
        }
    }
}