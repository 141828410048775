import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/packages/customersActions"
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader"
import CustomerEditForm from "./CustomerEditForm"
import { useCustomersUIContext } from "../CustomersUIContext"

const CustomerEditDialog = ({
  id, show, onHide, customerId }) => {
  // Customers UI Context
  const [promotion, setPromotion] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(false)
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    }
  }, [customersUIContext])

  // Customers Redux state
  const dispatch = useDispatch()
  const { actionsLoading, customerForEdit, authToken, promotions } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
      actionsLoading: state.packages.actionsLoading,
      customerForEdit: state.packages.customerForEdit,
      promotions: state.products.entities,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.fetchPromotions(authToken))
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id))
  }, [id, dispatch, authToken])

  useEffect(() => {
    if (customerForEdit) {
      setStartDate(customerForEdit.start)
      setEndDate(customerForEdit.end)
      setPromotion(customerForEdit.promotion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerForEdit])

  // server request for saving customer
  const saveShop = (product) => {
    if (!promotion && !id) {
      setError('Please select promotion')
      return
    }
    if (!startDate || !endDate) {
      setError('Please enter start date and end date')
      return
    } else if (moment(startDate).isAfter(moment(endDate))) {
      setError('End date must after start date')
      return
    }
    if (!id) {
      dispatch(actions.createPackagePromo({ promotion, start: startDate, end: endDate, token: authToken })).then(() => onHide())
    }
    else {
      dispatch(actions.updateShop({ id, start: startDate, end: endDate, token: authToken })).then(() => onHide())
    }
    // setFiles([])
    // setRemovedFiles([])
    setStartDate(null)
    setEndDate(null)
    setPromotion(null)
    // setError(false)
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setStartDate(null)
        setEndDate(null)
        setError(false)
        onHide()
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        id={id}
        saveShop={saveShop}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        promotions={promotions}
        onHide={onHide}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        error={error}
        promotion={promotion}
        setPromotion={setPromotion}
      />
    </Modal>
  )
}

export default CustomerEditDialog
