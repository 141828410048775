import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  .searchbox {
    margin: 10px 0 16px 0;
    display: flex;
    justify-content: center;
  }
  .header-promo {
    margin: 16px 0;
  }
  .promo-head {
    padding: 16px;
  }
  .promo-name {
    font-weight: 500;
    line-height: 1.2;
    font-size: 16px;
  }
  .promo-date {
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.54);
  }
  .promotion {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 410px;
    margin-bottom: 24px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .promotions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 360px;
    background-color: white;
  }
  .label-row {
    padding: 8px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
  .promo-image {
    width: 300px;
    max-height: 350px;
  }

  @media (max-width: 768px) {
    .promotion {
      width: 500px;
    }
    .promotions {
    }
    .promo-image {
      max-height: 450px;
      width: 450px;
    }
  }

  @media (max-width: 550px) {
    .promotion {
      height: 310px !important;
      width: 360px;
    }
  }
`;
// TODO: promotions comment width 510px;

export const TextStyled = styled.div`
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 1000 !important;
  font-size: 25px !important;
  line-height: 45px !important;

  @media (max-width: 550px) {
    line-height: 30px !important;
    font-size: 20px !important;
  }
`;

export const BlackTextStyledSm = styled.div`
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 25px !important;
  font-size: 12px !important;
`;

export const BlackTextStyledMd = styled.div`
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  width: 80%;
`;

export const DateStyled = styled.div`
  -webkit-text-stroke: 1px #000;
  display: flex;
  align-items: flex-end;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 1000 !important;
  font-size: 20px !important;
  line-height: 30px !important;

  @media (max-width: 550px) {
    line-height: 19px !important;
    font-size: 15px !important;
  }
`;

export const PointStyled = styled.div`
  -webkit-text-stroke: 1px #000;
  display: flex;
  align-items: flex-end;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 1000 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;

  @media (max-width: 550px) {
    line-height: 19px !important;
    font-size: 15px !important;
    height: 30px;
  }
`;

export const DiscountPriceStyled = styled.div`
  background-color: #f21212;
  color: #fff;
  padding: 5px 10px 5px 10px;
  font-size: 20px;
  font-family: Poppins;
  font-weight: bold;
  border-radius: 0 0 8px 8px;

  @media (max-width: 550px) {
    font-size: 20px !important;
  }
`;

export const NormalPriceStyled = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  text-decoration: line-through;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 8px 8px 0 0;
`;

export const OverlayPromotion = styled.div`
  position: relative;
  width: 100%;
  background-color: #68c9c7;
  padding: 5px 20px 0px 20px;
  border-radius: 0 0 25px 25px;
  bottom: 0;
`;

export const PointCircle = styled.div`
  position: absolute;
  background-color: #000;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 40px;
  right: 20px;
  z-index: 99;

  @media (max-width: 550px) {
    width: 65px;
    height: 65px;
  }
`;

export const PointCircleNum = styled.div`
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 20px;
`;

export const PointCircleType = styled.div`
  color: #fff;
`;

export const GreenCardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 8px;
  background-color: rgb(174, 226, 225);

  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`

export const CardContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-gap: 16px;
  justify-content: center;
`

export const CardItem = styled.li`
  list-style-type: none;
  width: 100px;
`

// transition: transform .2s;

// &:hover {
//   transform: scale(1.1); 
// }

export const Shopname = styled.label`
  font-weight: 600;
  font-size: 19px;
  color: #1A1A27;
`

export const Point = styled.label`
  font-weight: 500;
  font-size: 20px;
  color: #15B7B3;
`

export const PointText = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: #1A1A27;
`

export const HeaderCardContainer = styled.div`
  margin-top: 40px;

  @media (max-width: 991px) {
    margin-top: 55px;
  }
`