import { icon } from '../assets/index'

export const UserMenu = [
  {
    name: 'menu.reward',
    path: `/reward`,
    icon: icon.ic_reward
  },
  // {
  //   name: 'Shop',
  //   path: '/shop',
  //   icon: icon.ic_shop
  // },
  // {
  //   name: 'Booking',
  //   path: '/booking-management',
  //   icon: icon.ic_booking
  // },
  {
    name: 'menu.deposit',
    path: '/products',
    icon: icon.ic_deposit
  },
  {
    name: 'menu.point.history',
    path: '/redeem-history',
    icon: icon.ic_history
  },
  // {
  //   name: 'menu.tutorial',
  //   path: '/tutorial',
  //   icon: icon.ic_tutorial
  // },
  {
    name: 'menu.contact.us',
    path: '/contact',
    icon: icon.ic_contact_us
  },
]

export const AdminMenu = [
  {
    name: 'menu.dashboard',
    path: `/admin/dashboard`,
    icon: icon.ic_dashboard
  },
  {
    name: 'menu.reward',
    path: `/reward`,
    icon: icon.ic_reward
  },
  // {
  //   name: 'Shop',
  //   path: '/shop',
  //   icon: icon.ic_shop
  // },
  // {
  //   name: 'Booking',
  //   path: '/booking-management',
  //   icon: icon.ic_booking
  // },
  {
    name: 'menu.invite.customer',
    path: '/invite',
    icon: icon.ic_invite_customer
  },
  {
    name: 'menu.admin.management',
    path: '/admin/adminManage',
    icon: icon.ic_admin_manage
  },
  {
    name: 'menu.report',
    path: '/admin/report',
    icon: icon.ic_report
  },
  // {
  //   name: 'menu.tutorial',
  //   path: '/tutorial',
  //   icon: icon.ic_tutorial
  // },
  {
    name: 'menu.contact.us',
    path: '/contact',
    icon: icon.ic_contact_us
  },
]