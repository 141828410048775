import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    .searchbox{
        margin: 16px 0;
        
    }
    .header-promo {
        margin: 16px 0;
    }
    .promo-head {
        padding: 16px;
    }
    .promo-name {
        font-weight: 500;
        line-height: 1.2;
        font-size: 16px;
    }
    .promo-date {
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.54);
    }
    .promotion {
        width: 350px;
        margin-bottom: 24px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        cursor: pointer;
    }
    .promotions {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        width: 360px;
        background-color: white;
        
    }
    .label-row {
        padding: 8px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0,0,0,0.54);
    }
    .promo-image {
        width: 350px;
        max-height: 400px;
    }
    @media only screen and (min-width: 768px) {
        .promotion {
            width: 500px;
        }
        .promotions {
            width: 510px;
        }
        .promo-image {
            max-height: 500px;
            width: 500px;
        }
    }
`
