import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Snackbar from '@material-ui/core/Snackbar'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as auth from "../../modules/Auth/_redux/authRedux"
import { Container } from "./styles"
import { useHistory } from "react-router-dom"
import { getUserByToken } from "../../modules/Auth/_redux/authCrud"
import { FormattedMessage } from 'react-intl';

const Package = props => {
  const { user, authToken } = useSelector(state => state.auth)
  const [cpackage, setPromotion] = useState(null)
  const [share, setShare] = useState(false)
  const [snack, setSnack] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS

  useEffect(() => {
    const fetchData = async () => {
      const id = props.match.params?.id
      const result = await axios.get(`${backendURL}/package/${id}`, { headers: { "Authorization": `Bearer ${authToken}` } })
      setPromotion(result.data.package)
    }
    fetchData()
  }, [props.match.params, backendURL, authToken])

  const canRedeem = () => cpackage.canRedeem && ((cpackage.point !== 0 && user.point >= cpackage.point) ||
    (cpackage.credit !== 0 && user.credit >= cpackage.credit) || (cpackage.point === 0 && cpackage.credit === 0))

  const onRedeem = async () => {
    setLoading(true)
    await axios.post(`${backendURL}/package/${cpackage._id}/redeem`, {}, { headers: { "Authorization": `Bearer ${authToken}` } })
    const resultUser = await getUserByToken(authToken)
    dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: resultUser.data.data.user } })
    setModal(false)
    history.push('/')
  }

  return (
    <>
      {cpackage && (
        <Container>
          <div className="center-content">
            <div className="align-cen">
              <div className="img-con">
                <img className="promo-image" src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/packages/${cpackage.image}`} />
              </div>
              <div className="promo-content">
                <div className="promo-name">
                  <h4>
                    <b>{cpackage.name}</b>
                  </h4>
                </div>
                <div>
                  <h5>
                    {cpackage.point || cpackage.credit} {cpackage.point ? 'point' : 'credit'}
                  </h5>
                </div>
                <div className="des" dangerouslySetInnerHTML={{ __html: cpackage.description }} />
              </div>
              <div className="btn-row">
                <Button variant="outlined" onClick={() => history.goBack()}>Back</Button>
                {canRedeem() &&
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setModal(true)}
                  >
                    Redeem
                  </Button>}
                <Button variant="outlined" onClick={(e) => setShare(e.currentTarget)}>Share</Button>
                <Menu
                  open={share}
                  anchorEl={share}
                  onClose={() => setShare(false)}
                >
                  <MenuItem onClick={() => { }}>Facebook</MenuItem>
                  <MenuItem onClick={() => { }}>Line</MenuItem>
                  <MenuItem onClick={() => {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/package/${cpackage._id}`)
                    setSnack('Copied!!')
                    setShare(false)
                  }}>Copy</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <Dialog open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Redeem</DialogTitle>
            <DialogContent>
              Do you want to redeem {cpackage.point || cpackage.credit} {cpackage.point ? 'point' : 'credit'}
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={() => setModal(false)} color="primary">
                <FormattedMessage id="BUTTON.CANCEL" />
              </Button>
              <Button disabled={loading} onClick={onRedeem} color="primary">
                Confirm
              </Button>

            </DialogActions>
            {loading && <LinearProgress />}
          </Dialog>
        </Container>
      )}
      <Snackbar
        autoHideDuration={2000}
        message={snack}
        open={snack}
        onClose={() => setSnack(false)}
      />
    </>
  )
}

export default Package
