// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as actions from '../../../_redux/qrCode/customersActions';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../CustomersUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useCustomersUIContext } from '../CustomersUIContext';
import QRCode from 'qrcode.react';
import { printToFile } from '../../../../../utils/downloadCanvas';

const ShopsTable = ({ intl, id }) => {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog
    };
  }, [customersUIContext]);
  // Getting curret state of customers list from store (Redux)
  const { currentState, authToken } = useSelector(
    state => ({ currentState: state.qrCode, authToken: state.auth.authToken }),
    shallowEqual
  );
  const { user, shop } = useSelector(state => state.auth);
  const { totalCount, entities, listLoading } = currentState;
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(null);
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchCustomers(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  // Table columns
  const columns = [
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog
      },
      style: {
        minWidth: '100px'
      }
    },
    {
      dataField: 'name',
      text: intl.formatMessage({
        id: 'SHOP.PRODUCT.FORM.NAME'
      })
    },
    {
      dataField: 'point',
      text: intl.formatMessage({
        id: 'SHOP.MANAGE.CUSTOMERS.POINT'
      })
    },
    {
      dataField: 'userLimit',
      text: intl.formatMessage({
        id: 'SHOP.PROMOTION.USER_LIMIT'
      })
    },
    {
      dataField: 'code',
      text: 'QR code',
      formatter: (code, cell) => {
        return (
          code && (
            <a
              href="#"
              onClick={() => {
                setModalDetail({ name: cell.name, point: cell.point, code: cell.code });
                setModal(true);
              }}
            >
              Click
            </a>
          )
        );
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };
  const searchText = customersUIContext.queryParams.filter.searchText;
  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps;
    const indexOfLast = page * sizePerPage;
    const indexOfFirst = indexOfLast - sizePerPage;
    const currentEntities = entities.slice(indexOfFirst, indexOfLast);
    return currentEntities;
  };
  const searchEntities = entities => entities.filter(shop => shop.name.includes(searchText));

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={
                  entities === null
                    ? []
                    : searchText
                      ? pagination(searchEntities(entities), paginationProps)
                      : pagination(entities, paginationProps)
                }
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(customersUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: customersUIProps.ids,
                //   setIds: customersUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              <Modal
                show={modal && modal.length !== 0}
                onHide={() => {
                  setModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <h3>Qr Code</h3>
                    <a onClick={() => {
                      setModal(false);
                    }} className='btn btn-xs btn-icon btn-light btn-hover-primary'>
                      <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                  </div>
                </Modal.Header>
                <Modal.Body id="redeemQrCode">
                  <div style={{ textAlign: 'center' }}>
                    <QRCode value={`${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/redeem/${modalDetail && modalDetail.code}`} />
                    <div style={{ color: '#1aa29e' }}>
                      <label className="font-weight-bold" style={{ color: '#000' }}>QR Code for redeem</label><br />
                      <label className="font-weight-bold">Shop : </label> {shop && shop.name}<br />
                      <label className="font-weight-bold">Name : </label> {modalDetail && modalDetail.name}<br />
                      <label className="font-weight-bold">Point : </label> {modalDetail && modalDetail.point}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => printToFile('redeemQrCode', `${modalDetail && modalDetail.name} Redeem Qrcode.png`)}
                    variant="primary"
                  >
                    <label style={{ margin: 0 }}>Download QR Code</label>
                  </Button>
                </Modal.Footer>
              </Modal>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default injectIntl(ShopsTable);
