/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"
import { useSelector } from "react-redux"
import menu from './menu'
import toggle from "./../../../../../_metronic/_assets/js/layout/base/aside.js";
import { getUserByToken } from "../../../../../app/modules/Auth/_redux/authCrud"

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(state => state.auth.user)
  const location = useLocation()
  const history = useHistory()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : ""
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {user && menu(user.seqId)[user.type].map((item, index) => (
          <li
            key={index}
            id="kt_aside_toggle"
            onClick={e => {
              // const mytoggle = toggle.getElement()
              // console.log(mytoggle)
              // console.log(toggle.getElement())
              toggle.getOffcanvas().hide()
            }
            }
            className={`menu-item ${getMenuItemActive(item.route)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={item.route || '/'}>
              {/* <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span> */}
              <span className="menu-text font-weight-bold"><FormattedMessage id={item.id} /></span>
            </NavLink>
          </li>
        ))}

      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
