import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  .month {
    display: flex;
    align-items: center;
  }
  .select-datetime {
    margin: 20px 0;
  }
  .btn-report {
    display: flex;
    margin: 8px 0;
  }
  .email-btn {
    margin-left: 8px;
  }
`