/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import { v4 as uid } from 'uuid'
import moment from 'moment'
import * as actions from "../../../_redux/packages/customersActions"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls"
import ProductEditForm from "./ProductEditForm"
import { useSubheader } from "../../../../../../_metronic/layout"
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls"

const initProduct = {
  id: undefined,
  name: '',
  limit: '',
  userLimit: '',
  point: 0,
}

const PackageEdit = ({
  history,
  match: {
    params: { packageId },
  },
}) => {
  // Subheader
  const suhbeader = useSubheader()
  // Tabs
  const [tab, setTab] = useState("basic")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [file, setFile] = useState(null)
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)
  const [uuid, setUuid] = useState(uid())
  const [type, setType] = useState('point')
  const dispatch = useDispatch()
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, productForEdit, user } = useSelector(
    (state) => ({
      actionsLoading: state.packages.actionsLoading,
      productForEdit: state.packages.customerForEdit,
      user: state.auth.user
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.fetchCustomer(packageId))
    setUuid(uid())
  }, [packageId, dispatch])

  useEffect(() => {
    let _title = packageId ? "" : "New Package"
    if (productForEdit && packageId) {
      _title = `Edit Package`
      setActive(productForEdit.status === 'active')
      setDescription(productForEdit.description)
      setStartDate(productForEdit.start)
      setEndDate(productForEdit.end)
    }

    setTitle(_title)
    suhbeader.setTitle(_title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit, packageId])

  const saveProduct = async (values) => {
    console.log(values)
    if (!startDate || !endDate) {
      setError('Please enter start date and end date')
      return
    } else if (moment(startDate).isAfter(moment(endDate))) {
      setError('End date must after start date')
      return
    } else if (!packageId && !file) {
      setError('Please upload package image')
      return
    }
    const data = new FormData()
    data.append('seqId', user.seqId)
    data.append('name', values.name)
    data.append('description', description)
    if (packageId) {
      data.append('point', productForEdit.point ? values.point : 0)
      data.append('credit', productForEdit.credit ? values.point : 0)
    } else {
      data.append('point', type === 'point' ? values.point : 0)
      data.append('credit', type === 'credit' ? values.point : 0)
    }
    data.append('count', Number(values.count) || '')
    data.append('start', startDate)
    data.append('end', endDate)
    if (file) {
      data.append('image', file)
    }
    data.append('active', active ? 'active' : 'inactive')
    if (!packageId) {
      dispatch(actions.createPackage(data)).then(() => backToProductsList())
    } else {
      data.append('id', packageId)
      dispatch(actions.updateShop(data)).then(() => backToProductsList())
    }
    // setError(false)
  }

  const backToProductsList = () => {
    history.push(`/shop/${user.seqId}/admin/packages/`)
  }

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {tab === "basic" && (
            <ProductEditForm
              id={packageId}
              description={description}
              setDescription={setDescription}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              file={file}
              setFile={setFile}
              active={active}
              setActive={setActive}
              type={packageId ? (productForEdit && productForEdit.point ? 'point' : 'credit') : type}
              setType={setType}
              error={error}
              setError={setError}
              actionsLoading={actionsLoading}
              product={{ ...productForEdit, point: productForEdit && (productForEdit.point || productForEdit.credit) } || initProduct}
              saveProduct={saveProduct}
              uuid={uuid}
            />
          )}
        </div>
        {/* <button
          type="submit"
          className="btn btn-primary ml-2"
          onClick={saveProductClick}
        >
          Save
          </button> */}
      </CardBody>
    </Card>
  )
}

export default PackageEdit