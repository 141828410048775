// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import moment from 'moment'
import { Modal, Button } from "react-bootstrap"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import * as actions from "../../../_redux/topup/customersActions"
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers"
import * as uiHelpers from "../CustomersUIHelpers"
import * as columnFormatters from "./column-formatters"
import { Pagination } from "../../../../../../_metronic/_partials/controls"
import { useCustomersUIContext } from "../CustomersUIContext"

const ShopsTable = ({ intl, id }) => {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    }
  }, [customersUIContext])
  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.topup }),
    shallowEqual
  )
  const { totalCount, entities, listLoading } = currentState
  const [modal, setModal] = useState(false)
  // Customers Redux state
  const dispatch = useDispatch()
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([])
    // server call by queryParams
    dispatch(actions.fetchCustomers(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch])
  // Table columns
  const columns = [
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
    //     openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
    //   },
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: start => start && moment(start).format('MM/DD/YY')
    },
    {
      dataField: "credit",
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.CREDIT",
      }),
    },
    {
      dataField: "expire",
      text: "expired",
      formatter: start => start && moment(start).format('MM/DD/YY')
    },
    {
      dataField: "image",
      text: intl.formatMessage({
        id: "SHOP.PROMOTION.IMAGE"
      }),
      formatter: image => image && <a target="_blank" href={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/topup/${image}`}>Click</a>
    },
  ]
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  }
  const searchText = customersUIContext.queryParams.filter.searchText
  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps
    const indexOfLast = page * sizePerPage
    const indexOfFirst = indexOfLast - sizePerPage
    const currentEntities = entities.slice(indexOfFirst, indexOfLast)
    return currentEntities
  }
  const searchEntities = entities => entities.filter(shop =>
    shop.name.includes(searchText))

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : searchText ? pagination(searchEntities(entities), paginationProps) : pagination(entities, paginationProps)}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: customersUIProps.ids,
                //   setIds: customersUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              <Modal
                show={modal && modal.length !== 0}
                onHide={() => {
                  setModal(false)
                }}>
                <Modal.Header closeButton>
                  <Modal.Title>Product images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {modal && modal.map(image => <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}
                    src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/customers/products/${image}`} />)}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      setModal(false)
                    }}
                    variant="primary"
                  >
                    <FormattedMessage id="SHOP.MANAGE.CUSTOMERS.CLOSE" />
                  </Button>

                </Modal.Footer>
              </Modal>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(ShopsTable)
