import styled from 'styled-components'
import ReactPlayer from 'react-player'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .2);
`

export const PlayerWrapper = styled.div`
  justify-content: center;

`

export const Player = styled(ReactPlayer)`
  width: 680px !important; 

  @media (max-width: 768px) {
    width: 600px !important;
  }

  @media (max-width: 540px) {
    width: 325px !important;
    height: 200px !important;
  }
`

export const PlayerThumbnail = styled(ReactPlayer)`
  width: 100px !important;
  min-width: 100px;
  height: 70px !important;
  margin: 0 20px 0 0;
  display: flex;
`

export const PlaylistContainer = styled.div`
  width: 100%;
  padding: 20px;
`

export const PlaylistRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const PlaylistInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const RowGuard = styled.div`
  background-color: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.3s;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`

export const InfoTextBold = styled.p`
  font-weight: bold;
  color: ${props => props.playing ? '#7ab8b6' : ''};
  margin: 0;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`

export const InfoText = styled.p`
  margin: 0;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`

export const Header = styled.h3`
  margin: 20px 0 20px 0;
  text-decoration: underline;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`