import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Container } from '../adminManage/styles';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ActionsItem from './components/ActionsItem';
import CreateSales from './components/CreateSales';
import { getSales } from '../../_redux/sales/actions';

const SalesManage = (props) => {
  const shop = useSelector(state => state.auth.shop)
  const [list, setList] = useState([])
  const { id } = props.match.params;
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [success, setSuccess] = useState(null);
  const [createModal, setCreateModal] = useState(false)

  useEffect(() => { fetchData() }, [])

  const fetchData = async () => {
    const { data } = await getSales()
    setList(data.sales)
  }

  const downloadQR = (id, salesName) => {
    const canvas = document.getElementById(`sales-qrcode-${id}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${shop.name}-${salesName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleCopyUrl = (str) => {
    copy(str)
    setSuccess('Copy url success.')
  }

  const replaceText = (text) => {
    return text.replace(/\s/g, '+')
  }

  const onSuccess = (text) => {
    setSuccess(text)
    fetchData()
  }

  return (
    <>
      <Container>
        <HeaderContainer>
          <h3 className="card-label">Sales Management</h3>
          <button className="btn btn-primary" onClick={() => setCreateModal(true)}>New salesperson</button>
        </HeaderContainer>
        <Table className="table table-head-custom table-vertical-center text-center">
          <thead>
            <tr>
              <th>Actions</th>
              <th>Order</th>
              <th>Name</th>
              <th>URL</th>
              <th>QRCode</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td><ActionsItem item={item} onSubmitSuccess={onSuccess} /></td>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <button className="btn btn-primary mr-3" onClick={() => handleCopyUrl(`${process.env.REACT_APP_BASE_URL}/shop/${id}/registration?sales=${replaceText(item.name)}`)} >
                    <i className="fa fa-link pr-0" aria-hidden="true" color='#fff'></i>
                  </button>
                </td>
                <td>
                  <QRCode size={256} includeMargin id={`sales-qrcode-${item._id}`} style={{ display: 'none' }} value={`${process.env.REACT_APP_BASE_URL}/shop/${id}/registration?sales=${replaceText(item.name)}`} />
                  <button className="btn btn-primary" onClick={() => downloadQR(item._id, item.name)}>
                    <i className="fa fa-qrcode pr-0" aria-hidden="true" color='#fff' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <CreateSales modal={createModal} handleCloseModal={() => setCreateModal(false)} onSubmitSuccess={onSuccess} />
    </>
  )
}

export default SalesManage

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Table = styled.table`
  table-layout:fixed;
`