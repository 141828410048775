import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { divide } from 'lodash-es';
import useWindowSize from './WindowSize';

const LineChart = props => {
  const { dataList, labelList, colorList, barName } = props;

  const size = useWindowSize();
  const width = size.width < 550 ? '330' : '700';
  const fontSize = size.width < 550 ? '10px' : '14px';

  const config = {
    series: [
      {
        name: barName,
        data: dataList
      }
    ],
    colors: colorList,
    options: {
      chart: {
        type: 'line',
        selection: {
          yaxis: {
            min: 5,
            max: 40
          }
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }
      }
    },
    xaxis: {
      type: 'catagory',
      categories: labelList,
      labels: {
        show: true,
        rotate: -50,
        rotateAlways: size.width < 550 ? true : false,
        style: {
          fontSize: fontSize
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return Math.floor(val)
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    dataLabels: {
      enabled: true,
      style: {
        show: true,
        fontSize: fontSize
      }
    }
  };

  return (
    <div className="d-flex justify-content-center m-3">
      <Chart options={config} series={config.series} type="line" width={width} />
    </div>
  );
};

export default LineChart;
