import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux"
import { Select, MenuItem, Button } from '@material-ui/core';
import { Bar, Pie } from 'react-chartjs-2';
import { Container } from './styles';
import { injectIntl } from 'react-intl';
import { DonutChart, BarChart, LineChart } from './chart/index';
import axios from 'axios';
import moment from 'moment';
import { mockup_data, isMockup } from './MockupData';

const Dashboard = ({ intl }) => {
  const user = useSelector(state => state.auth.user)
  const shopId = user.seqId
  const [months, setMonths] = useState([]);
  const [month, setMonth] = useState('');
  const [stat, setStat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sixMonths, setSixMonths] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
  const colors = {
    lightGreen: '#c9ffed',
    navy: '#178fff',
    pink: '#ff8cec',
    orange: '#ff9b4a',
    yellow: '#ffea00',
    green: '#2ade78',
    blue: '#60ebf7',
    red: '#ff4f5e'
  };

  useEffect(() => {
    let month_list = moment.months();
    var today = new Date();
    var d;
    var month;
    for (var i = 5; i > -1; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = month_list[d.getMonth()] + ', ' + d.getFullYear();
      months.push(month);
    }
    setMonth(months[months.length - 1]);
    onMonthChange(months[months.length - 1]);
  }, []);

  const onMonthChange = selectedMonth => {
    setMonth(selectedMonth);
    let month_idx = selectedMonth ? selectedMonth.split(', ') : months[months.length - 1].split(', ');
    getMonthForChart(month_idx);
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.post(`${backendURL}/report/stat`, {
        from: new Date(month_idx[1], moment.months().indexOf(month_idx[0]) + 1, 1)
      });
      setStat(result.data);
      setLoading(false);
    };
    fetchData();
  };

  const getMonthForChart = month_idx => {
    var list = [];
    let month_list = moment.monthsShort();
    var today = new Date(month_idx[1], moment.months().indexOf(month_idx[0]) + 1, 1);
    var d;
    var month;
    for (var i = 6; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = month_list[d.getMonth()] + ', ' + d.getFullYear();
      list.push(month);
    }
    setSixMonths(list);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">{intl.formatMessage({ id: 'loading' })}</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>
    );
  }

  const isNumber = input => {
    return typeof input === 'number';
  };

  const getListfromKey = (data, key) => {
    const list = [];
    data.forEach(item => list.push(isNumber(item[key]) ? Math.abs(item[key]) : item[key]));
    return list;
  };

  return (
    <Container>
      <div className="filter-row">
        <h3>Dashboard</h3>
        <div className="filter-row-row">
          <Select
            onChange={e => {
              onMonthChange(e.target.value);
            }}
            value={month}
          >
            {months.map((itemMonth, index) => (
              <MenuItem value={itemMonth} key={index}>
                {itemMonth}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {/* <div className="stat-row">
        <div className="stat-card">
          <div className="stat-head">Transactions</div>
          <div>
            <div className="stat-main">
              {stat.transaction}{' '}
              {stat.transaction >= stat.previousTransaction ? (
                <ArrowDropUpIcon fontSize="large" />
              ) : (
                <ArrowDropDownIcon fontSize="large" />
              )}
            </div>
            <div> {stat.visit} Visits</div>
            <div className="stat-sub">{stat.previousTransaction} Last Month</div>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-head">New Merchant's Customers</div>
          <div>
            <div className="stat-main">
              {stat.shopUsers}{' '}
              {stat.shopUsers >= stat.previousShopUsers ? (
                <ArrowDropUpIcon fontSize="large" />
              ) : (
                <ArrowDropDownIcon fontSize="large" />
              )}
            </div>
            <div>{stat.totalShopUsers} Total Customers</div>
            <div className="stat-sub">{stat.previousShopUsers} Last Month</div>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-head">Merchant's Returning Customers</div>
          <div>
            <div className="stat-main">
              {stat.shopUserTransaction}{' '}
              {stat.shopUserTransaction >= stat.previousShopUserTransaction ? (
                <ArrowDropUpIcon fontSize="large" />
              ) : (
                <ArrowDropDownIcon fontSize="large" />
              )}
            </div>
            <div>&nbsp;</div>
            <div className="stat-sub">{stat.previousShopUserTransaction} Last Month</div>
          </div>
        </div>
      </div> */}
      <div className="chart-item">
        <div className="chart-title">
          <h5>Merchant's Customers</h5>
        </div>
        {/* <Pie
          data={{
            datasets: [
              {
                data: [stat.transaction, stat.visit],
                backgroundColor: ['#ff6384', '#ffcd57']
              }
            ],
            labels: ['transaction', 'visit']
          }}
        /> */}
        <h4 className="py-3">New customers</h4>
        <LineChart
          dataList={isMockup(shopId) ? mockup_data.merchant_customer.new_customer : stat.totalNewShopUsers}
          labelList={sixMonths}
          colorList={[colors.green]}
          barName={'New customer'}
        />
        <hr />
        <h4 className="py-3">Total customers</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.merchant_customer.total_customer : stat.totalShopUsers}
          labelList={sixMonths}
          colorList={[colors.pink]}
          barName={'Total customer'}
        />
      </div>
      <div className="chart-item">
        <div className="chart-title">
          <h5>Total shop manager</h5>
        </div>
        <DonutChart
          dataList={isMockup(shopId) ? [mockup_data.total_manager.total_owner, mockup_data.total_manager.total_admin] : [stat.totalOwner, stat.totalAdmin]}
          labelList={['Owner', 'Admin']}
          colorList={[colors.red, colors.navy]}
          centerText={'Total'}
        />
      </div>
      <div className="chart-item">
        <div className="chart-title">
          <h5>Top 5 Promotion Redeem</h5>
        </div>
        <h4 className="py-3">Top 5 redeem by amount</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.promotion_redeem.by_amount.data : getListfromKey(stat.topRedeemByAmount, 'count')}
          labelList={isMockup(shopId) ? mockup_data.promotion_redeem.by_amount.label : getListfromKey(stat.topRedeemByAmount, '_id')}
          colorList={[colors.orange]}
          barName={'Redeem by amount'}
        />
        <hr />
        <h4 className="py-3">Top 5 redeem by point</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.promotion_redeem.by_point.data : getListfromKey(stat.topRedeemByPoint, 'sum')}
          labelList={isMockup(shopId) ? mockup_data.promotion_redeem.by_point.label : getListfromKey(stat.topRedeemByPoint, '_id')}
          colorList={[colors.blue]}
          barName={'Redeem by point'}
        />
      </div>
      <div className="chart-item">
        <div className="chart-title">
          <h5>Top 5 Customer Redeem</h5>
        </div>
        <h4 className="py-3">Point add by amount</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.customer_redeem.point_by_amount.data : getListfromKey(stat.pointAddByAmount, 'count')}
          labelList={isMockup(shopId) ? mockup_data.customer_redeem.point_by_amount.label : getListfromKey(stat.pointAddByAmount, 'phone')}
          colorList={[colors.red]}
          barName={'Total customer'}
        />
        <hr />
        <h4 className="py-3">Point add by point</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.customer_redeem.point_by_point.data : getListfromKey(stat.pointAddByPoint, 'sum')}
          labelList={isMockup(shopId) ? mockup_data.customer_redeem.point_by_point.label : getListfromKey(stat.pointAddByPoint, 'phone')}
          colorList={[colors.navy]}
          barName={'Total customer'}
        />
        <hr />
        <h4 className="py-3">Redeem customer by amount</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.customer_redeem.customer_by_amount.data : getListfromKey(stat.topRedeemCustomerByAmount, 'count')}
          labelList={isMockup(shopId) ? mockup_data.customer_redeem.customer_by_amount.label : getListfromKey(stat.topRedeemCustomerByAmount, 'phone')}
          colorList={[colors.pink]}
          barName={'Total customer'}
        />
        <hr />
        <h4 className="py-3">Redeem customer by point</h4>
        <BarChart
          dataList={isMockup(shopId) ? mockup_data.customer_redeem.customer_by_point.data : getListfromKey(stat.topRedeemCustomerByPoint, 'sum')}
          labelList={isMockup(shopId) ? mockup_data.customer_redeem.customer_by_point.label : getListfromKey(stat.topRedeemCustomerByPoint, 'phone')}
          colorList={[colors.orange]}
          barName={'Total customer'}
        />
      </div>

      {/* <h4>Transactions & Visits</h4> */}
      {/* <div>
        <div>
          <Bar
            data={{
              labels: stat?.monthActivity.map(day => Object.keys(day)[0]),
              datasets: [
                {
                  label: 'visit',
                  data: stat?.monthActivity.map(day => day[Object.keys(day)[0]].visit),
                  backgroundColor: stat?.monthActivity.map(day => 'rgba(255, 99, 132, 0.2)'),
                  borderColor: stat?.monthActivity.map(day => 'rgba(255,99,132,1)'),
                  borderWidth: 2
                },
                {
                  label: 'transaction',
                  data: stat?.monthActivity.map(day => day[Object.keys(day)[0]].transaction),
                  backgroundColor: stat?.monthActivity.map(day => 'rgba(255, 159, 64, 0.2)'),
                  borderColor: stat?.monthActivity.map(day => 'rgba(255, 159, 64, 1)'),
                  borderWidth: 2
                }
              ]
            }}
            options={{
              responsive: true,
              scales: {
                yAxes: [
                  {
                    stacked: false,
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true
                    }
                  }
                ],
                xAxes: [
                  {
                    stacked: false,
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true
                    }
                  }
                ]
              }
            }}
          />
        </div>
        <div className="chart-row"></div>
        <div className="chart-row">
          <div className="chart-item">
            <div className="chart-title">
              <h5>Merchant's Customer Age Breakdown</h5>
            </div>
            <Pie
              data={{
                datasets: [
                  {
                    data: [
                      stat?.age['18'],
                      stat?.age['25'],
                      stat?.age['35'],
                      stat?.age['55'],
                      stat?.age['65'],
                      stat?.age['old']
                    ],
                    backgroundColor: ['#ff6384', '#ffcd57', '#36a2eb', '#4bc0c0', '#dcedfb', '#954321', '#feddc8']
                  }
                ],
                labels: ['< 18', '18-25', '26-35', '36-55', '56-65', '> 66']
              }}
            />
          </div>
        </div>
        <div className="chart-row">
          <div className="chart-item">
            <div className="chart-title">
              <h5>Campaign Transactions</h5>
            </div>
            <Pie
              data={{
                datasets: [
                  {
                    data: [
                      ...stat.promotionsCount.map(promotion => promotion.count),
                      ...stat.packagesCount.map(pack => pack.count)
                    ],
                    backgroundColor: ['#ffcd57', '#36a2eb', '#4bc0c0', '#dcedfb']
                  }
                ],
                labels: [
                  ...stat.promotionsCount.map(promotion => promotion.name),
                  ...stat.packagesCount.map(pack => pack.name)
                ]
              }}
            />
          </div>
        </div>
      </div> */}
    </Container>
  );
};

export default injectIntl(Dashboard);
