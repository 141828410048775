import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../modules/ECommerce/_redux/customerProduct/customersActions"
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment'
import { Container } from './styles'
import { useHistory } from "react-router-dom"

const Products = props => {
    const [search, setSearch] = useState(null)
    const { authToken, user } = useSelector(state => state.auth)
    const { entities: customerProducts } = useSelector(state => state.customerProducts)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {

        dispatch(actions.fetchCustomersProducts(authToken))

    }, [dispatch, authToken])

    const formatPromotions = promotions => promotions.filter(promotion => moment().isBetween(moment(promotion.start).subtract(1, 'days'), moment(promotion.end), 'days') && (!search || promotion.name.toLowerCase().includes(search.toLowerCase())))

    return (
        <Container>
            <div>
                {/* <h2 className="header-promo">Promotions</h2> */}
                <div className="searchbox">
                    <TextField
                        fullWidth
                        label="Search"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="promotions">
                    {customerProducts && formatPromotions(customerProducts).map(promotion => (
                        <div onClick={() => history.push(`/shop/${user.seqId}/product/${promotion._id}`, promotion)} className="promotion">
                            <div className="promo-head">
                                <div className="promo-name">{promotion.name}</div>
                                <div className="promo-date">{moment(promotion.start).format('DD MMMM YYYY')} - {moment(promotion.end).format('DD MMMM YYYY')} </div>
                            </div>
                            {promotion.images.length !== 0 && (
                                <img src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/customers/products/${promotion.images[0]}`} className="promo-image" />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}

export default Products