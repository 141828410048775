import React, { useEffect, useState } from 'react';
import { Container, ErrorLabel } from './styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CountryConfig from '../../../CountryConfig';
import { injectIntl } from 'react-intl';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
const CURRENCY = CountryConfig[process.env.REACT_APP_COUNTRY].currency

const AddPoint = ({ intl }) => {
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [form, setForm] = useState({
    mobile: '',
    amount: '',
    currency: CURRENCY
  })
  const [conversionRate, setConversionRate] = useState()
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [mobileList, setMobileList] = useState([])
  const [defaultMobileList, setDefaultMobileList] = useState([])
  const autoCompleteError = form.mobile && mobileList.length === 0 && mobileList.find(item => item === form.mobile) === undefined

  useEffect(() => {
    getConversionRate()
  }, [])

  const getConversionRate = async () => {
    if (!form.currency) return
    try {
      const { data } = await axios.get(`${backendURL}/rate?currency=${form.currency}`)
      if (data.conversionRate && data.conversionRate.length !== 0) {
        setConversionRate(data.conversionRate[0])
      } else {
        setError(`Please create ${CURRENCY} conversion rate.`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getListMobile = async () => {
    if (!form.mobile) return
    try {
      const { data } = await axios.get(`${backendURL}/user?phone=${form.mobile}`)
      const list = data.user.map(item => item.phone)
      setDefaultMobileList(data.user)
      setMobileList(list)
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnChange = (event) => {
    if (event.target.name == 'amount' && event.target.value < 0) return
    setForm({ ...form, [event.target.name]: event.target.value });
  }

  const handleOnChangeAutocomplete = async (event, value, name) => {
    setForm({ ...form, [name]: value });
    if (name === "mobile") {
      await getListMobile()
    }
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    if (autoCompleteError) return

    try {
      const { _id } = defaultMobileList.find(item => item.phone === form.mobile)
      const { data } = await axios.post(`${backendURL}/customer/pointRate`, {
        userId: _id,
        point: Math.floor(form.amount / conversionRate.rate) * conversionRate.earnPoint
      });

      if (data.success) {
        setSuccess("Add point success.")
        setForm({
          mobile: '',
          amount: '',
          currency: CURRENCY
        })
      }
    } catch (e) {
      const { message } = e.response.data
      setError(message)
    }
  }

  return (
    <Container>
      <h3 className="mb-5">{intl.formatMessage({ id: 'menu.add.point' })}</h3>
      <form className="d-flex flex-column align-items-center" onSubmit={(event) => handleOnSubmit(event)}>
        <div className='form-group' style={{ marginBottom: 0 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            noOptionsText={'Please try again.'}
            value={form.mobile}
            options={mobileList}
            sx={{ width: 300 }}
            onInputChange={(event, value) => handleOnChangeAutocomplete(event, value, 'mobile')}
            renderInput={(params) => <TextField {...params} label="Contact" required />}
          />
          {(autoCompleteError) && <ErrorLabel>Please try again.</ErrorLabel>}
        </div>
        <div className="form-group d-flex flex-column" style={{ marginTop: '1.175rem' }}>
          <TextField name="amount" label="Sale amount" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} value={form.amount} />
        </div>
        <div className="d-flex flex-column align-items-center mb-5">
          {conversionRate && <h6>{intl.formatMessage({ id: 'menu.add.point' }, { currency: form.currency, amount: conversionRate.rate, point: conversionRate.earnPoint })}</h6>}
        </div>
        <div className="d-flex flex-column align-items-center">
          {(form.amount && conversionRate) &&
            <>
              <h3>You will earn</h3>
              <h2>{Math.floor(form.amount / conversionRate.rate) * conversionRate.earnPoint} points</h2>
            </>
          }
          <button type="submit" disabled={autoCompleteError} className="m-5 btn btn-primary">{intl.formatMessage({ id: 'button.submit' })}</button>
        </div>
      </form>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  )
}



export default injectIntl(AddPoint)