import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input } from '../../../../_metronic/_partials/controls';
import * as auth from '../_redux/authRedux';
import { login } from '../_redux/authCrud';

function ManagementLogin(props) {
  const management_shop_id = process.env.REACT_APP_MANAGEMENT_SHOP_ID;
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = 'form-control form-control-solid h-auto py-5 px-6 ';
    if (meta.touched && meta.error) {
      result += ' is-invalid';
    }

    if (meta.touched && !meta.error) {
      result += ' is-valid';
    }

    return result;
  };

  return (
    <div className="login-form login-signin">
      <Formik
        initialValues={{
          password: ''
        }}
        validate={values => {
          const errors = {};
          if (!values.password) {
            errors.password = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            });
          }
          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          enableLoading();
          if (values.password === management_shop_id) {
            history.push({
              pathname: '/shop/management',
              state: true
            });
          } else {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.INVALID_LOGIN'
              })
            );
          }
        }}
      >
        {({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form className="form" noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            {status && (
              <div role="alert" className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            )}

            <div className="form-group">
              <Field name="password" component={Input} placeholder="Password" label="Password">
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="password"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="Enter Password"
                    />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            {/* begin::Actions */}
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span className={`${loading ? 'pr-3' : ''}`}>Sign In</span>
                {loading && <span className="spinner-border text-light"></span>}
              </button>
            </div>
            {/* end::Actions */}
          </form>
        )}
      </Formik>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ManagementLogin));
