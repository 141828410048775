import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CountryConfig from '../../../CountryConfig';
import { injectIntl } from 'react-intl';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
const CURRENCY = CountryConfig[process.env.REACT_APP_COUNTRY].currency

const ConversionRate = ({ intl }) => {
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [id, setId] = useState(undefined)
  const [form, setForm] = useState({
    rate: '',
    earnPoint: '',
    currency: CURRENCY
  })
  const [conversionRate, setConversionRate] = useState()
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getConversionRate()
  }, [])

  const getConversionRate = async () => {
    if (!form.currency) return
    try {
      const { data } = await axios.get(`${backendURL}/rate?currency=${form.currency}`)
      if (data.conversionRate && data.conversionRate.length !== 0) {
        const item = data.conversionRate[0]
        setId(item._id)
        setForm({
          ...form,
          earnPoint: item.earnPoint,
          rate: item.rate
        })
        setConversionRate(item)
      } else {
        setError(`Please create ${CURRENCY} conversion rate.`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnChange = (event) => {
    if (event.target.value < 0) return;
    setForm({ ...form, [event.target.name]: event.target.value });
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    try {
      const { data } = id ? await axios.patch(`${backendURL}/rate/${id}`, form) : await axios.post(`${backendURL}/rate`, form)
      if (data.success) {
        setSuccess(id ? "Update conversion rate success." : "Add conversion rate success.")
        setConversionRate(data.data)
      }
    } catch (e) {
      const { message } = e.response.data
      setError(message)
    }
  }

  return (
    <Container>
      <h3 className="mb-5">Conversion Rate</h3>
      <form className="d-flex flex-column align-items-center" onSubmit={(event) => handleOnSubmit(event)}>
        <div className="form-group d-flex flex-column">
          <TextField name="rate" label="Rate" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} value={form.rate} />
        </div>
        <div className="form-group d-flex flex-column">
          <TextField name="earnPoint" label="Earn point" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} value={form.earnPoint} />
        </div>
        <div className="d-flex flex-column align-items-center">
          {conversionRate && <h6>Conversion rate: Every {conversionRate.rate} {form.currency}, get {conversionRate.earnPoint} points</h6>}
        </div>
        <div className="d-flex flex-column align-items-center">
          <button type="submit" className="m-5 btn btn-primary" >{intl.formatMessage({ id: 'button.submit' })}</button>
        </div>
      </form>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  )
}



export default injectIntl(ConversionRate)