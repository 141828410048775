import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import * as auth from "../../modules/Auth/_redux/authRedux"
import { Container } from "./styles"
import { useHistory } from "react-router-dom"
import Carousel from 'react-bootstrap/Carousel'
import { getUserByToken } from "../../modules/Auth/_redux/authCrud"

const Product = props => {
    const { user, authToken } = useSelector(state => state.auth)
    const [promotion, setPromotion] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const history = useHistory()
    const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS

    useEffect(() => {
        const fetchData = async () => {
            const id = props.match.params?.id
            const result = await axios.get(`${backendURL}/product/${id}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            setPromotion(result.data.product)
        }
        fetchData()
    }, [props.match.params, backendURL, authToken])

    return (
        <>
            {promotion && (
                <Container>
                    <div className="center-content">
                        <div className="align-cen">
                            <div>
                                <Carousel>
                                    {promotion.images.map(img => (
                                        <Carousel.Item key={img}>
                                            <img src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/customers/products/${img}`} className="promo-image" />
                                        </Carousel.Item>
                                    ))}

                                </Carousel>
                            </div>
                            <div>
                                <div className="promo-content">
                                    <div className="promo-name">
                                        <h4>
                                            <b>{promotion.name}</b>
                                        </h4>
                                    </div>
                                    <div>
                                        <h5>
                                            {moment(promotion.start).format('DD MMMM YYYY')} - {moment(promotion.end).format('DD MMMM YYYY')}
                                        </h5>
                                    </div>
                                    <div className="des">
                                        {promotion.description}
                                    </div>
                                </div>
                                <div className="btn-row">
                                    <Button variant="outlined" onClick={() => history.goBack()}>Back</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            )}
        </>
    )
}

export default Product
