// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, openProductsPage, openUpdateCustomersStatusDialog, openPackagesPage, openTopupPage }
) => {
  const More = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    return (
      <>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() => {
              openEditCustomerDialog(row._id);
            }}
          >
            Add/Deduct Point
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              openUpdateCustomersStatusDialog(row);
            }}
          >
            Generate point QR code
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              openProductsPage(row._id);
            }}
          >
            Deposit
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              openPackagesPage(row._id);
            }}
          >
            Packages
          </MenuItem>
          <MenuItem
            onClick={() => {
              openTopupPage(row._id);
            }}
          >
            Topup Money
          </MenuItem> */}
        </Menu>
        {/* <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={() => {
          openEditCustomerDialog(row._id)
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>
      <> </>

      <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-sm"
        onClick={() => openUpdateCustomersStatusDialog(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/qr-code.svg")} />
        </span>
      </a>

      <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-sm"
        onClick={() => openProductsPage(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
        </span>
      </a>

      <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-sm"
        onClick={() => openPackagesPage(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")} />
        </span>
      </a> */}
      </>
    );
  };

  return <More />;
};
