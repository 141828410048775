import React from "react"
import { useSelector } from "react-redux"
import { Route } from "react-router-dom"
import { ProductsLoadingDialog } from "./products-loading-dialog/ProductsLoadingDialog"
import { ProductDeleteDialog } from "./product-delete-dialog/ProductDeleteDialog"
import { ProductsFetchDialog } from "./products-fetch-dialog/ProductsFetchDialog"
import ProductsCard from "./ProductsCard"
import { ProductsUIProvider } from "./ProductsUIContext"

const PackagesPage = ({ history }) => {
  const { user } = useSelector(state => state.auth)
  const productsUIEvents = {
    newProductButtonClick: () => {
      history.push(`/shop/${user.seqId}/admin/packages/new`)
    },
    openEditProductPage: (id) => {
      history.push(`/shop/${user.seqId}/admin/packages/${id}/edit`)
    },
    openDeleteProductDialog: (id) => {
      history.push(`/shop/${user.seqId}/admin/packages/${id}/delete`)
    },
    openDeleteProductsDialog: () => {
      history.push(`/shop/${user.seqId}/admin/packages/deleteProducts`)
    },
    openFetchProductsDialog: () => {
      history.push(`/shop/${user.seqId}/admin/packages/fetch`)
    },
    openUpdateProductsStatusDialog: () => {
      history.push(`/shop/${user.seqId}/admin/packages/updateStatus`)
    },
  }

  return (
    <ProductsUIProvider productsUIEvents={productsUIEvents}>
      <ProductsLoadingDialog />
      <Route path="/shop/:id/admin/packages/:id/delete">
        {({ history, match }) => (
          <ProductDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/packages`)
            }}
          />
        )}
      </Route>
      <Route path="/shop/:id/admin/packages/fetch">
        {({ history, match }) => (
          <ProductsFetchDialog
            show={match != null}
            onHide={() => {
              history.push(`/shop/${user.seqId}/admin/packages`)
            }}
          />
        )}
      </Route>
      <ProductsCard />
    </ProductsUIProvider>
  )
}

export default PackagesPage;
