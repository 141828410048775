import * as requestFromServer from "./customersCrud"
import { customersManageSlice, callTypes } from "./customersSlice"

const { actions } = customersManageSlice

export const fetchCustomers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .findShops(queryParams)
    .then(response => {
      const { customers } = response.data
      console.log(customers)
      dispatch(actions.customersFetched({ totalCount: customers.length, entities: customers }))
    })
    .catch(error => {
      error.clientMessage = "Can't find customers"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }))
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .getShopById(id)
    .then(response => {
      const { customer } = response.data
      dispatch(actions.customerFetched({ customerForEdit: customer }))
    })
    .catch(error => {
      console.log(error)
      error.clientMessage = "Can't find customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.customerDeleted({ id }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const createShop = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createShop(customerForCreation)
    .then(response => {
      const { shop } = response.data.data
      dispatch(actions.customerCreated({ customer: shop }))
    })
    .catch(error => {
      error.clientMessage = "Can't create customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateShop = updateShop => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateShop(updateShop)
    .then(response => {
      const { customer } = response.data
      dispatch(actions.customerUpdated({ customer }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customer"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }))
    })
    .catch(error => {
      error.clientMessage = "Can't update customers status"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }))
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}
