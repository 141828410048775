import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../modules/ECommerce/_redux/products/productsActions';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import styled from 'styled-components';
import {
  Container,
  TextStyled,
  DateStyled,
  DiscountPriceStyled,
  NormalPriceStyled,
  OverlayPromotion,
  PointStyled,
  BlackTextStyledSm,
  BlackTextStyledMd,
  PointCircle,
  PointCircleNum,
  PointCircleType
} from './styles';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import MainPageHeader from './MainPageHeader';

const Home = props => {
  const [search, setSearch] = useState(null);
  const [tab, setTab] = useState(0);
  const { authToken, user, shop } = useSelector(state => state.auth);
  const { entities: promotions } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.fetchProducts(authToken));
  }, [dispatch, authToken]);

  const isTabShow = () =>
    promotions &&
    formatPromotions(promotions) &&
    formatPromotions(promotions).filter(
      promotion => promotion.status === 'active' && promotion.point >= 0 && !promotion.credit
    ).length > 0 &&
    formatPromotions(promotions).filter(
      promotion => promotion.status === 'active' && promotion.credit && promotion.credit > 0
    ).length > 0;

  const TabPoint = () => {
    return (
      <div className="promotions bg-transparent">
        {promotions &&
          formatPromotions(promotions) &&
          formatPromotions(promotions)
            .filter(promotion => promotion.status === 'active' && promotion.point >= 0 && !promotion.credit)
            .map(promotion => (
              <div
                className="promotion bg-white"
                style={{
                  borderRadius: '25px',
                  backgroundImage: `url(${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${promotion.image})`
                }}
              >
                <Carousel
                  indicators={
                    false
                    // promotion.image.length > 1 && (promotion.image[1] !== null || promotion.image[2] !== null)
                  }
                  prevIcon={
                    promotion.image.length > 1 && (promotion.image[1] !== null || promotion.image[2] !== null)
                      ? undefined
                      : false
                  }
                  nextIcon={
                    promotion.image.length > 1 && (promotion.image[1] !== null || promotion.image[2] !== null)
                      ? undefined
                      : false
                  }
                >
                  {promotion.image.map(img =>
                    img ? (
                      <Carousel.Item>
                        <CarsouselImgStyled
                          src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${img}`}
                        />
                      </Carousel.Item>
                    ) : null
                  )}
                </Carousel>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    position: 'absolute',
                    width: 'inherit',
                    height: 'inherit'
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'column'
                    }}
                    onClick={() => history.push(`/shop/${user.seqId}/promotion/${promotion._id}`, promotion)}
                  >
                    {/* <OverlayPromotion>
                      <TextStyled className="promo-name">{promotion.name}</TextStyled>
                      <DateStyled className="promo-name">
                        {moment(promotion.start).format('DD MMMM YYYY')} -{' '}
                        {moment(promotion.end).format('DD MMMM YYYY')}
                      </DateStyled>
                    </OverlayPromotion> */}
                    {/* <DetailContainer>
                      {promotion.originalPrice && promotion.promotionPrice ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                          <NormalPriceStyled>
                            {promotion.originalPrice} {promotion.currency}
                          </NormalPriceStyled>
                          <DiscountPriceStyled>
                            {promotion.promotionPrice} {promotion.currency}
                          </DiscountPriceStyled>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <PointStyled>
                        {promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}
                      </PointStyled>
                    </DetailContainer> */}
                    <DetailContainer>
                      {promotion.originalPrice && promotion.promotionPrice ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                          <NormalPriceStyled>
                            {promotion.originalPrice} {promotion.currency}
                          </NormalPriceStyled>
                          <DiscountPriceStyled>
                            {promotion.promotionPrice} {promotion.currency}
                          </DiscountPriceStyled>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <PointCircle>
                        <PointCircleNum>{promotion.point || promotion.credit}</PointCircleNum>
                        {/* comment credit */}
                        <PointCircleType>{'point'}</PointCircleType>
                      </PointCircle>
                    </DetailContainer>
                    <OverlayPromotion>
                      <BlackTextStyledMd className="promo-name">{promotion.name}</BlackTextStyledMd>
                      <div style={{ border: 'solid 0.5px rgba(129, 129, 129, 0.4)', margin: '5px 0' }}></div>
                      <BlackTextStyledSm className="promo-name">
                        <i className="far fa-clock" style={{ color: '#000', paddingRight: '5px' }}></i>
                        {moment(promotion.start).format('DD MMMM YYYY')} -{' '}
                        {moment(promotion.end).format('DD MMMM YYYY')}
                      </BlackTextStyledSm>
                    </OverlayPromotion>
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  };

  const TabCredit = () => {
    return (
      <div className="promotions">
        {promotions &&
          formatPromotions(promotions) &&
          formatPromotions(promotions)
            .filter(promotion => promotion.credit && promotion.credit > 0)
            .map(promotion => (
              <div
                onClick={() => history.push(`/shop/${user.seqId}/promotion/${promotion._id}`, promotion)}
                className="promotion"
              >
                <div className="promo-head">
                  <div className="promo-name">{promotion.name}</div>
                  <div className="promo-date">
                    {moment(promotion.start).format('DD MMMM YYYY')} - {moment(promotion.end).format('DD MMMM YYYY')}
                  </div>
                </div>
                <img
                  src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${promotion.image}`}
                  className="promo-image"
                />
                <div className="label-row">
                  <span>
                    {promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}
                  </span>
                </div>
              </div>
            ))}
      </div>
    );
  };

  const formatPromotions = promotions =>
    promotions.filter(
      promotion =>
        promotion.status === 'active' &&
        moment().isBetween(moment(promotion.start).subtract(1, 'days'), moment(promotion.end), 'days') &&
        (!search || promotion.name.toLowerCase().includes(search.toLowerCase()))
    );

  return (
    <div>
      <MainPageHeader />
      <Container>
        <div>
          <div className="searchbox">
            <TextField
              style={{ width: 300 }}
              label="Search"
              onChange={e => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
          {isTabShow() && (
            <Paper square>
              <Tabs variant="fullWidth" value={tab} onChange={(e, value) => setTab(value)}>
                <Tab label="point" />
                <Tab label="credit" />
              </Tabs>
            </Paper>
          )}
          {tab === 0 && <TabPoint />}
          {(tab === 1 ||
            (promotions &&
              formatPromotions(promotions).filter(
                promotion => promotion.status === 'active' && promotion.point >= 0 && !promotion.credit
              ).length === 0)) && <TabCredit />}
        </div>
      </Container>
    </div>
  );
};

export default Home;

export const CarsouselImgStyled = styled.img`
  height: 350px;
  width: 500px;
  border-radius: 25px 25px 0 0;

  @media (max-width: 550px) {
    height: 250px;
    width: 360px;
  }
`;

export const LogoImageStyled = styled.img`
  max-height: 120px;
`

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 8px 20px;
`;

export const DetailContainer2 = styled.div`
  display: flex;
  margin: 0px 20px 0px 20px;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 90px;
  left: 0px;
`;

export const TopInfo = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;
