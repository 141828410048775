import React, { useState, useRef, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Modal, Button } from 'react-bootstrap';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Input } from '../../../../_metronic/_partials/controls';
import * as auth from '../_redux/authRedux';
import { register } from '../_redux/authCrud';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TextField } from '@material-ui/core';
import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
var locale = process.env.REACT_APP_COUNTRY;

function Registration(props) {
  const { intl } = props;

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [error, setError] = useState(props.location.error);
  const [adminError, setAdminError] = useState('');
  const [invalidMobile, setInvalidMobile] = useState(false);
  const history = useHistory();
  const [mobile, setMobile] = useState('');
  const [otp, setOTP] = useState('');
  const [show, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timeoutError, setTimeoutError] = useState(false);
  const lineProfile = props.location.profile;
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inviteCode = params.get('invitecode');
  const facebookId = params.get('id');
  const first_name = params.get('first_name');
  const last_name = params.get('last_name');
  const email = params.get('email');
  const sales = params.get('sales');
  // const phone = params?.get('phone') && `+${params.get('phone') ? params.get('phone').trim() : params.get('phone')}`;
  const phone = params?.get('phone');
  const redeem = params.get('redeem');
  const facebook_profile = `${params.get('picture')}&height=${params.get('height')}&width=${params.get(
    'width'
  )}&ext=${params.get('ext')}&hash=${params.get('hash')}`;

  var birthday = null;
  if (params.get('birthday')) {
    var birthday =
      params.get('birthday').split('/')[2] +
      '-' +
      params.get('birthday').split('/')[0] +
      '-' +
      params.get('birthday').split('/')[1];
  }

  useEffect(() => {
    try {
      axios.get('https://ipapi.co/json/').then(data => {
        if (data.data.country) {
          locale = data.data.country;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = 'form-control form-control-solid h-auto py-5 px-6 ';
    if (meta.touched && meta.error) {
      result += ' is-invalid';
    }

    if (meta.touched && !meta.error) {
      result += ' is-valid';
    }

    return result;
  };

  const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

  const validateBirthDate = selectedDate =>
    selectedDate && moment(selectedDate).isAfter('1900-01-01') && moment(selectedDate).isBefore(new Date());

  const submitRegister = async (values, { setStatus, setSubmitting }) => {
    setError(false);

    if (invalidMobile) {
      setError('Please enter required field.');
      return;
    }

    if (inviteCode && values.password !== values.re_password) {
      setAdminError('Password do not match. Please re-enter password.');
      return;
    }
    if (inviteCode && (values.password.length < 6 || values.re_password.length < 6)) {
      setAdminError('Password must have at least 6 characters.');
      return;
    }

    enableLoading();

    try {
      const { data } = await register({
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        phone: values.phone,
        birthdate: values.date,
        lineId: lineProfile ? lineProfile.userId : undefined,
        facebookId: facebookId ? facebookId : undefined,
        linePictureUrl: lineProfile ? lineProfile.pictureUrl : undefined,
        lineDisplayName: lineProfile ? lineProfile.displayName : undefined,
        facebookProfile: params.get('picture') ? facebook_profile : undefined,
        seqId: props.match.params.seqId,
        shippingAddress: values.shippingAddress,
        remark: values.remark,
        password: values.password,
        re_password: values.re_password,
        salesName: values.salesName,
        inviteCode
      });

      // if (data.success) {
      //   setShow(true);
      //   setTimeLeft(60);
      //   setMobile(param_mobile ? param_mobile : values.phone);
      //   disableLoading();
      // }

      props.register(data.token);
      if (redeem) {
        history.push({ pathname: `/shop/${props.match.params.seqId}/redeem/${redeem}` });
      } else {
        history.push({ pathname: '/' });
      }
    } catch (err) {
      const errorResponseData = err.response;
      if (errorResponseData && errorResponseData.data) {
        if (errorResponseData.data.msg == 'There is a problem occur on Movider.') {
          setError('Wrong mobile number.');
        } else if (errorResponseData.data.message) {
          setTimeoutError(errorResponseData.data.message);
        }
      }

      setSubmitting(false);
      setStatus(err.response.data.message);
      disableLoading();
    }
  };

  const handleClose = () => {
    setShow(false);
    setOTP('');
  };

  const onChangeOTP = event => {
    if (event.target.value.length > 6) {
      return;
    }

    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(event.target.value)) {
      setOTP(event.target.value);
    }
  };

  useEffect(() => {
    if (!timeLeft) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onSubmit = event => {
    event.preventDefault();
    setLoading(true);
    if (mobile && isValidPhoneNumber(mobile)) {
      axios
        .post(`${backendURL}/auth/login-phone-first`, { phone: mobile, seqId: props.match.params.seqId })
        .then(response => {
          if (
            // TODO: OTP response message when success
            response.data.msg === 'The OTP is sent to your phone number, please fill the OTP received.' ||
            response.data.msg === 'User found, Please provide birthdate password'
          ) {
            setTimeLeft(60);
          } else {
            setError('Something went wrong. Please contact admin.');
          }
        })
        .catch(err => {
          setError(err.response.data.msg);
        });
    } else {
      setError('Invalid mobile phone.');
      setMobile('');
    }
    setLoading(false);
  };

  const onSubmitOTP = async event => {
    event.preventDefault();
    if (!otp) {
      setError('Plese enter number 6 digits OTP.');
      return;
    }

    try {
      const result = await axios.post(`${backendURL}/auth/login-phone-second`, {
        phone: mobile,
        password: otp,
        seqId: props.match.params.seqId
      });
      if (result.data.success) {
        dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: result.data.user } });
        dispatch({ type: auth.actionTypes.Login, payload: { authToken: result.data.token } });
        history.push({ pathname: '/' });
      } else if (
        result.data.msg === 'Phone or OTP are invalid' ||
        result.data.msg === 'Phone or Birthdate are invalid'
      ) {
        setError(result.data.msg);
        setOTP('');
      } else {
        setError('Something went wrong. Please contact admin.');
      }
    } catch (e) {
      if (e.response) {
        setError(e.response.data.message);
        setOTP('');
      } else {
        // console.log(e);
      }
    }
  };

  return (
    <div className="login-form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESCRIPTION" />
        </p>
      </div>

      <Formik
        initialValues={{
          email: email ? email : '',
          firstname: first_name ? first_name : '',
          lastname: last_name ? last_name : '',
          phone: phone ? phone : '',
          date: birthday ? birthday : '',
          shippingAddress: '',
          remark: '',
          password: '',
          re_password: '',
          salesName: sales ? sales : ''
        }}
        validate={values => {
          const errors = {};

          if (inviteCode && !values.email) {
            errors.email = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            });
          }

          if (inviteCode && !values.password) {
            errors.password = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            });
          }

          if (inviteCode && !values.re_password) {
            errors.re_password = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            });
          }

          if (inviteCode && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_FIELD'
            });
          }

          // if (!values.firstname.trim()) {
          //   errors.firstname = intl.formatMessage({
          //     id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          //   });
          // }

          // if (!values.lastname.trim()) {
          //   errors.lastname = intl.formatMessage({
          //     id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          //   });
          // }

          // if (!values.date) {
          //   errors.date = intl.formatMessage({
          //     id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          //   });
          // }

          if (!validateBirthDate(values.date)) {
          }

          if (!values.phone) {
            setInvalidMobile(true);
          } else if (values['phone'].length < 10) {
            setInvalidMobile(true);
          } else {
            setInvalidMobile(false);
          }
          // else if (!isValidPhoneNumber(values['phone'])) {
          //   setInvalidMobile(true);
          // } 


          return errors;
        }}
        onSubmit={submitRegister}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validateForm
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className="form">
            {status && (
              <div role="alert" className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            )}

            <div className="form-group">
              <Field type="text" name="firstname" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label><FormattedMessage id="REGISTER.FIRSTNAME" /></label>
                    <input
                      type="text"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder={intl.formatMessage({ id: 'user.firstname.placeholder' })}
                    />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="text" name="lastname" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label><FormattedMessage id="REGISTER.LASTNAME" /></label>
                    <input type="text" {...field} className={`${getInputClasses(meta)}`} placeholder={intl.formatMessage({ id: 'user.lastname.placeholder' })} />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="text" name="phone" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label>
                      <FormattedMessage id="CONTACT.MOBILE" />
                      <label style={{ fontSize: '12px', color: '#faa991', marginBottom: '1px' }}>
                        <label style={{ marginRight: '10px', fontSize: '12px', color: 'red' }}>&nbsp;*</label>
                      </label>
                    </label>
                    {/* <PhoneInput
                      name="phone"
                      placeholder="Enter mobile phone"
                      defaultCountry={locale}
                      international
                      value={values.phone}
                      onChange={e => {
                        const phoneRegex = /^[0-9]*$/
                        if (phoneRegex.test(e.target.value)) {
                          setFieldValue('phone', e);
                        }

                        if (e.target.value.length >= 10) {
                          setValid(true)
                        } else {
                          setValid(false)
                        }
                      }}
                    /> */}
                    <input
                      type="text"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="Enter mobile"
                      onChange={e => {
                        const phoneRegex = /^[0-9]*$/
                        if (phoneRegex.test(e.target.value)) {
                          setFieldValue('phone', e.target.value);
                        }
                      }}
                    />
                    <div className="error invalid-feedback">Invalid mobile</div>
                    {invalidMobile ? (
                      <div style={{ fontSize: '0.9rem', fontWeight: '400', marginTop: '0.25rem', color: '#F64E60' }}>
                        {values['phone'] ? 'Invalid field' : 'Required field'}
                      </div>
                    ) : null}
                    {/* {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )} */}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="email" name="email" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label>
                      {intl.formatMessage({ id: 'user.email' })}{inviteCode && <label style={{ fontSize: '12px', color: 'red' }}>&nbsp;*</label>}
                    </label>
                    <input type="email" {...field} className={`${getInputClasses(meta)}`} placeholder={intl.formatMessage({ id: 'user.email.placeholder' })} />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            {inviteCode && (
              <>
                <div className="form-group">
                  <Field type="password" name="password" component={Input}>
                    {({ field, form, meta }) => (
                      <div>
                        <label>
                          {intl.formatMessage({ id: 'user.password' })}{inviteCode && <label style={{ fontSize: '12px', color: 'red' }}>&nbsp;*</label>}
                        </label>
                        <input
                          type="password"
                          {...field}
                          className={`${getInputClasses(meta)}`}
                          placeholder={intl.formatMessage({ id: 'user.password.placeholder' })}
                        />
                        {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field type="password" name="re_password" component={Input}>
                    {({ field, form, meta }) => (
                      <div>
                        <label>
                          {intl.formatMessage({ id: 'user.repassword' })}<label style={{ fontSize: '12px', color: 'red' }}>&nbsp;*</label>
                        </label>
                        <input
                          type="password"
                          {...field}
                          className={`${getInputClasses(meta)}`}
                          placeholder={intl.formatMessage({ id: 'user.repassword.placeholder' })}
                        />
                        {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>
              </>
            )}

            <div className="form-group">
              <Field type="date" name="date" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label><FormattedMessage id="REGISTER.BIRTHDATE" /></label>
                    {/* <input
                      type="date"
                      {...field}
                      placeholder="Enter Birthdate"
                      className={`${getInputClasses(meta)}`}
                    /> */}
                    <TextField
                      {...field}
                      type="date"
                      className={`${getInputClasses(meta)}`}
                      placeholder="dd/mm/yyyy"
                      format="dd/mm/yyyy"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="text" name="salesName" component={Input}>
                {({ field, form, meta }) => (
                  <div>
                    <label>{intl.formatMessage({ id: 'user.salesname' })}</label>
                    <input type="text" {...field} className={`${getInputClasses(meta)}`} placeholder={intl.formatMessage({ id: 'user.salesname.placeholder' })} />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="text" name="shippingAddress" component={Input} as="textarea">
                {({ field, form, meta }) => (
                  <div>
                    <label><FormattedMessage id="REGISTER.SHIPPING.ADDRESS" /></label>
                    <textarea
                      rows="3"
                      type="text"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder={intl.formatMessage({ id: 'user.shipping.address.placeholder' })}
                    />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field type="text" name="remark" component={Input} as="textarea">
                {({ field, form, meta }) => (
                  <div>
                    <label><FormattedMessage id="REGISTER.REMARK" /></label>
                    <textarea
                      rows="3"
                      type="text"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder={intl.formatMessage({ id: 'user.remark.placeholder' })}
                    />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="form-group">
                  <>
                    <DatePicker
                      disableFuture
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                        },
                        "aria-readonly": true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder="Date of Birth (Date/Month/Year)"
                      format="MM/dd/yyyy"
                      margin="normal"
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      className={`${getInputClasses({
                        touched: selectedDate || selectedDateError,
                        error: !validateBirthDate() || selectedDateError,
                      })}`}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    {(!validateBirthDate() || selectedDateError) && (
                      <div className="error invalid-feedback">
                        Invalid date of birth
                      </div>
                    )}
                  </>
                </div>
              </MuiPickersUtilsProvider> */}

            <div className="form-group">
              <Checkbox
                color="primary"
                name="acceptTerms"
                onChange={() => setAcceptTerm(!acceptTerm)}
                checked={acceptTerm}
              />
              <FormattedMessage id="FORM.TERM.IAGREE" />
              <a style={{ color: 'blue' }} onClick={() => setModal(true)}>
                <FormattedMessage id="FORM.TERM" />
              </a>
              <span />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={!acceptTerm}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={() => validateForm().then(values => values !== {} && setError('Please enter required field.'))}
              >
                <span className={`${loading ? 'pr-3' : ''}`}>
                  <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                </span>
                {loading && <span className="spinner-border text-light"></span>}
              </button>

              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={() => history.goBack()}
              >
                {intl.formatMessage({
                  id: 'FORM.CANCEL'
                })}
              </button>
            </div>
          </form>
        )}
      </Formik>
      <Dialog onClose={() => setModal(false)} aria-labelledby="simple-dialog-title" open={modal}>
        <DialogTitle id="simple-dialog-title">{intl.formatMessage({ id: 'terms.and.conditions' })}</DialogTitle>
        <List style={{ padding: '20px', lineHeight: '32px' }}>
          These Funcrowds Terms and Conditions of Use (these “Terms and Conditions“) set forth the terms and conditions
          for the use of any and all products and services (collectively, the “Services”) provided by Funcrowds
          Corporation (“Funcrowds“) to users of the Services (the “User“ or “Users“, depending upon the context).
          <br />
          <p className="pt-2 mb-1">
            <strong>1. Definitions</strong>
          </p>
          &emsp;The following words and terms shall have the meanings set forth below when they are used in these Terms
          and Conditions.
          <br />
          &emsp;1.1. “Contents” means information such as text, sounds, music, images, videos, software, programs,
          computer code, and other information.
          <br />
          &emsp;1.2. “Subject Contents” means Contents that may be accessed through the Services.
          <br />
          &emsp;1.3. “Submitted Contents” means Contents that Users have submitted, transmitted or uploaded on or to the
          Services.
          <br />
          <p className="pt-2 mb-1">
            <strong>2. Agreement to these Terms and Conditions</strong>
          </p>
          &emsp;2.1. All Users shall use the Services in accordance with these Terms and Conditions. Users may not use
          the Services unless they agree to these Terms and Conditions.
          <br />
          &emsp;2.2. Users who are minors may only use the Services by obtaining prior consent from their parents or
          legal guardians. Furthermore, if Users will be using the Services on behalf of, or for the purposes of, a
          business enterprise, then such business enterprise must also agree to these Terms and Conditions prior to
          using the Services.
          <br />
          &emsp;2.3. If there are Separate Terms and Conditions applicable to the Services, Users shall also comply with
          such Separate Terms and Conditions as well as these Terms and Conditions in using the Services.
          <br />
          <p className="pt-2 mb-1">
            <strong>3. Modification to these Terms and Conditions</strong>
          </p>
          &emsp;Funcrowds may modify these Terms and Conditions when Funcrowds deems it to be necessary, within the
          scope of the purposes of the Services. In such case, Funcrowds will indicate the contents of the modified
          version of these Terms and Conditions, as well as the effective date of the modification, on the Services or
          on Funcrowds’s website, or will publicize the same to Users by notifying Users in the manner prescribed by
          Funcrowds. The modified version of these Terms and Conditions shall become effective as of the effective date
          thereof.
          <br />
          <p className="pt-2 mb-1">
            <strong>4. Account</strong>
          </p>
          &emsp;4.1. When using the Services, Users may need to set up an account by registering certain information.
          Users must register true, accurate and complete information, and must revise the same to keep such information
          up-to-date at all times.
          <br />
          &emsp;4.2. If Users register any authentication information when using the Services, they must exercise due
          care in handling such information at their own responsibility to ensure that such information is not used in
          an unlawful manner. Funcrowds may treat any and all activities conducted under the authentication information
          as activities that have been conducted by the User with whom the authentication information is registered.
          <br />
          &emsp;4.3. Any User who has registered for the Services may delete such User’s account and cancel the Services
          at any time.
          <br />
          &emsp;4.4. Funcrowds reserves the right to delete any account that has been inactive for a period of one (1)
          year or more since its last activation, without any prior notice to the applicable User.
          <br />
          &emsp;4.5. Any and all rights of a User to use the Service shall cease to exist when such User’s account has
          been deleted for any reason. Please take note that an account cannot be retrieved after 6 months of account
          cancellation
          <br />
          &emsp;4.6. Each account in the Services is for exclusive use and belongs solely to the User of such account.
          Users may not transfer, lease or otherwise dispose their rights to use the Service to any third party, nor may
          the same be inherited or succeeded to by any third party.
          <br />
          <p className="pt-2 mb-1">
            <strong>5. Privacy</strong>
          </p>
          &emsp;5.1. Funcrowds places top priority on the privacy of its Users.
          <br />
          &emsp;5.2. Funcrowds will appropriately handle privacy information and personal information of Users in
          accordance with the Funcrowds Privacy Policy.
          <br />
          &emsp;5.3. Funcrowds promises to exercise the utmost care and attention to its security measures to ensure the
          safe management of any and all information collected from Users.
          <br />
          <p className="pt-2 mb-1">
            <strong>6. Provision of the Service</strong>
          </p>
          &emsp;6.1. Users shall supply PCs, mobile phone devices, smartphones and other communication devices,
          operating systems, communication methods and electricity, etc. necessary for using the Services at their own
          responsibility and expense.
          <br />
          &emsp;6.2. Funcrowds reserves the right to limit access to all or part of the Services by Users depending upon
          conditions that Funcrowds considers necessary, such as the age and identification of User, current
          registration status, and the like.
          <br />
          &emsp;6.3. Funcrowds reserves the right to modify, at Funcrowds's discretion, all or part of the Services as
          Funcrowds determines necessary anytime without any prior notice to Users.
          <br />
          &emsp;6.4. Funcrowds may cease providing all or part of the Services without any prior notice to Users in case
          of the occurrence of any of the following:
          <br />
          &emsp;&emsp;(1) When conducting maintenance or repair of systems;
          <br />
          &emsp;&emsp;(2) When the Services cannot be provided due to force majeure such as an accident (fire, power
          outage, etc.), act of God, war, riot, labor dispute;
          <br />
          &emsp;&emsp;(3) When there is system failure or heavy load on the system;
          <br />
          &emsp;&emsp;(4) When securing the safety of Users or third parties, or in the case of an emergency for the
          public welfare; or
          <br />
          &emsp;&emsp;(5) When Funcrowds reasonably determines it to be necessary, other than those set forth in items
          (1) through (4).
          <br />
          <p className="pt-2 mb-1">
            <strong>7. Advertisements</strong>
          </p>
          &emsp;Funcrowds reserves the right to post advertisements for Funcrowds or a third party on the Services.
          <br />
          <p className="pt-2 mb-1">
            <strong>8. Third-Party Services</strong>
          </p>
          &emsp;The Services may contain services or Contents provided by third parties other than Funcrowds. Such third
          parties shall bear any and all responsibility regarding such services and/or Contents. Furthermore, there may
          be terms or use or other terms and conditions provided by such third party which are applicable to such
          services and Contents.
          <br />
          <p className="pt-2 mb-1">
            <strong>9. Contents</strong>
          </p>
          &emsp;9.1. Funcrowds grants Users a non-transferable, non-sublicensable, non-exclusive license to use the
          Subject Contents provided by Funcrowds, solely for the purpose of using the Services.
          <br />
          &emsp;9.2. Users shall abide by the conditions applicable thereto when using the Subject Contents which are
          subject to conditions of use, such as additional fees and periods of use and the like. Even if terms such as
          “Purchase”, “Sales,” and the like appear on the screens for the Services, Funcrowds shall remain the owner of
          all intellectual property rights as well as all other rights in the Subject Contents offered to Users by
          Funcrowds, and such rights shall not be transferred to Users. Users will only be granted usage rights as set
          forth above.
          <br />
          &emsp;9.3. Users shall not use the Subject Contents beyond the scope of the intended use of the Subject
          Contents in the Services (including, without limitation, acts of copying, transmitting, reproducing, and
          modifying).
          <br />
          &emsp;9.4. If Users wish to back-up all or part of the Submitted Contents, they will need to do so themselves.
          Funcrowds will not undertake the obligation of backing up any of the Submitted Contents.
          <br />
          &emsp;9.5. The Services may include functions where multiple Users may edit Contents (post, correct, and
          delete, etc.). In such case, a User who posted his/her Submitted Contents shall grant other Users the right to
          edit such Submitted Contents.
          <br />
          &emsp;9.6. Users shall retain their rights to their Submitted Contents, and Funcrowds shall not acquire 　any
          such rights; provided, however, that, among the Submitted Contents, for those made available to other Users
          generally (i.e., not just to the User's “friends”), the User who posted such Submitted Contents shall be
          deemed to have granted Funcrowds a worldwide, perpetual, royalty-free license (including the right to amend
          (such as abbreviating) such Contents to the extent Funcrowds deems necessary and appropriate, and the right to
          sublicense such usage rights to other third parties working together with Funcrowds), to use such Contents for
          services and/or promotional purposes.
          <br />
          &emsp;9.7. Users shall not exercise their rights such as author’s moral rights with respect to the Submitted
          Contents in relation to the use by Funcrowds or a third party under Articles 10.5 and 10.6 above.
          <br />
          &emsp;9.8. Funcrowds may check and confirm the contents of the Submitted Contents by Users to the extent
          permissible under laws and regulations, when it is necessary for Funcrowds to confirm compliance with related
          laws and regulations or the provisions set out in these Terms and Conditions, etc.; provided, however, that
          Funcrowds is not obligated to conduct such confirmation.
          <br />
          &emsp;9.9. If Funcrowds believes that a User has violated or may violate any applicable laws or regulations or
          the provisions of these Terms and Conditions in relation to the Submitted Contents, or when otherwise
          reasonably necessary for Funcrowds’s business, then Funcrowds reserves the right to preclude such User's use
          of the Services in certain manners, such as deleting the Submitted Contents without providing prior notice to
          the User.
          <br />
          <p className="pt-2 mb-1">
            <strong>10. Provision of Subscription Services</strong>
          </p>
          &emsp;10.1. Users may receive services in exchange for payment of a fee or under the terms and conditions
          prescribed by Funcrowds, wherein the Users pay a certain amount of considerations to use certain Subject
          Contents designated by Funcrowds for a certain period of time (the “Subscription Services”). Users shall abide
          by these Terms and Conditions, the fees for the Subscription Services, the payment method thereof, as well as
          other terms and conditions of use posted on the Services or on Funcrowds’s website.
          <br />
          &emsp;10.2. Users may take procedures for the cancellation of Subscription Services at any time; provided,
          however, that even if a User takes the procedures for cancellation prior to the intended period of use, the
          User may not change such period of use, nor may the User cancel the purchase of the Subscription Services. In
          such case, the fees already paid will not be refunded, nor will there be any refund on a pro rata basis.
          However, the foregoing will not apply if laws or regulations require otherwise.
          <br />
          &emsp;10.3. If a User does not complete their cancellation procedure by the designated date and time, the
          period of use of the Subscription Services may be automatically renewed in accordance with the terms
          prescribed by Funcrowds even after the end of the period of use of such Subscription Services.
          <br />
          <p className="pt-2 mb-1">
            <strong>11. Restricted Matters</strong>
          </p>
          &emsp;Funcrowds prohibits Users from engaging in any of the following acts when using the Services:
          <br />
          &emsp;11.1. Acts that violate the laws and regulations, court verdicts, resolutions or orders, or
          administrative measures that are legally binding;
          <br />
          &emsp;11.2. Acts that may be in violation of public order, morals or customs;
          <br />
          &emsp;11.3. Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights,
          rights to fame, privacy, and all other rights granted by law or by a contract with Funcrowds and/or a third
          party;
          <br />
          &emsp;11.4. Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions
          that amount to child pornography or child abuse; expressions that lead to discrimination by race, national
          origin, creed, gender, social status, family origin, etc.; expressions that induce or encourage suicide,
          self-injurious behavior or drug abuse; or expressions that include anti-social content and lead to the
          discomfort of others;
          <br />
          &emsp;11.5. Acts that lead to the misrepresentation of Funcrowds and/or a third party or that intentionally
          spread false information;
          <br />
          &emsp;11.6. Acts of sending the same or similar messages to a large, indefinite number of Users (except for
          those approved by Funcrowds), indiscriminately adding other Users as friends or to group chats, or any other
          acts deemed by Funcrowds to constitute spamming;
          <br />
          &emsp;11.7. Acts of exchanging the right to use the Services or Contents into cash, property or other economic
          benefits, other than by using the method prescribed by Funcrowds;
          <br />
          &emsp;11.8. Acts of using the Services for sales, marketing, advertising, solicitation or other commercial
          purposes (except for those approved by Funcrowds); using the Services for the purpose of sexual conduct or
          obscene acts; using the Services for the purpose of meeting or engaging in sexual encounters with an unknown
          third party; using the Services for the purpose of harassment or libelous attacks against other Users; or
          otherwise using the Services for purposes other than as intended by the Services;
          <br />
          &emsp;11.9. Acts that benefit or involve collaboration with anti-social groups;
          <br />
          &emsp;11.10. Acts that are related to religious activities or invitations to certain religious groups;
          <br />
          &emsp;11.11. Acts of unauthorized or improper collection, disclosure, or provision of any other person's
          personal information, registered information, user history, or the like;
          <br />
          &emsp;11.12. Acts of interfering with the servers and/or network systems of the Services; fraudulently
          manipulating the Services by means of bots, cheat tools, or other technical measures; deliberately using
          defects of the Services; making unreasonable inquires and/or undue claims such as repeatedly asking the same
          questions beyond what is necessary, and other acts of interfering with or hindering Funcrowds's operation of
          the Services or other Users’ use of the Services;
          <br />
          &emsp;11.13. Acts of decoding the source code of the Services, such as by way of reverse engineering,
          disassembling or the like, for unreasonable purposes or in an unfair manner;
          <br />
          <p className="pt-2 mb-1">
            <strong>12. User Responsibility</strong>
          </p>
          &emsp;12.1. Users shall use the Services at their own risk, and shall bear any and all responsibilities for
          actions carried out under and the results from the use of the Services.
          <br />
          &emsp;12.2. Funcrowds may suspend the use of all or part of the Services, suspend or delete accounts, cancel
          any agreement between a User and Funcrowds with respect to the Services (including, without limitation, any
          agreement based on these Terms and Conditions, hereinafter the same) or take any other measure Funcrowds
          reasonably determines to be necessary and appropriate without prior notice to such User in the case that such
          User falls under or there is a possibility that such User falls under any of the following items:
          <br />
          &emsp;&emsp;(1) A User is in breach of applicable laws and regulations, these Terms and Conditions, or any
          Separate Terms and Conditions;
          <br />
          &emsp;&emsp;(2) A User is a member of an anti-social group or a related party thereof;
          <br />
          &emsp;&emsp;(3) A User tarnishes Funcrowds’s credibility by spreading false information, using fraudulent
          means or force, or other unlawful means;
          <br />
          &emsp;&emsp;(4) A User is subject to a petition for attachment, provisional attachment, or auction, ;
          procedures such as bankruptcy, civil rehabilitation, or similar procedures are commenced; or Funcrowds
          otherwise reasonably determines that there is uncertainty with respect to User’s credibility; or
          <br />
          &emsp;&emsp;(5) The relationship of trust with a User is lost or Funcrowds otherwise reasonably determines
          that it would not be appropriate for Funcrowds to provide the Services to User, due to reasons other than as
          set forth in items (1) to (4) above.
          <br />
          &emsp;12.3. In the case where Funcrowds suffers loss/damages either directly or indirectly (including, without
          limitation, attorney fees) due to a User’s use of the Services (including, without limitation, cases where
          Funcrowds receives a complaint from a third party due to such use), the User shall immediately compensate
          Funcrowds for such loss/damage in accordance with Funcrowds’s request.
          <br />
          <p className="pt-2 mb-1">
            <strong>13. NO WARRANTY</strong>
          </p>
          &emsp;Funcrowds SHALL PROVIDE NO WARRANTY, EITHER EXPRESSLY OR IMPLIEDLY, WITH RESPECT TO THE SERVICES
          (INCLUDING THE SUBJECT CONTENTS), THAT THERE ARE NO DEFECTS (INCLUDING, WITHOUT LIMITATION, FAULTS WITH
          RESPECT TO SECURITY, ETC., ERRORS OR BUGS, OR VIOLATIONS OF RIGHTS) OR AS TO THE SAFETY, RELIABILITY,
          ACCURACY, COMPLETENESS, EFFECTIVENESS AND FITNESS FOR A PARTICULAR PURPOSE. Funcrowds WILL IN NO WAY BE
          RESPONSIBLE FOR PROVIDING USERS WITH THE SERVICES AFTER DELETING SUCH DEFECTS.
          <br />
          <p className="pt-2 mb-1">
            <strong>14. Funcrowds's LIMITATION OF LIABILITY</strong>
          </p>
          &emsp;14.1. Funcrowds WILL NOT BE LIABLE FOR ANY DAMAGE INCURRED BY USERS FROM THE USE OF THE SERVICES, UNLESS
          SUCH DAMAGE IS ATTRIBUTABLE TO THE INTENTIONAL OR GROSSLY NEGLIGENT ACTS OF Funcrowds; PROVIDED, HOWEVER,
          THAT, IN THE CASE THAT THE AGREEMENT BETWEEN THE USER AND Funcrowds WITH RESPECT TO THE USE OF THE SERVICES
          FALLS UNDER A CONSUMER CONTRACT AS DEFINED UNDER THE CONSUMER CONTRACT ACT OF Thailand (“CONSUMER CONTRACT”),
          Funcrowds WILL BE LIABLE TO PROVIDE COMPENSATION FOR DAMAGE ARISING DUE TO NEGLIGENCE ON THE PART OF Funcrowds
          (EXCLUDING GROSS NEGLIGENCE) ARISING FROM CONTRACT OR TORT ONLY WITHIN THE RANGE OF (A) THE DAMAGE WHICH IS
          NORMALLY INCURRED (I.E., EXCLUSIVE OF LOST PROFITS AND OTHER DAMAGES ARISING UNDER SPECIAL CIRCUMSTANCES), AND
          (B) THE AMOUNT OF USAGE FEES FOR THE SERVICES RECEIVED FROM SUCH USER FOR FEE-BASED SERVICES FOR THE MONTH IN
          WHICH SUCH DAMAGE HAS BEEN INCURRED, AT MAXIMUM.
          <br />
          &emsp;14.2. IF A USER INCURS DAMAGE AS A RESULT OF GROSS NEGLIGENCE ON THE PART OF Funcrowds, Funcrowds WILL
          BE RESPONSIBLE TO PROVIDE COMPENSATION FOR THE DAMAGE INCURRED BUT ONLY WITHIN THE RANGE OF (A) THE DAMAGE
          WHICH IS NORMALLY INCURRED (I.E., EXCLUSIVE OF LOST PROFITS AND OTHER DAMAGES ARISING UNDER SPECIAL
          CIRCUMSTANCES), AND (B) THE AMOUNT OF USAGE FEES FOR THE SERVICES RECEIVED FROM SUCH USER FOR FEE-BASED
          SERVICES FOR THE MONTH IN WHICH SUCH DAMAGE HAS BEEN INCURRED, AT MAXIMUM; PROVIDED, HOWEVER, THAT THE
          FOREGOING SHALL NOT APPLY IF THE AGREEMENT BETWEEN SUCH USER AND Funcrowds FALLS UNDER THE DEFINITION OF A
          CONSUMER CONTRACT.
          <br />
          <p className="pt-2 mb-1">
            <strong>15. Relationship between these Terms and Conditions and Laws and Regulations</strong>
          </p>
          &emsp;If the terms of these Terms and Conditions violate any laws and regulations applicable to an agreement
          between Users and Funcrowds with respect to the Services (including, without limitation, the Consumer Contract
          Act of Japan), such terms, to the extent of such violation, shall not apply to the agreement with the Users;
          provided, however, that the remaining terms of these Terms and Conditions shall not be affected.
          <br />
          <p className="pt-2 mb-1">
            <strong>16. Notification and Contact</strong>
          </p>
          &emsp;16.1. For notifications from Funcrowds to Users regarding the Services, Funcrowds will use a method that
          Funcrowds considers appropriate, such as posting in an appropriate place within the Services or on Funcrowds’s
          website.
          <br />
          &emsp;16.2. For notifications from Users to Funcrowds regarding the Services, Users shall use the customer
          inquiry form available on the Services or on Funcrowds’s website, or through other means designated by
          Funcrowds.
          <br />
          <p className="pt-2 mb-1">
            <strong>17. Governing Law and Jurisdiction</strong>
          </p>
          &emsp;The Agreement shall be interpreted in accordance with, and all rights hereunder and thereunder and
          provisions hereof and thereof shall be governed by the laws of respective country the agreement is executed.
        </List>
        <DialogActions>
          <button
            type="button"
            onClick={() => {
              setAcceptTerm(true);
              setModal(false);
            }}
            className="btn btn-primary"
          >
            {intl.formatMessage({
              id: 'FORM.ACCEPT'
            })}
          </button>
          <button type="button" onClick={() => setModal(false)} className="btn btn-light-primary">
            {intl.formatMessage({
              id: 'FORM.CANCEL'
            })}
          </button>
        </DialogActions>
      </Dialog>
      <Snackbar open={!inviteCode && error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {error && error.includes('401') ? 'This user has not been suspend' : error}
        </Alert>
      </Snackbar>
      <Snackbar open={timeoutError} autoHideDuration={4000} onClose={() => setTimeoutError(false)}>
        <Alert onClose={() => setTimeoutError(false)} severity="error">
          {timeoutError}
        </Alert>
      </Snackbar>
      <Snackbar open={inviteCode && adminError} autoHideDuration={4000} onClose={() => setAdminError(false)}>
        <Alert onClose={() => setAdminError(false)} severity="error">
          {adminError && adminError.includes('401') ? 'This user has not been suspend' : adminError}
        </Alert>
      </Snackbar>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Verify mobile</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
          <p className="text-center">Please enter number 6 digits OTP send to {mobile}</p>
          <div className="form-group">
            <input
              max="999999999"
              type="number"
              className="form-control"
              name="name"
              placeholder="OTP"
              value={otp}
              onChange={event => onChangeOTP(event)}
            />
          </div>
          <button
            type="button"
            disabled={timeLeft === 0 ? '' : 'disabled'}
            className="btn btn-secondary"
            style={{ marginBottom: '10px' }}
            onClick={e => onSubmit(e)}
          >
            Resend OTP (Time left: {timeLeft} seconds)
          </button>
          <button
            type="button"
            disabled={timeLeft === 0 ? 'disabled' : ''}
            className="btn btn-primary"
            onClick={e => onSubmitOTP(e)}
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
