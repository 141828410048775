import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { editShopByAdmin } from '../../modules/Auth/_redux/authCrud';
import * as auth from '../../modules/Auth/_redux/authRedux';
import { Container } from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReactCrop from 'react-image-crop';
import { compressionNormalImage, compressionSmallImage } from '../../utils/compressionImage';
import { Modal } from 'react-bootstrap';
import replaceUrl from '../../utils/replaceUrl';
import styled from 'styled-components';

const Contact = ({ intl }) => {
  const dispatch = useDispatch();
  const { shop, user } = useSelector(state => state.auth);
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [oldPicture, setOldPicture] = useState(null);
  const [oldCoverPhoto, setOldCoverPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const [form, setForm] = useState({
    officeHours: '',
    address: '',
    mobile: '',
    facebook: '',
    line: '',
    whatsapp: '',
    startPoint: '',
    picture: null,
    coverPicture: ''
  });
  const [currentImgType, setCurrentImageType] = useState('picture')
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [show, setShow] = useState(false);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [defaultImg, setDefaultImg] = useState(null);

  const handleEditMode = () => {
    if (shop) {
      setForm({
        officeHours: shop.officeHour,
        address: shop.address,
        mobile: shop.mobile,
        facebook: shop.facebook !== 'undefined' ? shop.facebook : undefined,
        line: shop.line !== 'undefined' ? shop.line : undefined,
        whatsapp: shop.whatsapp !== 'undefined' ? shop.whatsapp : undefined,
        startPoint: shop.startPoint
      });
      setOldPicture(shop.logo ? `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shop.logo.data}` : undefined);
      setOldCoverPhoto(shop.cover ? `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shop.cover.data}` : undefined);
    }
    setIsEditMode(true);
  };

  const onChangeHandler = event => {
    if ((event.target.name == 'picture' || event.target.name == 'coverPicture') && event.target.files && event.target.files[0]) {
      setCurrentImageType(event.target.name)
      setForm({ ...form, [event.target.name]: event.target.files[0] });
      setShow(true);
      setDefaultImg(event.target.files[0]);
      setUpImg(URL.createObjectURL(event.target.files[0]));
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const onSubmit = async event => {
    event.preventDefault();
    var formdata = new FormData();
    formdata.append('officeHour', form.officeHours);
    formdata.append('address', form.address);
    formdata.append('mobile', form.mobile);
    formdata.append('facebook', form.facebook);
    formdata.append('line', form.line);
    formdata.append('whatsapp', form.whatsapp);
    formdata.append('startPoint', form.startPoint);
    if (form.picture) {
      formdata.append('logo', form.picture, form.picture.name);
      const compressedResizeLogo = await compressionSmallImage(form.picture);
      formdata.append('resizeLogo', compressedResizeLogo, form.picture.name);
    }
    if (form.coverPicture) {
      formdata.append('cover', form.coverPicture, form.coverPicture.name);
    }

    editShopByAdmin(shop.seqId, formdata)
      .then(response => {
        if (response.status == 201 || response.status == 200) {
          const { data } = response.data;
          dispatch({ type: auth.actionTypes.GetShopInfo, payload: data });
          setSuccess(true);
          setIsEditMode(false);
        } else {
          setError(true);
        }
      })
      .catch(error => {
        setError(true);
      });
  };

  const submitCrop = async (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);

    canvas.toBlob(
      async blob => {
        const image = blob || defaultImg;
        const compressFile = await compressionNormalImage(image);
        setForm({
          ...form,
          [currentImgType]: Object.assign(compressFile, {
            name: defaultImg.name,
            preview: URL.createObjectURL(compressFile)
          })
        });
        setShow(false);
        setLoading(false);
      },
      'image/png',
      1
    );
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const replaceSpace = (text) => {
    return text.replace(/\s/g, '+')
  }

  return (
    <Container style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <h2 style={{ fontWeight: 'bold', }}>
        <FormattedMessage id="MENU.CONTACT_US" />
      </h2>
      <div className="d-flex flex-wrap mb-5">
        {shop && shop.logo && (
          <img
            alt="Logo"
            className="img-fluid mx-auto mb-5"
            style={{ maxHeight: 150 }}
            src={shop.logo && `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shop.logo.data}`}
          />
        )}
      </div>
      <Container>
        {shop && !isEditMode ? (
          <div>
            <div className="p-2">{shop.name}</div>
            <ContainerFlex>
              <i className="fas fa-map-marked-alt fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
              <ContainerText
                dangerouslySetInnerHTML={{
                  __html: replaceUrl(replaceSpace(`https://www.google.com/maps/search/${shop.address}`), shop.address)
                }}
              />
            </ContainerFlex>
            <ContainerFlex style={{ color: '#fff' }}>
              <i className="fas fa-phone-alt fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
              <FormattedMessage id="CONTACT.TELEPHONE" /> : &nbsp;<label onClick={() => window.open(`tel:${shop.mobile}`)}>{shop.mobile}</label>
            </ContainerFlex>
            <ContainerFlex style={{ color: '#fff' }}>
              <div>
                <i className="far fa-clock fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
              </div>
              <ContainerText>
                <label style={{ whiteSpace: 'break-spaces' }}>{shop.officeHour}</label>
              </ContainerText>
            </ContainerFlex>
            {shop.facebook && (
              <ContainerFlex>
                <div>
                  <i className="fab fa-facebook-f fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
                </div>
                <ContainerText
                  dangerouslySetInnerHTML={{
                    __html: replaceUrl(shop.facebook),
                  }}
                />
              </ContainerFlex>
            )}
            {shop.whatsapp && (
              <ContainerFlex>
                <i className="fab fa-whatsapp fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
                <ContainerText
                  dangerouslySetInnerHTML={{
                    __html: shop.whatsapp.includes('https://') ? replaceUrl(shop.whatsapp) : replaceUrl(`https://wa.me/${shop.whatsapp}`),
                  }}
                />
              </ContainerFlex>
            )}
            {shop.line && (
              <ContainerFlex>
                <i className="fab fa-line fa-2x" style={{ color: '#464E5F', width: 40 }}></i>
                <ContainerText
                  dangerouslySetInnerHTML={{
                    __html: shop.line.includes('https://') ? replaceUrl(shop.line) : replaceUrl(`http://line.me/ti/p/${shop.line}`),
                  }}
                />
              </ContainerFlex>
            )}
            {user.type !== 'customer' && (
              <>
                <div className="p-2" style={{ color: '#fff' }}>
                  <FormattedMessage id="CONTACT.START.POINT" />&nbsp;:&nbsp;&nbsp;{shop.startPoint}</div>
                <div className="d-flex justify-content-end">
                  <button type="button" className="btn btn-primary" onClick={() => handleEditMode()}>
                    <FormattedMessage id="CONTACT.EDIT.CONTACT.US" />
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          ''
        )}

        {isEditMode && (
          <form onSubmit={event => onSubmit(event)}>
            <div className="form-group">
              <label><FormattedMessage id="CONTACT.ADDRESS" /></label>
              <input
                type="text"
                className="form-control"
                name="address"
                value={form.address}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className="form-group">
              <label><FormattedMessage id="CONTACT.MOBILE" /></label>
              <input
                type="text"
                className="form-control"
                name="mobile"
                value={form.mobile}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className="form-group">
              <label><FormattedMessage id="CONTACT.OFFICE.HOURS" /></label>
              <textarea
                type="text"
                className="form-control"
                name="officeHours"
                value={form.officeHours}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className="form-group">
              <label>Facebook</label>
              <input
                type="text"
                className="form-control"
                name="facebook"
                value={form.facebook}
                onChange={onChangeHandler}
              />
            </div>
            <div className="form-group">
              <label>Whatsapp</label>
              <input
                type="text"
                className="form-control"
                name="whatsapp"
                value={form.whatsapp}
                onChange={onChangeHandler}
              />
            </div>
            <div className="form-group">
              <label>Line</label>
              <input type="text" className="form-control" name="line" value={form.line} onChange={onChangeHandler} />
            </div>
            <div className="form-group">
              <label><FormattedMessage id="CONTACT.START.POINT" /></label>
              <input
                type="number"
                className="form-control"
                name="startPoint"
                value={form.startPoint}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className="form-group">
              <label>Logo</label>
              <div>
                <img style={{ height: '50px' }} src={oldPicture} />
              </div>
            </div>
            <div className="form-group">
              <label>Upload picture</label>
              <div className="custom-file">
                <input
                  accept="image/png, image/jpeg"
                  type="file"
                  className="custom-file-input"
                  name="picture"
                  onChange={onChangeHandler}
                />
                <label className="custom-file-label">
                  {form.picture && form.picture.name ? form.picture.name : 'Choose file...'}
                </label>
              </div>
              <label style={{ fontSize: '0.7rem', color: 'red' }}>
                <FormattedMessage id="CONTACT.MAXMIMUN.UPLOAD" />
              </label>
            </div>
            <div className="form-group">
              <label>Cover Photo</label>
              <div>
                <img style={{ height: '50px' }} src={oldCoverPhoto} />
              </div>
            </div>
            <div className="form-group">
              <label>Upload cover photo</label>
              <div className="custom-file">
                <input
                  accept="image/png, image/jpeg"
                  type="file"
                  className="custom-file-input"
                  name="coverPicture"
                  onChange={onChangeHandler}
                />
                <label className="custom-file-label">
                  {form.coverPicture && form.coverPicture.name ? form.coverPicture.name : 'Choose file...'}
                </label>
              </div>
              <label style={{ fontSize: '0.7rem', color: 'red' }}>
                Maximum upload file size 3MB, Recommended size 375x150 pixels.
              </label>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginRight: '1.75rem' }}
                onClick={() => setIsEditMode()}
              >
                <FormattedMessage id="BUTTON.CANCEL" />
              </button>
              <button type="submit" className="btn btn-primary">
                <FormattedMessage id="BUTTON.SUBMIT" />
              </button>
            </div>
          </form>
        )}
      </Container>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          <FormattedMessage id="CONTACT.UPDATE.FAILED" />
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          <FormattedMessage id="CONTACT.UPDATE.SUCCESS" />
        </Alert>
      </Snackbar>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'crop.image' })}</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column" style={{ padding: '1.75rem' }}>
          <p>{intl.formatMessage({ id: 'default.image' })}</p>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
          {completedCrop?.width || completedCrop?.height ? (
            <>
              <p style={{ padding: '20px 0 0 0' }}>{intl.formatMessage({ id: 'preview.image' })}</p>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </>
          ) : null}
          {loading && (
            <div className="d-flex justify-content-center" style={{ margin: 10 }}>
              <span style={{ marginRight: 10, color: '#464E5F' }}>{intl.formatMessage({ id: 'compression.image' })}</span>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{intl.formatMessage({ id: 'loading' })}</span>
              </div>
            </div>
          )}
          <button
            style={{ marginTop: 10 }}
            type="button"
            disabled={loading || !(completedCrop?.width || completedCrop?.height)}
            className="btn btn-primary"
            onClick={() => {
              submitCrop(previewCanvasRef.current, completedCrop);
            }}
          >
            <FormattedMessage id="BUTTON.SUBMIT" />
          </button>
        </div>
      </Modal>
    </Container>
  );
};

export default injectIntl(Contact);

const ContainerFlex = styled.div`
  display: flex;
  padding: 8px;
`
const ContainerText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`