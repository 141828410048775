import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, InviteText } from './styles';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import QRCode from 'qrcode.react';
import { printToFile } from '../../utils/downloadCanvas'

const InviteFriend = props => {
  const [snack, setSnack] = useState(false);
  const { id } = props.match.params;
  const { shop } = useSelector(state => state.auth);

  return (
    <Container>
      <h2 style={{ fontWeight: 'bold' }}>
        <FormattedMessage id="SHOP.INVITE.HEADER" />
      </h2>
      <div className="qr-container">
        <div id="qrCodeInvite" style={{ padding: '0 20px 0 20px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <InviteText style={{ fontSize: '18px' }}>{shop.name}</InviteText>
          <div className="qr">
            <QRCode value={`${process.env.REACT_APP_BASE_URL}/shop/${id}`} />
          </div>
        </div>
        <InviteText>
          <FormattedMessage id="SHOP.INVITE.MESSAGE" />
        </InviteText>
      </div>
      <Divider variant="middle" className="divider" style={{ height: '3px', backgroundColor: '#5ed3dc' }} />
      <div className="link">
        <div className="text-field">
          <TextField
            variant="outlined"
            style={{ backgroundColor: '#fff', boxShadow: '0px 0px 5px 5px #EDEDED inset' }}
            value={`${process.env.REACT_APP_BASE_URL}/shop/${id}`}
            InputProps={{
              endAdornment: (
                <FileCopyIcon
                  className="pointer"
                  style={{ color: '#5ed3dc' }}
                  onClick={() => {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/shop/${id}`);
                    setSnack('Copied!!');
                  }}
                />
              )
            }}
            fullWidth
          />
        </div>
      </div>
      <InviteText className="d-flex justify-content-center">
        <FormattedMessage id="SHOP.INVITE.INFO" />
      </InviteText>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button type='button' className='btn btn-light-primary' onClick={() => printToFile('qrCodeInvite', `${shop.name}.png`)}>Download QR Code</button>
      </div>
      <Snackbar autoHideDuration={2000} message={snack} open={snack} onClose={() => setSnack(false)} />
    </Container>
  );
};

export default InviteFriend;
