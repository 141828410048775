import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../modules/ECommerce/_redux/packages/customersActions"
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { Container } from './styles'
import { useHistory } from "react-router-dom"

const Packages = props => {
    const [search, setSearch] = useState(null)
    const [tab, setTab] = useState(0)
    const { authToken, user } = useSelector(state => state.auth)
    const { entities: packages } = useSelector(state => state.packages)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {

        dispatch(actions.fetchPackages(authToken))

    }, [dispatch, authToken])
    console.log(packages)

    const isTabShow = () => packages && formatPromotions(packages) && (formatPromotions(packages).filter(promotion => promotion.status === 'active' && (promotion.point >= 0 && (!promotion.credit))).length > 0 &&
        formatPromotions(packages).filter(promotion => promotion.status === 'active' && (promotion.credit && promotion.credit > 0)).length > 0)


    const TabPoint = () => {
        return (
            <div className="promotions">
                {packages && formatPromotions(packages) && formatPromotions(packages).filter(promotion => promotion.status === 'active' && (promotion.point >= 0 && (!promotion.credit))).map(promotion => (
                    <div onClick={() => history.push(`/shop/${user.seqId}/package/${promotion._id}`, promotion)} className="promotion">
                        <div className="promo-head">
                            <div className="promo-name">{promotion.name}</div>
                            <div className="promo-date">{moment(promotion.start).format('DD MMMM YYYY')}</div>
                        </div>
                        <img src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/packages/${promotion.image}`} className="promo-image" />
                        <div className="label-row">
                            <span>{promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}</span>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const TabCredit = () => {
        return (
            <div className="promotions">
                {packages && formatPromotions(packages) && formatPromotions(packages).filter(promotion => promotion.credit && promotion.credit > 0).map(promotion => (
                    <div onClick={() => history.push(`/shop/${user.seqId}/package/${promotion._id}`, promotion)} className="promotion">
                        <div className="promo-head">
                            <div className="promo-name">{promotion.name}</div>
                            <div className="promo-date">{moment(promotion.start).format('DD MMMM YYYY')}</div>
                        </div>
                        <img src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${promotion.image}`} className="promo-image" />
                        <div className="label-row">
                            <span>{promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}</span>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const formatPromotions = promotions => promotions.filter(promotion => promotion.status === 'active' && moment().isBetween(moment(promotion.start).subtract(1, 'days'), moment(promotion.end), 'days') && (!search || promotion.name.toLowerCase().includes(search.toLowerCase())))

    return (
        <Container>
            <div>
                {/* <h2 className="header-promo">Promotions</h2> */}
                <div className="searchbox">
                    <TextField
                        fullWidth
                        label="Search"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {isTabShow() && (
                    <Paper square>
                        <Tabs variant="fullWidth" value={tab} onChange={(e, value) => setTab(value)} >
                            <Tab label="point" />
                            <Tab label="credit" />
                        </Tabs>
                    </Paper>
                )}
                {tab === 0 && <TabPoint />}
                {(tab === 1 || (packages && formatPromotions(packages).filter(promotion => promotion.status === 'active' && (promotion.point >= 0 && (!promotion.credit))).length === 0)) && <TabCredit />}
            </div>
        </Container>
    )
}

export default Packages