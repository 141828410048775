import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/products/productsActions';
import * as uiHelpers from '../RedeemUIHelpers';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from '../../../../../../_metronic/_helpers';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useRedeemHistoryUIContext } from '../RedeemUIContext';
import { getRedeemHistory, getPointHistory } from '../../../_redux/redeem/redeemCrud';
import { injectIntl } from 'react-intl';
import axios from 'axios';

const RedeemHistoryTable = ({ intl, totalSize }) => {
  // Products UI Context
  const redeemHistoryUIContext = useRedeemHistoryUIContext();
  const [redeemHistory, setRedeemHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [isPromotion, setIsPromotion] = useState(false);
  const [content, setContent] = useState(undefined);

  const productsUIProps = useMemo(() => {
    return {
      ids: redeemHistoryUIContext.ids,
      setIds: redeemHistoryUIContext.setIds,
      queryParams: redeemHistoryUIContext.queryParams,
      setQueryParams: redeemHistoryUIContext.setQueryParams
    };
  }, [redeemHistoryUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(state => ({ currentState: state.products }), shallowEqual);
  const filter = redeemHistoryUIContext.queryParams.filter;
  const { totalCount, entities, listLoading } = currentState;
  const { authToken } = useSelector(state => state.auth);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    fetchRedeemHistory();
  }, []);

  const fetchRedeemHistory = async () => {
    const result = await getPointHistory();
    setRedeemHistory(result.data.pointHistory);
  };

  const handleClose = () => {
    setShow(false);
  };

  const onClickImage = (imgPath, selectedContent, isPromotion) => {
    setShow(true);
    setIsPromotion(isPromotion);
    setImagePath(imgPath);
    setContent(selectedContent);
  };

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchProducts(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);

  const formatDate = (value, format) => {
    const date = moment(value)
      .tz(moment.tz.guess())
      .format(format == 'bd' ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')
      .split(',');
    return (
      <span>
        {date[0]}
        <br />
        {format == 'bd' ? null : date[1]}
      </span>
    );
  };

  const formatDateLine = (value, format) => {
    if (!value) return undefined;
    const date = moment(value)
      .tz(moment.tz.guess())
      .format(format == 'bd' ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')
      .split(',');
    return (
      <span>
        {date[0]} {format == 'bd' ? null : date[1]}
      </span>
    );
  };

  const customerColumns = [
    {
      dataField: 'createdAt',
      text: (
        <span>
          {intl.formatMessage({
            id: 'REDEEM.HISTORY.PROMOTION.DATE'
          })}
          <br />({moment.tz.guess()})
        </span>
      ),
      formatter: createdAt => formatDate(createdAt, 'tz')
    },
    {
      dataField: 'receipt',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.RECEIPT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cellContent, row) => (
        <span>
          <span>
            {row.receipt &&
              row.receipt.map(url => (
                <i
                  className="fas fa-receipt p-2"
                  style={{ color: '#1ba7a2' }}
                  onClick={() =>
                    onClickImage(
                      `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${url.replace('public/', '')}`,
                      row,
                      false
                    )
                  }
                ></i>
              ))}
          </span>
          <span>
            {row.promotion &&
              row.promotion.image.map(url => (
                <i
                  class="far fa-image p-2"
                  style={{ color: '#ff9b4a' }}
                  onClick={() =>
                    onClickImage(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${url}`, row, true)
                  }
                ></i>
              ))}
          </span>
        </span>
      )
    },
    {
      dataField: 'point',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.POINT.OR.CREDIT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: point => (point > 0 ? `+${point}` : point)
    },
    {
      dataField: 'newPoint',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.NEW.POINT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center'
    },
    {
      dataField: 'description',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.DESCRIPTION'
      })
    }
  ];

  const adminColumns = [
    {
      dataField: 'createdAt',
      text: (
        <span>
          {intl.formatMessage({
            id: 'REDEEM.HISTORY.PROMOTION.DATE'
          })}
          <br />({moment.tz.guess()})
        </span>
      ),
      formatter: createdAt => formatDate(createdAt, 'tz')
    },
    {
      dataField: 'receipt',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.RECEIPT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cellContent, row) => (
        <span>
          <span>
            {row.receipt &&
              row.receipt.map(url => (
                // <a
                //   key={url}
                //   href={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${url.replace('public/', '')}`}
                //   target="_blank"
                //   className="p-2"
                // >
                <i
                  className="fas fa-receipt p-2"
                  style={{ color: '#1ba7a2' }}
                  onClick={() =>
                    onClickImage(
                      `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${url.replace('public/', '')}`,
                      row,
                      false
                    )
                  }
                ></i>
                // </a>
              ))}
          </span>
          <span>
            {row.promotion &&
              row.promotion.image.map(url => (
                <i
                  class="far fa-image p-2"
                  style={{ color: '#ff9b4a' }}
                  onClick={() =>
                    onClickImage(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${url}`, row, true)
                  }
                ></i>
              ))}
          </span>
        </span>
      )
    },
    {
      dataField: 'point',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.POINT.OR.CREDIT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: point => (point > 0 ? `+${point}` : point)
    },
    {
      dataField: 'newPoint',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.NEW.POINT'
      }),
      classes: 'text-center',
      headerClasses: 'text-center'
    },
    {
      dataField: 'description',
      text: intl.formatMessage({
        id: 'POINT.HISTORY.DESCRIPTION'
      })
    },
    {
      dataField: 'shopUser',
      text: intl.formatMessage({
        id: 'REDEEM.HISTORY.USER.DETAIL'
      }),
      formatter: shopUser => (
        <span>
          <span className="font-weight-bold">Name: &nbsp;</span>
          {shopUser.user.firstName} {shopUser.user.lastName}
          <br />
          <span className="font-weight-bold">Email: &nbsp;</span>
          {shopUser.user.email}
          <br />
          <span className="font-weight-bold">Phone: &nbsp;</span>
          {shopUser.user.phone}
          <br />
          <span className="font-weight-bold">Birthdate: &nbsp;</span>
          {shopUser.user.birthdate && formatDate(shopUser.user.birthdate, 'bd')}
        </span>
      )
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: redeemHistory && redeemHistory.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber
  };

  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps;
    const indexOfLast = page * sizePerPage;
    const indexOfFirst = indexOfLast - sizePerPage;
    const currentEntities = entities.slice(indexOfFirst, indexOfLast);
    totalSize(redeemHistory.length);
    return currentEntities;
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={redeemHistory === null ? [] : pagination(redeemHistory, paginationOptions)}
                columns={user.type == 'customer' ? customerColumns : adminColumns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(productsUIProps.setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <Dialog maxWidth="sm" fullWidth open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Promotion Users</DialogTitle>

        <DialogContent>
          {loading && <div>{intl.formatMessage({ id: 'loading' })}</div>}
          <List>
            {!loading &&
              modal.length &&
              modal?.map((redeem, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${redeem.shopUser?.user?.firstName} ${redeem.shopUser?.user?.lastName}`}
                    secondary={moment(redeem.createdAt).format('DD/MM/YYYY')}
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{isPromotion ? 'Promotion Image' : 'Receipt Image'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagePath} className="img-fluid" />
          {content && (
            <div style={{ marginTop: '15px' }}>
              <p className="font-weight-bold">
                Usage date: &nbsp;
                <label className="font-weight-normal">{formatDateLine(content.createdAt, 'tz')}</label>
              </p>
              <p className="font-weight-bold">
                Redeem point: &nbsp;<label className="font-weight-normal">{content.point}</label>
              </p>
              <p className="font-weight-bold">
                Description: &nbsp;<label className="font-weight-normal">{content.description}</label>
              </p>
              {typeof content.shopUser !== 'string' ? (
                <div>
                  <p className="font-weight-bold" style={{ paddingRight: '10px' }}>
                    User Detail
                  </p>
                  <div style={{ marginLeft: '15px' }}>
                    {(content.shopUser.user.firstName || content.shopUser.user.lastName) && (
                      <p className="font-weight-bold">
                        Name: &nbsp;
                        <label className="font-weight-normal">
                          {content.shopUser.user.firstName} {content.shopUser.user.lastName}
                        </label>
                      </p>
                    )}
                    {content.shopUser.user.email && (
                      <p className="font-weight-bold">
                        Email: &nbsp;<label className="font-weight-normal">{content.shopUser.user.email}</label>
                      </p>
                    )}
                    {content.shopUser.user.phone && (
                      <p className="font-weight-bold">
                        Phone: &nbsp;<label className="font-weight-normal">{content.shopUser.user.phone}</label>
                      </p>
                    )}
                    {content.shopUser.user.birthdate && (
                      <p className="font-weight-bold">
                        Birthday: &nbsp;
                        <label className="font-weight-normal">
                          {formatDateLine(content.shopUser.user.birthdate, 'bd') || ''}
                        </label>
                      </p>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default injectIntl(RedeemHistoryTable);
