import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setTokenBySeqId, removeTokenBySeqId } from '../../../utils/localstorage'

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  LineProfileLoaded: '[Load Line Profile] Action',
  GetShopInfo: '[Get shop info] Action',
  Timeout: 'SESSION_TIMEOUT',
  SetErrorMessage: 'SET_ERROR_MESSAGE'
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  lineProfile: undefined,
  shop: undefined,
  errorMessage: undefined
};

export const reducer = persistReducer(
  { storage, key: 'demo1-auth', whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, seqId } = action.payload;
        const shopSeqId = state.shop?.seqId

        if (seqId || shopSeqId) {
          setTokenBySeqId(seqId || shopSeqId, authToken)
        }

        return { ...state, authToken };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { ...state, authToken };
      }

      case actionTypes.Logout: {
        const payloadSeqId = action.payload?.seqId
        const shopSeqId = state.shop?.seqId
        if (!action.payload?.trigger) {
          removeTokenBySeqId(payloadSeqId || shopSeqId)
        }

        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.LineProfileLoaded: {
        const { lineProfile } = action.payload;
        return { ...state, lineProfile };
      }

      case actionTypes.GetShopInfo: {
        const { shop } = action.payload;
        return { ...state, shop };
      }

      case actionTypes.Timeout: {
        const seqId = window.location.pathname.split('/');
        // const { seqId } = state.shop
        removeTokenBySeqId(seqId[2])
        return { shop: state.shop };
      }

      case actionTypes.SetErrorMessage: {
        const { error } = action.payload;
        return { ...state, errorMessage: error };
      }

      default:
        return { ...state };
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  loadLineProfile: lineProfile => ({ type: actionTypes.LineProfileLoaded, payload: { lineProfile } })
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   yield put(actions.fulfillUser(user));
  // });
}
