import React from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { GreenCardItem, CardContainer, CardItem } from './styles'
import { UserMenu, AdminMenu } from './constants/menu'
import MainPageHeader from "./MainPageHeader";

const MainPage = () => {
  const history = useHistory();
  const shop = useSelector(state => state.auth.shop);
  const user = useSelector(state => state.auth.user);
  const shopId = shop.seqId
  const menuList = user.type === 'customer' ? UserMenu : AdminMenu

  return (
    <div>
      <MainPageHeader />
      <CardContainer style={{ padding: '54px 22px' }}>
        {menuList.map(item => (
          <CardItem>
            <GreenCardItem onClick={() => history.push(`/shop/${shopId}${item.path}`)}>
              <img src={item.icon} />
              <div className="text-center" style={{ marginTop: '8px', whiteSpace: 'pre-line' }}>
                <FormattedMessage id={item.name} />
              </div>
              {/* <label className="text-center" style={{ marginTop: '8px' }}>{item.name}</label> */}
            </GreenCardItem>
          </CardItem>
        ))}
      </CardContainer>
    </div>
  )
}

export default MainPage