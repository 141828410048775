const menu = seqId => ({
  customer: [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    // { id: 'MENU.SPECIAL_PACKAGE', route: `/shop/${seqId}/packages` },
    { id: 'MENU.SHOW_DEPOSIT', route: `/shop/${seqId}/products` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    // { id: 'MENU_TUTORIAL', route: `/shop/${seqId}/tutorial` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ],
  user: [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    // { id: 'MENU.SPECIAL_PACKAGE', route: `/shop/${seqId}/packages` },
    { id: 'MENU.SHOW_DEPOSIT', route: `/shop/${seqId}/products` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ],
  'shop-owner': [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    { id: 'MENU_DASHBOARD', route: `/shop/${seqId}/admin/dashboard` },
    { id: 'MENU_INVITE_FRIEND', route: `/shop/${seqId}/invite` },
    { id: 'MENU_USER_MANAGE', route: `/shop/${seqId}/admin/customers` },
    { id: 'MENU_ADMIN_MANAGE', route: `/shop/${seqId}/admin/adminManage` },
    { id: 'MENU_CREATE_PROMOTION', route: `/shop/${seqId}/admin/promotions` },
    { id: 'MENU_SALESPERSON_MANAGE', route: `/shop/${seqId}/admin/salesperson` },
    // { id: 'MENU_CREATE_PACKAGE', route: `/shop/${seqId}/admin/packages` },
    { id: 'MENU_CREATE_POINT_QRCODE', route: `/shop/${seqId}/admin/qrcode` },
    { id: 'MENU_CONVERSION_RATE', route: `/shop/${seqId}/admin/conversion-rate` },
    { id: 'MENU_ADD_POINT_BY_CUSTOMER', route: `/shop/${seqId}/admin/customer-addpoint` },
    { id: 'MENU_ADD_POINT', route: `/shop/${seqId}/admin/addPoint` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    // { id: 'MENU_TUTORIAL', route: `/shop/${seqId}/tutorial` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
    // { id: 'MENU_ANNOUNCEMENT' }
  ],
  'shop-admin': [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    { id: 'MENU_DASHBOARD', route: `/shop/${seqId}/admin/dashboard` },
    { id: 'MENU_INVITE_FRIEND', route: `/shop/${seqId}/invite` },
    { id: 'MENU_USER_MANAGE', route: `/shop/${seqId}/admin/customers` },
    { id: 'MENU_CREATE_PROMOTION', route: `/shop/${seqId}/admin/promotions` },
    { id: 'MENU_SALESPERSON_MANAGE', route: `/shop/${seqId}/admin/salesperson` },
    // { id: 'MENU_CREATE_PACKAGE', route: `/shop/${seqId}/admin/packages` },
    { id: 'MENU_CREATE_POINT_QRCODE', route: `/shop/${seqId}/admin/qrcode` },
    { id: 'MENU_CONVERSION_RATE', route: `/shop/${seqId}/admin/conversion-rate` },
    { id: 'MENU_ADD_POINT_BY_CUSTOMER', route: `/shop/${seqId}/admin/customer-addpoint` },
    { id: 'MENU_ADD_POINT', route: `/shop/${seqId}/admin/addPoint` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    // { id: 'MENU_TUTORIAL', route: `/shop/${seqId}/tutorial` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
    // { id: 'MENU_ANNOUNCEMENT' }
  ],
  'shop-report': [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    { id: 'MENU_DASHBOARD', route: `/shop/${seqId}/admin/dashboard` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
    // { id: 'MENU_ANNOUNCEMENT' }
  ],
  admin: [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    { id: 'MENU_CREATE_SHOP' },
    { id: 'MENU_CREATE_GROUP_NOTIFY' },
    { id: 'MENU_MAINTENANCE' },
    { id: 'MENU_SUPPORT' },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ]
});

export default menu;
