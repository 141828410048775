// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react"
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next"
import LinearProgress from '@material-ui/core/LinearProgress'
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import { Modal, Button } from "react-bootstrap"
import * as actions from "../../../_redux/customersManage/customersActions"
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers"
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import QRCode from 'qrcode.react'
import {
  Input,
} from "../../../../../../_metronic/_partials/controls"
import * as uiHelpers from "../CustomersUIHelpers"
import * as columnFormatters from "./column-formatters"
import { Pagination } from "../../../../../../_metronic/_partials/controls"
import { useCustomersUIContext } from "../CustomersUIContext"

const ShopsTable = ({ intl }) => {
  const [modal, setModal] = useState(false)
  const [point, setPoint] = useState(0)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [qr, setQr] = useState(null)
  // Customers UI Context
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      openProductsPage: customersUIContext.openProductsPage,
      openPackagesPage: customersUIContext.openPackagesPage,
      openTopupPage: customersUIContext.openTopupPage,
    }
  }, [customersUIContext])

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customersManage }),
    shallowEqual
  )
  const { user } = useSelector(state => state.auth)
  const { totalCount, entities, listLoading } = currentState

  // Customers Redux state
  const dispatch = useDispatch()
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([])
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch])
  // Table columns
  const columns = [
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openUpdateCustomersStatusDialog: (row) => setModal(row),
        openProductsPage: customersUIProps.openProductsPage,
        openPackagesPage: customersUIProps.openPackagesPage,
        openTopupPage: customersUIProps.openTopupPage
      },
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "name",
      formatter: (name, row) => name || row.user.firstName,
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.NAME",
      }),
    },
    {
      dataField: "user",
      formatter: (user) => user.birthdate ? moment(user.birthdate).format('DD/MM/YYYY') : '',
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.BIRTHDATE",
      }),
    },
    {
      dataField: "user",
      formatter: (user) => user.lineProfile?.displayName
      , text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.LINE_NAME",
      }),
    },
    {
      dataField: "user",
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.LINE_EMAIL",
      }),
      formatter: (user) => user.lineProfile?.email
    },
    {
      dataField: "user",
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.OWN_EMAIL",
      }),
      formatter: (user) => user.email
    },
    {
      dataField: "user",
      text: intl.formatMessage({
        id: "SHOP.MANAGE.CUSTOMERS.PHONE",
      }),
      formatter: (user) => user.phone
    },
    {
      dataField: "point",
      text: "point",
    },
    {
      dataField: "credit",
      text: "credit",
    },
    // {
    //   dataField: "logo",
    //   text: "Logo URL",
    //   formatter: (logo) => logo && <a target="_blank" href={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/${logo}`}>{`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/${logo}`}</a>
    // },
  ]
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  }
  const searchText = customersUIContext.queryParams.filter.searchText
  const pagination = (entities, paginationProps) => {
    const { page, sizePerPage } = paginationProps
    const indexOfLast = page * sizePerPage
    const indexOfFirst = indexOfLast - sizePerPage
    const currentEntities = entities.slice(indexOfFirst, indexOfLast)
    return currentEntities
  }
  const searchEntities = entities => entities.filter(shop => {
    return shop.user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      shop.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      shop.user.lineProfile?.displayName?.toLowerCase().includes(searchText.toLowerCase()) ||
      shop.user.phone.toLowerCase().includes(searchText.toLowerCase()) ||
      shop.user.email.toLowerCase().includes(searchText.toLowerCase())
  })
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : searchText ? pagination(searchEntities(entities), paginationProps) : pagination(entities, paginationProps)}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: customersUIProps.ids,
                //   setIds: customersUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              <Modal show={modal} onHide={() => {
                setModal(false)
                setQr(null)
              }}>
                <Modal.Header closeButton>
                  <Modal.Title>Generate QR code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {!qr && (
                    <Input
                      type="number"
                      field={{ name: "point" }}
                      label="point"
                      form={{ touched: { point: false }, errors: { point: false } }}
                      value={point}
                      onChange={e => setPoint(e.target.value)}
                    />)
                  }
                  {qr && <QRCode value={qr} />}
                  {error && <Alert severity="error">{error}</Alert>}
                  {loading && <LinearProgress />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={loading}
                    variant="secondary" onClick={() => {
                      setModal(false)
                      setQr(null)
                    }}>
                    <FormattedMessage id="SHOP.MANAGE.CUSTOMERS.CLOSE" />
                  </Button>
                  {!qr && <Button
                    onClick={async () => {
                      if (point <= 0 || point > 1000000) {
                        setError('Invalid point')
                        return
                      } else {
                        setError(false)
                      }
                      setLoading(true)
                      const result = await axios.post(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/customer/qrcode`, { id: modal._id, point })
                      setLoading(false)
                      setQr(`${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/redeem/${result.data.code}`)
                    }}
                    disabled={loading}
                    variant="primary"
                  >
                    <FormattedMessage id="SHOP.MANAGE.CUSTOMERS.GENERATE" />
                  </Button>}

                </Modal.Footer>
              </Modal>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(ShopsTable)
