import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as auth from '../../modules/Auth/_redux/authRedux';
import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import { getUserByToken } from '../../modules/Auth/_redux/authCrud';
import { Helmet } from 'react-helmet';
import { Carousel } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';

const Promotion = props => {
  const { user, authToken } = useSelector(state => state.auth);
  const [promotion, setPromotion] = useState(null);
  const [share, setShare] = useState(false);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;

  useEffect(() => {
    const fetchData = async () => {
      const id = props.match.params?.id;
      const result = await axios.get(`${backendURL}/promotion/${id}`);
      setPromotion({ ...result.data.promotion, image: result.data.promotion.image.filter((image) => image !== null) });
    };
    fetchData();
  }, [props.match.params, backendURL, authToken]);

  const canRedeem = () =>
    promotion.canRedeem &&
    ((promotion.point !== 0 && user.point >= promotion.point) ||
      (promotion.credit !== 0 && user.credit >= promotion.credit) ||
      (promotion.point === 0 && promotion.credit === 0)) && user.type == 'customer';

  const onRedeem = async () => {
    setLoading(true);
    await axios.post(
      `${backendURL}/promotion/${promotion._id}/redeem`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
    const resultUser = await getUserByToken(authToken);
    dispatch({ type: auth.actionTypes.UserLoaded, payload: { user: resultUser.data.data.user } });
    setModal(false);
    setSuccess(true);
  };

  const facebookShare = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/promotion/${promotion._id}`;
    window.FB.api(`/?id=${url}&scrape=true`, 'POST', {});
    // let desc = promotion.description.replace('<p>','');
    // desc = desc.replace('</p>','')
    // document.querySelector('meta[name="url"]').setAttribute("content", url);
    // document.querySelector('meta[name="image"]').setAttribute("content", `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${promotion.image}`);
    // document.querySelector('meta[name="title"]').setAttribute("content", promotion.name);
    // document.querySelector('meta[name="description"]').setAttribute("content", desc);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse`, '_blank');
  };

  return (
    <>
      {promotion && (
        <Container>
          <Helmet>
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/promotion/${promotion._id}`}
            />
            <meta property="og:title" content={promotion.name} />
            <meta
              property="og:image"
              itemprop="image"
              content={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${promotion.resizeImage}`}
            />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
          </Helmet>
          <div className="center-content">
            <div className="align-cen">
              <CarsouselStyled>
                <Carousel indicators={promotion.image.length > 1} prevIcon={promotion.image.length > 1 ? undefined : false} nextIcon={promotion.image.length > 1 ? undefined : false}>
                  {promotion.image.map((img) =>
                    img ? <Carousel.Item>
                      <CarsouselImgStyled
                        src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/img/shop/promotions/${img}`}
                      />
                    </Carousel.Item> : null
                  )}
                </Carousel>
              </CarsouselStyled>
              <div className="promo-content">
                <div className="promo-name">
                  <h4>
                    <b>{promotion.name}</b>
                  </h4>
                </div>
                <div>
                  <h5>
                    {promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}
                  </h5>
                </div>
                <div className="des" dangerouslySetInnerHTML={{ __html: promotion.description }} />
              </div>
              <div className="btn-row">
                <Button variant="outlined" onClick={() => history.push(`/shop/${user.seqId}/reward`)}>
                  Back
                </Button>
                {canRedeem() && (
                  <Button variant="outlined" color="primary" onClick={() => setModal(true)}>
                    Redeem
                  </Button>
                )}
                <Button variant="outlined" onClick={e => setShare(e.currentTarget)}>
                  Share
                </Button>
                <Menu open={share} anchorEl={share} onClose={() => setShare(false)}>
                  {/* <MenuItem><div 
                                    class="fb-share-button" 
                                    data-href={window.location.href}
                                    data-layout="button_count" 
                                    data-size="small"><a target="_blank" 
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcrm.getcode.dev%2Fshop%2F${user.seqId}%2Fpromotion%2F${promotion._id}&amp;src=sdkpreparse`}
                                    class="fb-xfbml-parse-ignore">Facebook</a></div>
                                    </MenuItem> */}
                  <MenuItem onClick={() => facebookShare()}>Facebook</MenuItem>
                  {/* <MenuItem>
                                        Instagram
                                    </MenuItem> */}
                  <MenuItem
                    onClick={() =>
                      window.location.replace(
                        `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(window.location.href)}`
                      )
                    }
                  >
                    Line
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      window.location.replace(
                        `https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`
                      )
                    }
                  >
                    Whatsapp
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/promotion/${promotion._id}`
                      );
                      setSnack('Copied!!');
                      setShare(false);
                    }}
                  >
                    Copy
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <Dialog open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Redeem</DialogTitle>
            <DialogContent>
              Do you want to redeem <label className="font-weight-bold">{promotion.name}</label> using <label className="font-weight-bold">{promotion.point || promotion.credit} {promotion.point ? 'point' : 'credit'}</label> for discount price <label className="font-weight-bold">{promotion.originalPrice} {promotion.currency}</label> to <label className="font-weight-bold">{promotion.promotionPrice} {promotion.currency}</label>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={() => setModal(false)} color="primary">
                <FormattedMessage id="BUTTON.CANCEL" />
              </Button>
              <Button disabled={loading} onClick={onRedeem} color="primary">
                Confirm
              </Button>
            </DialogActions>
            {loading && <LinearProgress />}
          </Dialog>
        </Container>
      )}
      <Snackbar autoHideDuration={2000} message={snack} open={snack} onClose={() => setSnack(false)} />
      <Snackbar
        open={success}
        autoHideDuration={1000}
        onClose={() => {
          setSuccess(false);
          history.push('/');
        }}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Redeem success.
        </Alert>
      </Snackbar>
    </>
  );
};

export default Promotion;


export const CarsouselStyled = styled.div`
  display: flex;
  width: 450px;

  @media (max-width: 550px) {
    width: 280px;
  }
`

export const CarsouselImgStyled = styled.img`
  height: 350px;
  width: 500px;
  border-radius: 25px;

  @media (max-width: 550px) {
    height: 200px;
    width: 300px;
  }
`