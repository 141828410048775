import html2canvas from 'html2canvas'

export const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
}

export const printToFile = (div, name) => {
    html2canvas(document.getElementById(div)).then(function (canvas) {
        var myImage = canvas.toDataURL("image/png");
        downloadURI("data:" + myImage, name);
    });
}