import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { Typography } from '@material-ui/core';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../_helpers';
import useWindowSize from '../../../../app/pages/dashboard/chart/WindowSize';

export function Brand() {
  const uiService = useHtmlClassService();
  const { shop } = useSelector(state => state.auth);
  const size = useWindowSize();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
      asideMinimize: uiService.getClasses('aside-minimize')
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div style={{ height: 'auto' }} className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
        {/* begin::Logo */}
        {/* <Link to="" className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo}/>
        </Link> */}
        {layoutProps.asideSelfMinimizeToggle && (
          <h2 className="brand-logo" style={{ color: '#FFFFFF', padding: '15px 0' }}>{shop ? shop.name : ''}</h2>
          //   <Typography className="brand-logo" variant="h5">
          //   {shop ? shop.name : ''}
          // </Typography>
        )}
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" style={{ display: size.width > 550 ? 'block' : 'none' }}>
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-double-left.svg')} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
