import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;

export const SALES = `${backendURL}/sales`;

export function createSales(body) {
    return axios.post(SALES, body);
}

export function getSales() {
    return axios.get(`${SALES}`);
}

export function updateSales(_id, body) {
    return axios.patch(`${SALES}/${_id}`, body);
}

export function deleteSales(_id) {
    return axios.delete(`${SALES}/${_id}`);
}