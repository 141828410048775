import React, { useMemo, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls"
// import { ProductsFilter } from "./products-filter/ProductsFilter"
import RedeemHistoryTable from "./components/RedeemHistoryTable"
import { useRedeemHistoryUIContext } from "./RedeemUIContext"
import { FormattedMessage, injectIntl } from "react-intl"

const RedeemHistoryCard = ({ intl }) => {
  const redeemHistoryUIContext = useRedeemHistoryUIContext()
  const [totalSize, setTotalSize] = useState(0);
  const limitPromition = 20;
  const productsUIProps = useMemo(() => {
    return {
      ids: redeemHistoryUIContext.ids,
      queryParams: redeemHistoryUIContext.queryParams,
      setQueryParams: redeemHistoryUIContext.setQueryParams
    }
  }, [redeemHistoryUIContext])

  return (
    <Card>
      <CardHeader title={intl.formatMessage({
        id: "MENU_REDEEM_HISTORY"
      })}>
      </CardHeader>
      <CardBody>
        {/* <ProductsFilter /> */}
        {/* {productsUIProps.ids.length > 0 && (
          <>
            <ProductsGrouping />
          </>
        )} */}
        <RedeemHistoryTable totalSize={(value) => setTotalSize(value)}/>
      </CardBody>
    </Card>
  )
}

export default injectIntl(RedeemHistoryCard)
