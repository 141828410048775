import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import { connect } from 'react-redux';
import * as auth from '../../../_redux/authRedux';
import { TextField, Button } from '@material-ui/core/';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shopinvite } from '../../../_redux/authCrud';

function GenerateUrlShop(props) {
  const [snack, setSnack] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
  const shop_id = props.id._id;
  const shopId = props.id.id

  const generateNewUrl = async () => {
    try {
      console.log(shop_id, shopId)
      const result = await shopinvite(shop_id);
      console.log(result)
      setInviteCode(result.data.invitationCode);
      setSnack('This link is valid for 10 minutes');
    } catch (err) {
      if (err.message.includes('403')) return setSnack('You do not have permission to generate invitation code');
      setSnack('Internal server error');
    }
  };
  return (
    <>
        <div className="inner">
          <div className="col">
            <div className="invite-admin full-width">
              <TextField
                variant="outlined"
                value={
                  inviteCode && `${process.env.REACT_APP_BASE_URL}/shop/${shopId}/applyInvite?invitecode=${inviteCode}`
                }
                InputProps={{
                  endAdornment: (
                    <FileCopyIcon
                      className="pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_BASE_URL}/shop/${shopId}/applyInvite?invitecode=${inviteCode}`
                        );
                        setSnack('Copied!!');
                      }}
                    />
                  )
                }}
                fullWidth
              />
            </div>
            <Button className="mt-5" onClick={() => generateNewUrl()} variant="contained" size="large" color="primary">
              <FormattedMessage id="SHOP.MANAGE.CREATE_NEW_INVITE" />
            </Button>
          </div>
        </div>

      <Snackbar autoHideDuration={2000} message={snack} open={snack !== false} onClose={() => setSnack(false)} />
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(GenerateUrlShop));
