// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useCallback, useState } from "react"
import { Modal } from "react-bootstrap"
import { Formik, Form, Field } from "formik"
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormattedMessage, injectIntl } from "react-intl"
import { useDropzone } from 'react-dropzone'
import Alert from '@material-ui/lab/Alert'
import * as Yup from "yup"
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls"
import 'antd/es/upload/style/index.css'
import 'antd/es/button/style/index.css'

// Validation schema
const ShopEditSchema = Yup.object().shape({
  newPoint: Yup.number()
    .min(-100000)
    .max(1000000)
    .required('Field edit point is required'),
})

const CustomerEditForm = ({
  saveShop,
  customer,
  actionsLoading,
  onHide,
  intl,
  files,
  setFiles,
  id
}) => {

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const handleUpload = async (file) => {
    const base64 = await getBase64(file)
    file.thumbUrl = base64
    setFiles((oldFiles) => [...oldFiles, file])
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={ShopEditSchema}
        onSubmit={(values, { resetForm }) => {
          // if (!id && !file) {
          //   setFileError(true)
          //   return
          // }
          let addPoint = values.newPoint;
          saveShop({ ...values, newPoint: addPoint })
          resetForm(customer)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-8">
                    <Field
                      name="oldPoint"
                      value={customer.point}
                      disabled
                      component={Input}
                      label={
                        intl.formatMessage({
                          id: "SHOP.MANAGE.CUSTOMERS.OLD_POINT",
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-8">
                    <Field
                      name="newPoint"
                      component={Input}
                      type="number"
                      label={
                        intl.formatMessage({
                          id: "SHOP.MANAGE.CUSTOMERS.NEW_POINT",
                        })
                      }
                    />
                  </div>
                </div>
              </Form>
              <Upload
                listType="picture"
                fileList={files}
                accept="image/*"
                beforeUpload={(file) => {
                  handleUpload(file)
                  return false
                }}
                onRemove={file => {
                  setFiles(oldFiles => {
                    const index = oldFiles.indexOf(file)
                    const newFileList = oldFiles.slice()
                    newFileList.splice(index, 1)
                    return newFileList
                  })
                }}
              >
                {files.length < 5 && <Button>
                  <UploadOutlined /> Upload
                </Button>}
              </Upload>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  onHide()
                  resetForm(customer)
                }}
                className="btn btn-light btn-elevate"
              >
                {intl.formatMessage({
                  id: "FORM.CANCEL"
                })}
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({
                  id: "FORM.SHOP.SUBMIT"
                })}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(CustomerEditForm)
