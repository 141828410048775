import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  .qr-container {
    margin: 16px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .qr {
    padding: 8px 0;
  }
  .link {
    padding: 16px 0;
  }
  .text-field {
    margin-bottom: 4px;
  }
`;

export const InviteText = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 14px;
`;
