import styled from 'styled-components'

export const Container = styled.div`
    border-radius: 8px;
    margin-top: 8px;
    background-color: white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .center-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .promo-image {
        max-height: 100%;
        max-width: 100%;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    .promo-content {
        width: 100%;
    }
    @media only screen and (min-width: 768px) {
        .promo-image {
        max-height: 500px;
        max-width: 500px;
        }
        .promo-content {
            min-width: 512px;
        }
    }
    .promo-name {
        padding: 8px 0;
        border-radius: 4px;
        margin-top: 8px;
        font-weight: bold;
    }
    .des {
        margin: 16px 0;
    }
    .btn-row {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .align-cen {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
