/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import liff from '@line/liff';
import { Link, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute, LayoutSplashScreen } from '../../../../_metronic/layout';
import Login from './Login';
import ManagementLogin from './ManagementLogin';
import Registration from './Registration';
import '../../../../_metronic/_assets/sass/pages/users/login-1.scss';
import SocialLogin from './socialLogin';
import Management from './management';
import { lineLogin } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import WechatJSSDK from 'wechat-jssdk';
import axios from 'axios';
import WechatLogin from './socialLogin/WechatLogin';
import bgImage from '../../../../assets/background-15.png';
import { getTokenBySeqId } from '../../../utils/localstorage'


export function AuthPage() {
  const [loading, setLoading] = useState(true);
  const [isWeChat, setIsWeChat] = useState(false);
  const [wechatObj, setWechatObj] = useState({});
  const [wxJSSDKerr, setWxJSSDKerr] = useState({});

  const history = useHistory();
  const location = useLocation();

  const search = decodeURIComponent(location.search);
  const queryString = new URLSearchParams(search);
  const isLoginPage = location.pathname.includes('login');
  const shopId = queryString.get('shop');
  const isLiff = queryString.get('liff');
  const inviteCode = queryString.get('invitecode');
  const isLineSuccess = queryString.get('line');
  const lineProfile = queryString.get('profile');

  const [shopDetail, setShopDetail] = useState(null);
  const dispatch = useDispatch();

  const isWithinWeChat = () => {
    const ua = navigator.userAgent.toLowerCase();
    // is within wechat
    // micromessenger is the keyword that indicates it's within wechat
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      // setIsWeChat(true);
      return true;
    }
    return false;
  };

  const requestForWeChatParams = async () => {
    // Make sure it's the url that you have whitelisted in WeChat Official Account Dashboard
    const url = window.location.href.split('#')[0];
    console.log(encodeURIComponent(url));
    // If you want to test on your device, be sure to use your IP address instead of localhost
    const YourBackEndUrl = `${process.env.REACT_APP_BACKEND_IP_ADDRESS}/wechat/get-signature?url=${encodeURIComponent(
      url
    )}`;

    try {
      const { data } = await axios.get(YourBackEndUrl);

      const config = {
        //below are mandatory options to finish the wechat signature verification
        //the 4 options below should be received like api '/get-signature' above
        appId: data.appId,
        nonceStr: data.nonceStr,
        signature: data.signature,
        timestamp: data.timestamp,
        //below are optional
        success: jssdkInstance => {
          console.log('success', jssdkInstance);
        },
        error: (err, jssdkInstance) => {
          console.log('failed', jssdkInstance);
        },
        //enable debug mode, same as debug
        debug: true,
        // Tell WeChat what functionalities you would like to use
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone',
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'pauseVoice',
          'stopVoice',
          'onVoicePlayEnd',
          'uploadVoice',
          'downloadVoice',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          'translateVoice',
          'getNetworkType',
          'openLocation',
          'getLocation',
          'hideOptionMenu',
          'showOptionMenu',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'closeWindow',
          'scanQRCode',
          'chooseWXPay',
          'openProductSpecificView',
          'addCard',
          'chooseCard',
          'openCard'
        ], //optional, pass all the jsapi you want, the default will be ['onMenuShareTimeline', 'onMenuShareAppMessage']
        customUrl: '' //set custom weixin js script url, usually you don't need to add this js manually
      };
      // const wechatObj = new WechatJSSDK(config);

      // console.log({ wechatObj });
      // await wechatObj.initialize();
      console.log('After initialize');
      // Usually you want to create a wrapper component or set it as a window variable to use wechatObj everywhere else without reinitializing it.
      // setWechatObj(wechatObj);
    } catch (error) {
      console.log({ error });
      // setWxJSSDKerr(error);
    }
  };

  const initLiff = async () => {
    let profile = null;
    console.log('init liff', liff.isLoggedIn())
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
      if (liff.isLoggedIn()) {
        profile = await liff.getProfile();
        console.log(profile)
        const res = await lineLogin(profile.userId, shopId);
        dispatch({ type: auth.actionTypes.Login, payload: { authToken: res.data.token } });
      } else {
        let redirectUri = `${process.env.REACT_APP_BASE_URL}?shop=${shopId}&liff=true`;
        redirectUri += inviteCode ? `&invitecode=${inviteCode}` : '';
        liff.login({ redirectUri });
      }
    } catch (err) {
      if (err.message.includes('404')) {
        history.push({
          pathname: `/shop/${shopId}/registration`,
          search: inviteCode && `?invitecode=${inviteCode}`,
          profile,
          error: err.message
        });
        setLoading(false);
      }
      console.dir(err);
    }
  };

  const initLine = async () => {
    let profile = null;
    try {
      profile = JSON.parse(lineProfile);
      const res = await lineLogin(profile.userId, shopId, profile.pictureUrl);
      dispatch({ type: auth.actionTypes.Login, payload: { authToken: res.data.token } });
      dispatch({ type: auth.actionTypes.UserLoaded, payload: { authToken: res.data.user } });
    } catch (err) {
      let code = localStorage.getItem('_ivc');
      localStorage.removeItem('_ivc')
      if (err.message.includes('404')) {
        history.push({
          pathname: `/shop/${shopId}/registration`,
          search: code && `?invitecode=${code}`,
          profile,
          error: err.message
        });
        setLoading(false);
      }
      console.dir(err);
    }
  };

  useEffect(() => {
    const seqId = window.location.pathname.split('/');

    if (parseInt(seqId[2]) > 0) {
      getLogo(seqId[2]);
    }

    if (shopId && isLiff) {
      initLiff();
    } else if (shopId && isLineSuccess) {
      initLine();
    } else {
      setLoading(false);
    }

    const storageToken = getTokenBySeqId(seqId[2])

    if (storageToken) {
      dispatch({ type: auth.actionTypes.Login, payload: { authToken: storageToken, seqId: seqId[2] } });
    }

    // const isWechat = isWithinWeChat();
    // if (!isWechat) {
    //   requestForWeChatParams();
    // }
    // axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/wechat/oauth`)
    //   .then(({ data }) => {
    //     const { url } = data;
    //     history.push(url);
    //   });
  }, []);

  const getLogo = async id => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/shop/${id}`);
    dispatch({ type: auth.actionTypes.GetShopInfo, payload: data });
    setShopDetail(data.shop);
  };

  if (loading) return <LayoutSplashScreen />;

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              background: 'linear-gradient(135deg, #7AB8B6 40%, white 100%)'
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between ">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5">
                <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl('/media/logos/Funcrowd-01.svg')} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                {/* <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl('/media/logos/logo-letter-1.png')} /> */}
                {/* <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to Optimo CRM!
                  </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  An online application for Shopping.
                  </p> */}
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white"></div>
                <div className="d-flex">
                  <Link to="/terms" style={{color: '#7AB8B6', fontWeight: 'bold'}}>
                    Privacy
                  </Link>
                  <Link to="/terms" className="ml-10" style={{color: '#7AB8B6', fontWeight: 'bold'}}>
                    Legal
                  </Link>
                  <Link to="/terms" className="ml-10" style={{color: '#7AB8B6', fontWeight: 'bold'}}>
                    Contact
                  </Link>
                </div>
              </div> */}
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7"
            style={{ backgroundImage: `url(${isLoginPage && bgImage})`, backgroundSize: 'contain' }}
          >
            {/* begin::Content body */}
            <div className={`d-flex flex-column flex-column-fluid  ${isLoginPage ? 'justify-content-center' : ''}`}>
              <div className="d-flex flex-column flex-wrap align-items-center flex-center mb-4 mt-lg-0">
                {shopDetail && shopDetail.logo && (
                  <img
                    alt="Logo"
                    className="img-fluid mx-auto"
                    style={{ maxHeight: isLoginPage ? 200 : 60 }}
                    src={`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/${shopDetail.logo.data}`}
                  />
                )}
                {shopDetail && (
                  <h2 style={{ color: 'rgb(122, 184, 182)', margin: '30px 0 20px 0' }}>{shopDetail.name}</h2>
                )}
              </div>
              <div className="d-flex flex-column align-items-center flex-start mt-4 mt-lg-0">
                <Switch>
                  {/* <ContentRoute exact path="/oauth-callback" render={WechatLogin} /> */}
                  <ContentRoute exact path="/admin/login" component={Login} />
                  <ContentRoute exact path="/shop/management/login" component={ManagementLogin} />
                  <ContentRoute exact path="/shop/management" component={Management} />
                  <ContentRoute exact path="/shop/:seqId/login" component={SocialLogin} />
                  <ContentRoute exact path="/shop/:seqId/applyInvite" component={SocialLogin} />
                  <ContentRoute exact path="/shop/:seqId/registration" component={Registration} />
                  <Redirect from="/admin" to="/admin/login" />
                  <Redirect from="/shop/:seqId" to="/shop/:seqId/login" />
                  <Redirect to="/error" />
                </Switch>
              </div>
            </div>

            {/* begin::Mobile footer */}
            {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2"></div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary">
                  Privacy
                </Link>
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary ml-4">
                  Legal
                </Link>
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary ml-4">
                  Contact
                </Link>
              </div>
            </div> */}
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
