import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Modal, Button } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { printToFile } from '../../utils/downloadCanvas';
import CountryConfig from '../../../CountryConfig';

const frontendURL = process.env.REACT_APP_BASE_URL
const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
const CURRENCY = CountryConfig[process.env.REACT_APP_COUNTRY].currency

const AddPointByCustomer = ({ intl }) => {
  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;
  const { user, shop } = useSelector(state => state.auth);
  const [form, setForm] = useState({
    amount: '',
    currency: CURRENCY
  })
  const [conversionRate, setConversionRate] = useState()
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(null);

  useEffect(() => {
    getConversionRate()
  }, [])

  const getConversionRate = async () => {
    try {
      const { data } = await axios.get(`${backendURL}/rate?currency=${form.currency}`)
      if (data.conversionRate && data.conversionRate.length !== 0) {
        setConversionRate(data.conversionRate[0])
      } else {
        setError(`Please create ${CURRENCY} conversion rate.`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnChange = (event) => {
    if (event.target.name == 'amount' && event.target.value < 0) return
    setForm({ ...form, [event.target.name]: event.target.value });
  }

  const handleOnChangeAutocomplete = async (event, value, name) => {
    setForm({ ...form, [name]: value });
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    try {
      const { data } = await axios.post(`${backendURL}/shop/qrcode`, {
        name: `Every ${conversionRate.rate} ${form.currency}, get ${conversionRate.earnPoint} points`,
        point: Math.floor(form.amount / conversionRate.rate) * conversionRate.earnPoint,
        userLimit: 1,
      });
      if (data) {
        setModal(true)
        setModalDetail(data)
        setForm({
          amount: '',
          currency: CURRENCY
        })
      }
    } catch (e) {
      const { message } = e.response.data
      setError(message)
    }
  }

  const handleShare = (platform) => {
    if (platform === 'whatsapp') {
      const url = encodeURIComponent(`${frontendURL}/shop/${shop.seqId}/redeem/${modalDetail.code}`)
      window.location.replace(
        `https://api.whatsapp.com/send?text=${url}`
      )
    }
  }

  return (
    <>
      <Container>
        <h3 className="mb-5">Add point by customer</h3>
        <form className="d-flex flex-column align-items-center" onSubmit={(event) => handleOnSubmit(event)}>
          <div className="form-group d-flex flex-column">
            <TextField name="amount" label="Sale amount" variant="outlined" type="number" required style={{ width: '300px' }} onChange={handleOnChange} value={form.amount} />
          </div>
          {/* <div className="form-group d-flex flex-column">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={currencies}
              sx={{ width: 300 }}
              getOptionLabel={option => option.key}
              renderOption={(props, option) => <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.key} - {option.name}
              </Box>}
              onInputChange={(event, value) => handleOnChangeAutocomplete(event, value, 'currency')}
              renderInput={(params) => <TextField {...params} label="Currency" required />}
            />
          </div> */}
          <div className="d-flex flex-column align-items-center mb-5">
            {conversionRate && <h6>Conversion rate: Every {conversionRate.rate} {form.currency}, get {conversionRate.earnPoint} points</h6>}
          </div>
          <div className="d-flex flex-column align-items-center">
            {(form.amount && conversionRate) &&
              <>
                <h3>You will earn</h3>
                <h2>{Math.floor(form.amount / conversionRate.rate) * conversionRate.earnPoint} points</h2>
              </>
            }
            <button type="submit" className="m-5 btn btn-primary">{intl.formatMessage({ id: 'button.submit' })}</button>
          </div>
        </form>
        <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
          <Alert onClose={() => setSuccess(false)} severity="success">
            {success}
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
          <Alert onClose={() => setError(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Container>
      <Modal
        show={modal && modal.length !== 0}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header closeButton>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3>Qr Code</h3>
            <a onClick={() => {
              setModal(false);
            }} className='btn btn-xs btn-icon btn-light btn-hover-primary'>
              <i class="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body id="redeemQrCode">
          <div style={{ textAlign: 'center' }}>
            <QRCode value={`${process.env.REACT_APP_BASE_URL}/shop/${user.seqId}/redeem/${modalDetail && modalDetail.code}`} />
            <div style={{ color: '#1aa29e' }}>
              <label className="font-weight-bold" style={{ color: '#000' }}>QR Code for redeem</label><br />
              <label className="font-weight-bold">Shop : </label> {shop && shop.name}<br />
              <label className="font-weight-bold">Name : </label> {modalDetail && modalDetail.name}<br />
              <label className="font-weight-bold">Point : </label> {modalDetail && modalDetail.point}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleShare('whatsapp')}
            variant="primary"
          >
            <label style={{ margin: 0 }}>Share whatsapp</label>
          </Button>
          <Button
            onClick={() => printToFile('redeemQrCode', `${modalDetail && modalDetail.name} Redeem Qrcode.png`)}
            variant="primary"
          >
            <label style={{ margin: 0 }}>Download QR Code</label>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}



export default injectIntl(AddPointByCustomer)