import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/qrCode/customersActions"
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader"
import CustomerEditForm from "./CustomerEditForm"
import { useCustomersUIContext } from "../CustomersUIContext"

const CustomerEditDialog = ({
  id, show, onHide, customerId }) => {
  // Customers UI Context
  const [error, setError] = useState(false)
  const customersUIContext = useCustomersUIContext()
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    }
  }, [customersUIContext])

  // Customers Redux state
  const dispatch = useDispatch()
  const { actionsLoading, customerForEdit, authToken } = useSelector(
    (state) => ({
      actionsLoading: state.customerProducts.actionsLoading,
      customerForEdit: state.customerProducts.customerForEdit,
      authToken: state.auth.authToken,
    }),
    shallowEqual
  )

  // useEffect(() => {
  //   // server call for getting Customer by id
  //   dispatch(actions.fetchCustomer(id))
  // }, [id, dispatch])

  // server request for saving customer
  const saveShop = (qrData) => {
    console.log(qrData)
    dispatch(actions.createShop({...qrData, authToken})).then(() => onHide())
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        id={id}
        saveShop={saveShop}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        onHide={onHide}
        error={error}
      />
    </Modal>
  )
}

export default CustomerEditDialog
