// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useCallback, useState } from "react"
import { Modal } from "react-bootstrap"
import { Formik, Form, Field } from "formik"
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, injectIntl } from "react-intl"
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button } from 'antd'
import * as Yup from "yup"
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls"
import { useDropzone } from 'react-dropzone'
// Validation schema
const ShopEditSchema = Yup.object().shape({
  credit: Yup.number()
    .min(0)
    .required("credit is required"),
})

const CustomerEditForm = ({
  saveShop,
  customer,
  actionsLoading,
  onHide,
  intl,
  file,
  setFile,
  expire,
  setExpire,
  id,
  error,
  removedFiles,
  setRemovedFiles
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }))
    }
  })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={ShopEditSchema}
        onSubmit={(values, { resetForm }) => {
          saveShop(values)
          resetForm(customer)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group">
                  <Field
                    name="credit"
                    type="number"
                    component={Input}
                    label={
                      intl.formatMessage({
                        id: "SHOP.MANAGE.CUSTOMERS.CREDIT",
                      })
                    }
                  />
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="form-group">
                    <label><FormattedMessage id="SHOP.MANAGE.CUSTOMER.TOPUP.EXPIRE" /></label>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        "aria-readonly": true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="MM/dd/yyyy"
                      value={expire}
                      onChange={(date) => setExpire(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disablePast
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <div className="form-group row">
                  <div className="col-lg-8">
                    <label><FormattedMessage id="SHOP.PROMOTION.IMAGE" /></label>
                    {file && <img
                      style={{
                        display: 'block',
                        maxWidth: '230px',
                        maxHeight: '95px',
                        width: 'auto',
                        height: 'auto',
                      }}
                      alt="preview"
                      src={file.preview}
                    />}
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} style={{ display: 'block' }} />
                      <p>{
                        intl.formatMessage({
                          id: "FORM.SHOP.LOGO_LIMIT",
                        })
                      }</p>
                    </div>
                  </div>
                </div>
              </Form>
              {error && <Alert severity="error">{error}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  onHide()
                  resetForm(customer)
                }}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="BUTTON.CANCEL" />
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({
                  id: "FORM.SHOP.SUBMIT"
                })}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(CustomerEditForm)
