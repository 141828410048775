// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react"
import { Modal } from "react-bootstrap"
import { Formik, Form, Field } from "formik"
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, injectIntl } from "react-intl"
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button } from 'antd'
import * as Yup from "yup"
import {
  Input,
} from "../../../../../../_metronic/_partials/controls"

// Validation schema
const ShopEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Minimum 2 symbols")
    .required("name is required"),
  userLimit: Yup.number().required("user limit is required"),
  point: Yup.number().required("point is required")
})

const CustomerEditForm = ({
  saveShop,
  customer,
  actionsLoading,
  onHide,
  intl,
  id,
  error,
}) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={ShopEditSchema}
        onSubmit={(values, { resetForm }) => {
          saveShop(values)
          resetForm(customer)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group">
                  <Field
                    name="name"
                    component={Input}
                    label={"name"}
                  />
                </div>
                <div className="form-group">
                  <Field
                    type="number"
                    name="userLimit"
                    component={Input}
                    label="limit per user"
                  />
                </div>
                <div className="form-group">
                  <Field
                    type="number"
                    name="point"
                    component={Input}
                    label="point"
                  />
                </div>
              </Form>
              {error && <Alert severity="error">{error}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  onHide()
                  resetForm(customer)
                }}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="BUTTON.CANCEL" />
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({
                  id: "FORM.SHOP.SUBMIT"
                })}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(CustomerEditForm)
