import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Container } from './styles';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const Redeem = props => {
  const { authToken, user } = useSelector(state => state.auth);
  const [qr, setQr] = useState(null);
  const [error, setError] = useState(null);
  console.log(props);
  const code = props.match.params.code;
  const backendURL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
  const history = useHistory();
  const [success, setSuccess] = useState(null);

  const Alert = aprops => <MuiAlert elevation={6} variant="filled" {...aprops} />;

  useEffect(() => {
    const fetch = async () => {
      const result = await axios.get(`${backendURL}/customer/qrcode/${code}`);
      setQr(result.data.qr);
    };
    fetch();
  }, [backendURL, code]);

  console.log(qr);

  const onRedeem = async () => {
    try {
      await axios.post(
        `${backendURL}/customer/redeem`,
        { code },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setSuccess(true);
      setTimeout(function() {
        history.push(`/shop/${user.seqId}/home`);
      }, 3000);
    } catch (e) {
      console.error(e);
      setError(true);
    }
  };

  return (
    <Container>
      <div className="shop-name">
        <h3>{qr?.shop?.name}</h3>
      </div>
      <div className="shop-point">
        <h4>{qr?.point} point</h4>
      </div>
      <div>
        <Button onClick={onRedeem}>Redeem</Button>
      </div>
      <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Can't redeem this qrcode
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Redeem success.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Redeem;
