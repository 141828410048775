import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .shop-point {
    margin: 16px 0;
  }
`