// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import { Switch } from '@material-ui/core/'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, injectIntl } from "react-intl"
import * as Yup from "yup"
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { useDropzone } from 'react-dropzone'
import { Input } from "../../../../../../_metronic/_partials/controls"

// Validation schema
const ProductEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Prmomotion name is required"),
  count: Yup.number()
    .min(0, "0 is minimum")
    .max(1000000, "1000000 is maximum")
    .required("Count is required"),
  point: Yup.number()
    .min(0, "0 is minimum")
    .max(1000000, "1000000 is maximum")
    .required("Point used is required"),
})

const ProductEditForm = ({
  id,
  product,
  btnRef,
  saveProduct,
  description,
  setDescription,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  file,
  setFile,
  active,
  setActive,
  type,
  setType,
  error,
  setError,
  intl,
  uuid
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }))
    }
  })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductEditSchema}
        onSubmit={(values, { resetForm }) => {
          saveProduct(values)
          resetForm(product)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
                <Field
                  name="name"
                  component={Input}
                  label={intl.formatMessage({
                    id: "SHOP.PACKAGE.FORM.NAME"
                  })}
                />
              </div>
              <div className="form-group">
                <label><FormattedMessage id="SHOP.PROMOTION.FORM.DESCRIPTION" /></label>
                {(!id || (id && product._id)) && <CKEditor
                  key={uuid}
                  editor={ClassicEditor}
                  rows={4}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.editing.view.change(writer => {
                      writer.setStyle(
                        "height",
                        "100px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo'],
                  }}
                  data={description || ''}
                />}
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  name="count"
                  component={Input}
                  label={intl.formatMessage({
                    id: "SHOP.PACKAGE.FORM.COUNT"
                  })}
                />
              </div>
              <div className="form-group">
                <Select
                  disabled={id}
                  value={"point"}
                  onChange={e => setType(e.target.value)}
                >
                  <MenuItem value="point">point</MenuItem>
                  <MenuItem value="credit">credit</MenuItem>
                </Select>
                <Field
                  type="number"
                  name="point"
                  component={Input}
                  label={intl.formatMessage({
                    id: "SHOP.PROMOTION.FORM.POINT"
                  })}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label><FormattedMessage id="SHOP.PACKAGE.FORM.START" /></label>
                <div className="form-group">
                  <>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        "aria-readonly": true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="MM/dd/yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disablePast
                    />
                  </>
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label><FormattedMessage id="SHOP.PACKAGE.FORM.END" /></label>
                <div className="form-group">
                  <>
                    <DatePicker
                      error={false}
                      helperText={null}
                      inputProps={{
                        style: {
                          fontFamily: `Poppins, Helvetica, "sans-serif"`,
                          fontWeight: 400,
                          color: 'rgb(70, 78, 95)',
                          display: 'block',
                          padding: '0.65rem 1rem',
                          border: '1px solid #E5EAEE',
                          borderRadius: '0.42rem'
                        },
                        "aria-readonly": true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="MM/dd/yyyy"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="form-control"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disablePast
                    />
                  </>
                </div>
              </MuiPickersUtilsProvider>
              <div className="form-group row">
                <div className="col-lg-8">
                  <label><FormattedMessage id="SHOP.PACKAGE.FORM.IMAGE" /></label>
                  {file && <img
                    style={{
                      display: 'block',
                      maxWidth: '230px',
                      maxHeight: '95px',
                      width: 'auto',
                      height: 'auto',
                    }}
                    alt="preview"
                    src={file.preview}
                  />}
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} style={{ display: 'block' }} />
                    <p>{
                      intl.formatMessage({
                        id: "FORM.SHOP.LOGO_LIMIT",
                      })
                    }</p>
                  </div>
                </div>
              </div>
              <label>Active</label>
              <Switch checked={active} onChange={() => setActive(!active)} />
            </Form>
            {error && <Alert severity="error">{error}</Alert>}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => {
                handleSubmit()
                // resetForm(product)
              }}
            ><FormattedMessage id="SHOP.PROMOTION.FORM.SAVE" /></button>
          </>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(ProductEditForm)
